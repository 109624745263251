import Button from 'components/ui/button';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { getNews } from 'services/news.service';
import { updateUserProfile } from 'services/profile.service';

import './styles.scss';
import { UserInfo } from 'types/user';

interface Props {
  send: () => void;
  player: UserInfo;
}

export default function NewFeatures(data: Props) {
  const { send, player } = data;
  const [news, setNews] = useState<any>();

  const { user, refreshUser } = useAuth();

  async function handleCloseTutorial() {
    await updateUserProfile({
      _id: user?._id,
      extra: {
        ...user?.extra,
        lasNews: news?.created,
      },
    });

    refreshUser();
    setNews(undefined);
    send();
  }

  useEffect(() => {
    async function loadNews() {
      if (user) {
        const data = await getNews(player?.cad_missao, user.extra.lasNews);

        if (data) {
          setNews(data);
        } else {
          send();
        }
      }
    }

    loadNews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, player]);

  if (!news) return null;

  return (
    <div className="modal-news fixed bg-black bg-opacity-40 top-0 left-0 w-screen h-screen p-6 pt-[10vh] flex justify-center overflow-y-auto">
      <div className="relative border-2 border-solid border-electric-blue bg-oxford-blue rounded-lg p-10 max-w-[600px] h-fit">
        <Button
          color="primary"
          className="absolute -right-5 -top-5  rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
          onClick={() => handleCloseTutorial()}
        >
          <IoIosClose color="white" size={30} />
        </Button>

        {news?.text && (
          <div dangerouslySetInnerHTML={{ __html: news.text }}></div>
        )}
      </div>
    </div>
  );
}
