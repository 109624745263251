import Button from "components/ui/button";
import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward, IoIosClose } from "react-icons/io";
import { Carousel } from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { updateUserProfile } from "services/profile.service";
import { getTutorialById } from "services/tutorial.service";
import "./styles.scss";

export default function UserTutorial() {
  const [tutorialEnabled, setTutorialEnabled] = useState(false);
  const [tutorialSteps, setTutorialSteps] = useState<any[]>();

  const { user, refreshUser } = useAuth();

  async function handleCloseTutorial() {
    setTutorialEnabled(false)
    await updateUserProfile({
      _id: user?._id,
      extra: {
        ...user?.extra,
        tutorial: true,
      },
    });
    refreshUser();
  }

  useEffect(() => {
    async function loadTutorial() {
      if (user && !user.extra.tutorial && !localStorage.getItem("tutorial")) {
        const data = await getTutorialById("tutorial_inicial");

        if (data.steps.length > 0) {
          setTutorialEnabled(true);
          setTutorialSteps(data.steps);
        }

        localStorage.setItem("tutorial", "true");
      }
    }

    loadTutorial();
  }, [user]);

  if (!tutorialEnabled || !tutorialSteps) return null;

  return (
    <div className="user-tutorial fixed bg-black bg-opacity-40 top-0 left-0 w-screen h-screen p-6 lg:pt-[17vh] flex justify-center overflow-y-auto">
      <div className="relative border-2 border-solid border-electric-blue bg-oxford-blue rounded-lg  w-[90vw] lg:w-[35vw] 2xl:w-[29vw] h-fit">
        <Button
          color="primary"
          className="absolute -right-5 -top-5  rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
          onClick={() => handleCloseTutorial()}
        >
          <IoIosClose color="white" size={30} />
        </Button>

        <Carousel
          showThumbs={false}
          showStatus={false}
          renderIndicator={(
            onClickHandler: any,
            isSelected: any,
            index: any
          ) => {
            if (isSelected) {
              return (
                <li
                  className="w-6 h-2 rounded-full bg-white"
                  aria-label={`Selecionado: Destaque ${index + 1}`}
                  title={`Selecionado: Destaque ${index + 1}`}
                />
              );
            }
            return (
              <li
                className="w-2 h-2 rounded-full bg-white bg-opacity-10"
                onClick={onClickHandler}
                onKeyDown={onClickHandler}
                value={index}
                key={index}
                role="button"
                title={`Destaque ${index + 1}`}
                aria-label={`Destaque ${index + 1}`}
              />
            );
          }}
          renderArrowPrev={(onClickHandler: any, hasPrev) => (
            <button
              className={`${!hasPrev && "hidden"} arrow left`}
              type="button"
              onClick={onClickHandler}
              title="Anterior"
            >
              <IoIosArrowBack size={25} color="white" />
            </button>
          )}
          renderArrowNext={(onClickHandler: any, hasNext) => (
            <button
              className={`${!hasNext && "hidden"} arrow right`}
              type="button"
              onClick={onClickHandler}
              title="Próximo"
            >
              <IoIosArrowForward size={25} color="white" />
            </button>
          )}
        >
          {/* <div className="content w-full h-full block relative cursor-pointer">
            <img
              src="https://toppng.com/public/uploads/thumbnail/anime-lolis-11562938919zcahwz7txc.png"
              alt="item"
            />
            <h1 className="text-yellow text-3xl">
              #JogaJuntoBB te dá as boas-vindas!
            </h1>
            <p className="text-white font-thin">
              Antes de iniciar sua jornada aqui, lembre-se de ler as regras do
              jogo, localizadas no menu lateral da tela inicial.
            </p>
          </div> */}
          {tutorialSteps?.map((item) => (
            <div  key={item.text} className="block p-5 2xl:p-10">
              <div
               
                className="content"
                dangerouslySetInnerHTML={{ __html: item.text }}
              ></div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
}
