/* eslint-disable react-hooks/exhaustive-deps */
import { Link, Outlet } from 'react-router-dom';
import { AiFillLeftCircle } from 'react-icons/ai';
import { BsPlusLg } from 'react-icons/bs';
import ReactPaginate from 'react-paginate';

import Box from 'components/ui/box';
import { useAuth } from 'context/AuthContext';
import InputMask from 'react-input-mask';
import { useState, useEffect, useRef } from 'react';
import { getEquieGestor } from 'services/dashboard.service';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import Avatar from 'funifier-nice-avatar';
import { TbSearch } from 'react-icons/tb';

import './styles.scss';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import { QuadroEquipeDetail } from './components/detail';
import Button from 'components/ui/button';
import SearchGestor from './components/SearchGestor';

export default function QuadroEquipe() {
  const [list, setList] = useState<unknown[]>();
  const [page, setPage] = useState(0);
  const [activePag, setActivePag] = useState(0);
  const [showDetail, setShowDetail] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState<any>(false);

  const [mask] = useState('');

  const searchRef = useRef<any>();

  const [loadingList, setLoadingList] = useState(true);

  const { user } = useAuth();

  function handleDetail(item: any) {
    setShowDetail(item);
  }

  async function loadData(e?: any, itemPage?: number) {
    if (!user) return;

    const payload = id?.extra?.id ? id.extra.id : user?.extra.id;

    if (e) {
      e.preventDefault();
    }

    try {
      const rangeItem = itemPage ? itemPage * 20 : 0;

      setActivePag(itemPage ? itemPage : 0);
      setLoadingList(true);
      setList([]);

      const homeSection = document.getElementById('main-body');
      if (homeSection) {
        homeSection.scrollTop = 0;
      }

      const data = await getEquieGestor(
        payload,
        searchRef.current.value,
        rangeItem
      );

      setPage(data.range.pages);

      setList(data.result);

      setLoadingList(false);
    } catch (e) {}
  }

  useEffect(() => {
    loadData();
  }, [user, id]);

  if (showDetail)
    return (
      <QuadroEquipeDetail
        onClose={() => setShowDetail('')}
        id={showDetail}
        isFromTeam={!!!id}
      />
    );

  return (
    <>
      <div className="mx-auto container p-3 lg:p-10 overflow-hidden">
        {!!!id ? (
          <div className=" mb-3 lg:mb-10 flex justify-between items-center">
            <div className="flex flex-ro items-center space-x-3 lg:space-x-1">
              <div>
                <Link to="../">
                  <AiFillLeftCircle size={48} className="text-purple" />
                </Link>
              </div>

              <h1 className="text-lg lg:text-3xl  py-1 px-6 rounded-full">
                Minha Equipe
              </h1>
            </div>

            <div>
              <Button
                className="px-6 py-2 rounded-sm uppercase"
                color="outlinePurple"
                onClick={() => setShowModal(true)}
              >
                Visualizar equipe lateral
              </Button>
            </div>
          </div>
        ) : (
          <div className=" mb-3 lg:mb-10 flex justify-between items-center">
            <div className="flex flex-ro items-center space-x-3 lg:space-x-1">
              <div>
                <button type="button" onClick={() => setId(false)}>
                  <AiFillLeftCircle size={48} className="text-purple" />
                </button>
              </div>

              <h1 className="text-lg lg:text-3xl  py-1 px-6 rounded-full">
                Equipe de {id.name}
              </h1>
            </div>
          </div>
        )}

        <div className="border-2 border-electric-blue rounded-3xl px-8 py-6 mb-12 bg-oxford-blue">
          <p className="text-dandelion text-lg font-semibold block mb-3">
            Para que serve o inventário de equipe?
          </p>
          <p className="text-white text-base font-light m-0">
            O Inventário de Equipe serve para acompanhar as atividades e
            reconhecimentos dos funcionários que estão sob sua supervisão. Dessa
            forma, você consegue identificar o bom desempenho de cada um.
          </p>
        </div>

        <div className="mb-5 lg:flex items-center justify-between">
          <p className="text-lg mb-4 lg:mb-0">
            Lista de funcionários sob sua supervisão{' '}
            <span className="text-yellow">({list?.length || 0})</span>
          </p>

          <div className="space-x-4 flex flex-row items-center justify-between">
            <form onSubmit={(e) => loadData(e)} className="pl-1 -ml-1 flex-1">
              <InputMask
                placeholder="Pesquise com nome e chave"
                alwaysShowMask={false}
                mask={mask}
                ref={(ref: any) => {
                  searchRef.current = ref;
                }}
                inputMode="search"
                style={{ fontSize: 16 }}
                className="min-w-full lg:min-w-[416px] bg-maastricht-blue text-sm text-white font-thin flex-1 rounded-md py-2 px-4"
              />
            </form>

            <TbSearch
              size={28}
              className="cursor-pointer hover:ring-2 mr-1 rounded-full"
              onClick={() => loadData(null)}
            />
          </div>
        </div>

        <div>
          <Box>
            <div className="space-y-5 lg:p-3">
              {loadingList && (
                <div className="space-y-2">
                  <ArticlePlaceholder />
                  <ArticlePlaceholder />
                </div>
              )}

              {!loadingList && list?.length === 0 && (
                <div className="text-center">
                  <h3 className="text-xl">Nenhum resultado encontrado</h3>
                </div>
              )}

              <div className="space-y-2">
                <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                  {list?.map((item: any) => (
                    <div
                      className="bg-maastricht-blue rounded-xl text-white space-y-2 cursor-pointer hover:ring-2 overflow-hidden"
                      key={item?._id}
                      onClick={() => handleDetail(item.matricula)}
                    >
                      <div className="px-4">
                        <div className="flex flex-col items-center justify-around md:h-40 space-y-1 text-center">
                          <div className="w-28 h-28 overflow-hidden rounded-full bg-purple text-3xl font-bold flex flex-row items-center justify-center">
                            {!!item.avatar_type &&
                              (item?.image?.medium?.url ? (
                                <img
                                  src={item.image.medium.url}
                                  alt={item.nome}
                                  className="w-full h-full"
                                />
                              ) : (
                                item.nome[0]
                              ))}
                            {!item.avatar_type &&
                              (!!item.avatar ? (
                                <Avatar
                                  className="w-full h-full"
                                  {...item.avatar}
                                />
                              ) : (
                                item.nome[0]
                              ))}
                          </div>
                        </div>
                      </div>

                      <div className="bg-prussian-blue-3 w-full h-full text-center pb-7 pt-5 ">
                        <div className="font-bold">{item.nome}</div>
                        <div className="mt-1">
                          <span className="text-electric-blue font-thin">
                            {item.diretoria}{' '}
                          </span>
                          <span className="font-thin">- {item.funcao}</span>
                        </div>
                        <div className="text-electric-blue  mb-3">
                          <span className="font-thin">{item.matricula}</span>
                        </div>

                        <button className="mt-4">
                          <BsPlusLg
                            className="mr-2 inline-block"
                            size={17}
                            color="#C2D2FF"
                          />
                          <span className="uppercase">Ver mais</span>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={`flex justify-center ${page < 2 && 'hidden'}`}>
              <ReactPaginate
                activeClassName={'item active '}
                breakClassName={'item break-me '}
                breakLabel={'...'}
                containerClassName={'pagination'}
                disabledClassName={'disabled-page'}
                nextClassName={'item next '}
                pageClassName={'item pagination-page '}
                previousClassName={'item previous'}
                initialPage={0}
                forcePage={activePag}
                nextLabel={
                  <IoIosArrowForward style={{ fontSize: 18, width: 150 }} />
                }
                onPageChange={(data) => loadData(null, data.selected)}
                pageRangeDisplayed={3}
                pageCount={page}
                previousLabel={
                  <IoIosArrowBack style={{ fontSize: 18, width: 150 }} />
                }
              />
            </div>
          </Box>
        </div>
      </div>
      {showModal && (
        <SearchGestor
          closeModal={() => setShowModal(false)}
          onSubmit={(id) => {
            setId(id);
            setShowModal(false);
          }}
        />
      )}
      <Outlet />
    </>
  );
}
