import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';

import ArticlePlaceholder from 'components/ui/article-placeholder';

import { AiFillLeftCircle } from 'react-icons/ai';
import { TfiMedallAlt } from 'react-icons/tfi';
import {
  deleteFeedbackByQuery,
  getPlayerInfo,
  getServerTime,
  getTaskCycles,
  getTasksManager,
  getVacationManager,
  playerLastManager,
  updateTask,
} from 'services/dashboard.service';

// import { MandouBem, Uau, Valeu } from 'components/icons';
import { TaskItem } from 'components/task-item';
import Button from 'components/ui/button';
import { Countdown } from 'components/countdown';
import { clamp } from 'utils/helpers';
import { CheckManagerType, GetTaskStatusLabel } from 'utils/strings';
// import { TaskReport } from './components/task-report';
import { BiCheck, BiUpvote } from 'react-icons/bi';
import { FaCoins } from 'react-icons/fa';
import {
  actionLog,
  actionLogBulk,
  getActions,
} from 'services/actionlog.service';
import Box from 'components/ui/box';
import { useModal } from 'context/ModalContext';
import ModalConclude from 'components/task-item/modal-actions/conclude';
import { HiOutlineHandThumbDown, HiOutlineHandThumbUp } from 'react-icons/hi2';
import ImgFeedback from 'assets/img/comportamento/bg-missoes-not-found.png';
import { CyclesInterface, User } from 'types/user';
import { getStorageItem, removeStorageItem } from 'utils/storage';
import { UserAvatar } from 'components/user-avatar';
import { setInterval } from 'timers';

// import { BiPencil } from 'react-icons/bi';

// const iconSize = 20;

// const Coins: any = {
//   uau_task: <Uau size={iconSize} />,
//   mandou_task: <MandouBem size={iconSize} />,
//   valeu_task: <Valeu size={iconSize} />,
// };

// const CoinLabel: any = {
//   uau_task: '#UAU',
//   mandou_task: '#MANDOUBEM',
//   valeu_task: '#VALEU',
// };

// const StatusLabel: any = {
//   ok: 'Entrega Avaliada',
//   aguardando: 'Aguardando Avaliação',
// };

// const cycles = [new Date('2022-11-18'), new Date('2022-12-18')];
// const cyclesStatus = ['PERÍODO DE AVALIAÇÃO', 'ENVIAR ENTREGAS'];
// const currentCycle = 1;

export function ValidarDestaque() {
  const location = useLocation();
  const { showModal } = useModal();
  const { user } = useContext(AuthContext);

  const [realDatetime, setRealDatetime] = useState<number>(0);

  const [internalUser, setInternalUser] = useState<User>();

  const [loading, setLoading] = useState(true);
  const [gestor, setIsGestor] = useState(false);
  const [data, setData] = useState<any[]>();

  const [filteredData, setFilteredData] = useState<any[]>();

  const [validTasks, setValidTasks] = useState<unknown[]>([]);
  const [rejectedTasks, setRejectedTasks] = useState<unknown[]>([]);
  const [doubledTasks, setDoubledTasks] = useState<unknown[]>([]);
  const [promotedTasks, setPromotedTasks] = useState<unknown[]>([]);

  const [maxValidTasks, setMaxValidTasks] = useState(0);
  const [maxRejectedTasks, setMaxRejectedTasks] = useState(0);
  const [maxDoubledTasks, setMaxDoubledTasks] = useState(0);
  const [maxPromotedTasks, setMaxPromotedTasks] = useState(0);

  const [activeTabDirector, setActiveTabDirector] = useState<
    'diretoria' | 'rede'
  >('diretoria');

  const [playerInfo, setPlayerInfo] = useState<any>();
  // const [isAssessor, setIsAssessor] = useState<boolean>();

  const [taskCycles, setTaskCycles] = useState<CyclesInterface[]>();
  const [currentCycle, setCurrentCycle] = useState(0);

  const [cycleEvaluationFinished, setCycleEvaluationFinished] = useState(false);

  useEffect(() => {
    let interv: any = undefined;

    getServerTime().then((timestamp) => {
      setRealDatetime(timestamp);

      interv = window.setInterval(() => {
        setRealDatetime((prevstate) => prevstate + 1000);
      }, 1000);
    });

    return () => clearInterval(interv);
  }, []);

  const isVacationManager = useMemo(
    () => !!internalUser && !!user && internalUser._id !== user._id,
    [internalUser, user]
  );

  const activeCycleObj = useMemo(
    () => (Array.isArray(taskCycles) ? taskCycles[currentCycle] : undefined),
    [currentCycle, taskCycles]
  );

  const getActiveEvaluationStep: 0 | 2 | 3 | 4 | 4.1 | 5 | null = useMemo(():
    | 0
    | 2
    | 3
    | 4
    | 4.1
    | 5
    | null => {
    if (
      !activeCycleObj ||
      activeCycleObj.status !== 'evaluation' ||
      !realDatetime
    )
      return null;

    if (
      realDatetime > activeCycleObj.date_evaluation_step2_start &&
      realDatetime < activeCycleObj.date_evaluation_step2_finish
    ) {
      // Etapa 2
      return 2;
    } else if (
      realDatetime > activeCycleObj.date_evaluation_step3_start &&
      realDatetime < activeCycleObj.date_evaluation_step3_finish
    ) {
      // Etapa 3
      return 3;
    } else if (
      realDatetime > activeCycleObj.date_evaluation_step4_start &&
      realDatetime < activeCycleObj.date_evaluation_step4_finish
    ) {
      // Etapa 4
      return 4;
    } else if (
      realDatetime > activeCycleObj.date_evaluation_pitStop_start &&
      realDatetime < activeCycleObj.date_evaluation_pitStop_finish
    ) {
      // Pit Stop
      return 4.1;
    } else if (
      realDatetime > activeCycleObj.date_evaluation_step5_start &&
      realDatetime < activeCycleObj.date_evaluation_step5_finish
    ) {
      // Etapa 5
      return 5;
    } else {
      return 0;
    }
  }, [activeCycleObj, realDatetime]);

  // Retorna a etapa atual da missão
  const getTaskStep = useCallback(
    (task: any) => {
      if (!getActiveEvaluationStep || !task || typeof task.status !== 'string')
        return undefined;

      // Etapa 2
      if (task.status === 'rejeitado' || task.status === 'aguardando') {
        return 2;
      }

      // Se tiver Pit Stop
      if (typeof task.prosseguir_gst === 'string') {
        if (
          task.prosseguir_gst === 'next' ||
          task.prosseguir_gst === 'stopped'
        ) {
          return 4.1;
        } else {
          return 5;
        }
      }

      // Etapa 3, 4 e 5
      if (typeof task.multiplicador === 'number') {
        // Etapa 3
        if (task.multiplicador === 1) {
          return 3;
        }

        // Etapa 3
        if (task.status === 'dobrado' && task.multiplicador === 2) {
          return 3;
        }

        // Etapa 4
        if (task.status === 'promovido' && task.multiplicador === 2) {
          return 4;
        }

        // Etapa 4
        if (task.status === 'dobrado' && task.multiplicador === 4) {
          return 4;
        }

        // Etapa 5
        if (task.status === 'promovido' && task.multiplicador === 4) {
          return 5;
        }

        // Etapa 5
        if (task.status === 'dobrado' && task.multiplicador === 8) {
          return 5;
        }

        // Etapa 5
        if (task.status === 'selo') {
          return 5;
        }
      }

      return undefined;
    },
    [getActiveEvaluationStep]
  );

  const getPlayerStep = useCallback(
    (task: any) => {
      if (
        !!task.prosseguir_idGestor &&
        task.prosseguir_idGestor === playerInfo._id
      ) {
        return 4.1;
      }

      if (typeof playerInfo.cod_etapa === 'number') {
        if (playerInfo.cod_etapa === 2) {
          return 2;
        }

        if (playerInfo.cod_etapa === 4) {
          return 4;
        }

        if (playerInfo.cod_etapa === 5) {
          return 5;
        }

        if (playerInfo.cod_etapa === 1 || playerInfo.cod_etapa === 3) {
          if (String(task.original_manager) === String(playerInfo._id)) {
            if (task.status === 'rejeitado' || task.status === 'aguardando') {
              return 2;
            } else {
              return 3;
            }
          } else {
            return 3;
          }
        }

        return 0;
      }

      return 0;
    },
    [playerInfo]
  );

  // TO DO: Mudar nomenclatura. Esse método não é utilizado apenas para reset.
  function canReset(task: any) {
    if (
      !getTaskStep(task) ||
      !getActiveEvaluationStep ||
      !playerInfo ||
      typeof playerInfo.cod_etapa !== 'number' ||
      !task.original_manager
    ) {
      return false;
    }

    if (
      !!task.prosseguir_idGestor &&
      task.prosseguir_idGestor === playerInfo._id
    ) {
      if (getActiveEvaluationStep === 4.1) {
        return true;
      } else {
        return false;
      }
    }

    // if (
    //   getActiveEvaluationStep === 4.1 &&
    //   typeof task.prosseguir_gst === 'string'
    // ) {
    //   const lastEvaluator =
    //     Array.isArray(task.evaluator) && task.evaluator.length
    //       ? task.evaluator[task.evaluator.length - 1]
    //       : undefined;

    //   if (
    //     (task.prosseguir_gst === 'next' || task.prosseguir_gst === 'stopped') &&
    //     lastEvaluator &&
    //     playerInfo._id === lastEvaluator
    //   ) {
    //     return true;
    //   }

    //   if (
    //     task.prosseguir_who_approved &&
    //     task.prosseguir_who_approved === playerInfo._id
    //   ) {
    //     return true;
    //   }

    //   return false;
    // }

    if (
      (playerInfo.cod_etapa === 2 &&
        getActiveEvaluationStep === 2 &&
        (getTaskStep(task) as number) >= 2) ||
      (playerInfo.cod_etapa === 4 &&
        getActiveEvaluationStep === 4 &&
        (getTaskStep(task) as number) >= 4) ||
      (playerInfo.cod_etapa === 5 &&
        getActiveEvaluationStep === 5 &&
        (getTaskStep(task) as number) >= 5)
    ) {
      return true;
    }

    if (
      String(task.original_manager) === String(playerInfo._id) &&
      (getActiveEvaluationStep === 2 || getActiveEvaluationStep === 3) &&
      (playerInfo.cod_etapa === 1 || playerInfo.cod_etapa === 3)
    ) {
      return (getTaskStep(task) as number) >= getActiveEvaluationStep;
    }

    if (
      (playerInfo.cod_etapa === 1 || playerInfo.cod_etapa === 3) &&
      String(task.original_manager) !== String(playerInfo._id) &&
      getActiveEvaluationStep === 3 &&
      (getTaskStep(task) as number) >= 3
    ) {
      return true;
    }

    return false;
  }

  function canDouble(task: any) {
    if (
      !canReset(task) ||
      !getTaskStep(task) ||
      !getActiveEvaluationStep ||
      getTaskStep(task) !== getActiveEvaluationStep ||
      getActiveEvaluationStep < 3 ||
      doubledTasks.length >= maxDoubledTasks ||
      getPlayerStep(task) === 4.1 ||
      typeof task.multiplicador !== 'number'
    ) {
      return false;
    }

    if (getTaskStep(task) === 3 && task.multiplicador === 1) {
      return true;
    }

    if (getTaskStep(task) === 4 && task.multiplicador === 2) {
      return true;
    }

    if (getTaskStep(task) === 5 && task.multiplicador === 4) {
      return true;
    }

    return false;
  }

  function canPromote(task: any) {
    if (
      !canReset(task) ||
      !getTaskStep(task) ||
      !getActiveEvaluationStep ||
      getTaskStep(task) !== getActiveEvaluationStep ||
      getActiveEvaluationStep < 3 ||
      promotedTasks.length >= maxPromotedTasks ||
      typeof task.multiplicador !== 'number'
    ) {
      return false;
    }

    if (
      task.status === 'promovido' &&
      getTaskStep(task) === 4.1 &&
      getPlayerStep(task) === 4.1 &&
      getActiveEvaluationStep === 4.1 &&
      task.prosseguir_gst === 'next'
    ) {
      return true;
    }

    if (task.status === 'dobrado') {
      if (getTaskStep(task) === 3 && task.multiplicador === 2) {
        return true;
      }

      if (getTaskStep(task) === 4 && task.multiplicador === 4) {
        return true;
      }

      if (getTaskStep(task) === 5 && task.multiplicador === 8) {
        return true;
      }
    }

    return false;
  }

  function canEdit(task: any) {
    if (
      !activeCycleObj ||
      activeCycleObj.status !== 'evaluation' ||
      !canReset(task) ||
      typeof playerInfo.cod_etapa !== 'number' ||
      playerInfo.cod_etapa < 1 ||
      playerInfo.cod_etapa > 4
    ) {
      return false;
    }

    if (task.status === 'aguardando') {
      return true;
    }

    if (task.multiplicador === 1 && getActiveEvaluationStep === 3) {
      return true;
    }

    if (
      task.status === 'promovido' &&
      task.multiplicador === 2 &&
      getActiveEvaluationStep === 4
    ) {
      return true;
    }

    return false;
  }

  function canEvaluate(item: any) {
    try {
      return (
        getTaskStep(item) === getActiveEvaluationStep &&
        !!taskCycles &&
        taskCycles[currentCycle].status === 'evaluation'
      );
    } catch (e) {
      return false;
    }
  }

  const getUserInfo = useCallback(async () => {
    try {
      const playerInfoResult = await getPlayerInfo(internalUser?._id);

      if (playerInfoResult) {
        setIsGestor(
          playerInfoResult.cod_etapa && playerInfoResult.cod_etapa > 0
        );
        setPlayerInfo(playerInfoResult);

        const taskCycleResults = await getTaskCycles();
        setTaskCycles(taskCycleResults);

        const cycle = taskCycleResults.findIndex(
          (item: any) => item.status === 'evaluation'
        );

        if (cycle >= 0) {
          setCurrentCycle(cycle);
        }

        setLoading(false);
      }
    } catch (e) {
      console.log(e);
    }
  }, [internalUser?._id]);

  async function handleReset(task: any) {
    if (!canReset(task)) return false;

    const newObj = { ...task };

    delete newObj.evaluator;

    if (getActiveEvaluationStep === 2) {
      delete newObj.level;
      delete newObj.multiplicador;
      delete newObj.prosseguir_gst;
      delete newObj.prosseguir_idGestor;
      delete newObj.prosseguir_who_approved;

      newObj.status = 'aguardando';

      await deleteFeedbackByQuery(`taskId:'${newObj._id}'`);
    } else {
      if (
        !!task.evaluator?.length &&
        task.evaluator.includes(String(playerInfo._id))
      ) {
        newObj.evaluator = [];

        for (let index = 0; index < task.evaluator.length; index++) {
          const id = task.evaluator[index];

          newObj.evaluator.push(id);

          if (id === String(playerInfo._id)) {
            break;
          }
        }
      }

      if (getActiveEvaluationStep === 3) {
        delete newObj.level;
        delete newObj.prosseguir_gst;
        delete newObj.prosseguir_idGestor;
        delete newObj.prosseguir_who_approved;

        newObj.status = 'aprovado';
        newObj.multiplicador = 1;
      } else if (getActiveEvaluationStep === 4) {
        delete newObj.prosseguir_gst;
        delete newObj.prosseguir_idGestor;
        delete newObj.prosseguir_who_approved;

        newObj.status = 'promovido';
        newObj.multiplicador = 2;
        newObj.level = 2;
      } else if (getActiveEvaluationStep === 4.1) {
        delete newObj.prosseguir_who_approved;

        newObj.prosseguir_gst = 'next';
        newObj.status = 'promovido';
        newObj.multiplicador = 4;
        newObj.level = 3;
      } else if (getActiveEvaluationStep === 5) {
        newObj.status = 'promovido';
        newObj.multiplicador = 4;
        newObj.level = 3;
      }

      if (!!newObj.feedback?.length) {
        let evaluators: any;

        if (Array.isArray(newObj.evaluator)) {
          evaluators = [newObj.original_manager, ...newObj.evaluator];
        } else {
          evaluators = [newObj.original_manager];
        }

        const evaluatorsWithoutMe = evaluators.filter(
          (x: string) => x !== String(playerInfo._id)
        );

        const feedbacksWithoutMe = newObj.feedback
          .filter((x: any) => {
            if (evaluatorsWithoutMe?.length) {
              const evaluatorId = x.evaluatorId.replace(/F(0+)?/g, '');
              return !evaluatorsWithoutMe.includes(evaluatorId);
            }

            return true;
          })
          .map((x: any) => x._id);

        if (feedbacksWithoutMe.length) {
          const deletedFeedbacks: any = [];

          feedbacksWithoutMe.forEach((feedbackId: string) =>
            deletedFeedbacks.push(deleteFeedbackByQuery(`_id:'${feedbackId}'`))
          );

          await Promise.all(deletedFeedbacks);
        }
      }
    }

    if (isVacationManager) {
      if (!newObj.substitute_managers) {
        newObj.substitute_managers = [];
      }

      const timestamp = await getServerTime();

      newObj.substitute_managers.push({
        playerId: user?._id,
        playerName: user?.name,
        status: 'reset',
        time: { $date: timestamp },
      });
    }

    await updateTask(newObj);

    loadData();
  }

  async function handleAccept(item: any) {
    let valid = [...validTasks];
    valid.push(item);
    setValidTasks(valid);

    let updatedData = { ...item };

    updatedData.status = 'aprovado';
    updatedData.multiplicador = updatedData.multiplicador || 1;

    let managerTypeResult = CheckManagerType({
      user: playerInfo,
      item,
    });

    if (managerTypeResult === 'equipe') {
      if (!updatedData.evaluator) {
        updatedData.evaluator = [];
      }

      updatedData.evaluator.push(playerInfo.gestor);
    }

    if (isVacationManager) {
      if (!updatedData.substitute_managers) {
        updatedData.substitute_managers = [];
      }

      const timestamp = await getServerTime();

      updatedData.substitute_managers.push({
        playerId: user?._id,
        playerName: user?.name,
        status: 'aprovado',
        time: { $date: timestamp },
      });
    }

    await updateTask(updatedData);

    loadData();
  }

  async function handleStop(item: any) {
    let managerTypeResult = CheckManagerType({
      user: playerInfo,
      item,
    });

    let updatedData = { ...item };

    if (
      updatedData.prosseguir_gst &&
      updatedData.prosseguir_gst === 'next' &&
      managerTypeResult === 'pitstop'
    ) {
      updatedData.prosseguir_gst = 'stopped';
    }

    updatedData.status = 'concluido';

    if (isVacationManager) {
      if (!updatedData.substitute_managers) {
        updatedData.substitute_managers = [];
      }

      const timestamp = await getServerTime();

      updatedData.substitute_managers.push({
        playerId: user?._id,
        playerName: user?.name,
        status: 'concluido',
        time: { $date: timestamp },
      });
    }

    await updateTask(updatedData);

    loadData();
  }

  async function handleReject(item: any) {
    let rejected = [...rejectedTasks];
    rejected.push(item);
    setRejectedTasks(rejected);

    let updatedData = { ...item };
    updatedData.status = 'rejeitado';

    if (isVacationManager) {
      if (!updatedData.substitute_managers) {
        updatedData.substitute_managers = [];
      }

      const timestamp = await getServerTime();

      updatedData.substitute_managers.push({
        playerId: user?._id,
        playerName: user?.name,
        status: 'rejeitado',
        time: { $date: timestamp },
      });
    }

    await updateTask(updatedData);

    loadData();
  }

  async function handleDoubled(item: any) {
    let doubled = [...doubledTasks];
    doubled.push(item);
    setDoubledTasks(doubled);

    let multiplicador = 1;

    let managerTypeResult = CheckManagerType({
      user: playerInfo,
      item,
    });

    if (managerTypeResult === 'solucoes') {
      multiplicador = 2;
    } else if (managerTypeResult === 'executivo') {
      multiplicador = 4;
    } else if (managerTypeResult === 'diretoria') {
      multiplicador = 8;
    }

    let updatedData = { ...item };
    updatedData.status = 'dobrado';
    updatedData.multiplicador = multiplicador;

    if (isVacationManager) {
      if (!updatedData.substitute_managers) {
        updatedData.substitute_managers = [];
      }

      const timestamp = await getServerTime();

      updatedData.substitute_managers.push({
        playerId: user?._id,
        playerName: user?.name,
        status: 'dobrado',
        time: { $date: timestamp },
      });
    }

    await updateTask(updatedData);

    loadData();
  }

  async function handlePromote(item: any) {
    let managerTypeResult = CheckManagerType({
      user: playerInfo,
      item,
    });

    // if (
    //   !(
    //     managerTypeResult === 'pitstop' &&
    //     item.prosseguir_gst &&
    //     item.prosseguir_gst === 'next'
    //   )
    // ) {
    let promoted = [...promotedTasks];
    promoted.push(item);
    setPromotedTasks(promoted);
    // }

    let updatedData = { ...item };

    let level = 1;

    if (managerTypeResult === 'solucoes') {
      level = 2;
    } else if (
      managerTypeResult === 'executivo' ||
      playerInfo.prosseguir_gst === 1
    ) {
      level = 3;
    } else if (managerTypeResult === 'diretoria') {
      level = 4;
    }

    let managerProsseguir: null | string = null;

    if (playerInfo.prosseguir_gst === 1) {
      updatedData.prosseguir_gst = 'next';
      updatedData.prosseguir_idGestor = playerInfo.gestor;
    } else if (
      updatedData.prosseguir_gst &&
      updatedData.prosseguir_gst === 'next' &&
      managerTypeResult === 'pitstop'
    ) {
      const lastManager = await playerLastManager(internalUser?.extra.id);

      if (
        lastManager?.gestor_ultimo_nivel &&
        lastManager?.gestor_ultimo_nivel._id
      ) {
        managerProsseguir = lastManager?.gestor_ultimo_nivel._id;
      }

      updatedData.prosseguir_gst = 'approved';
      updatedData.prosseguir_who_approved = playerInfo._id;
    }

    updatedData.status = 'promovido';
    updatedData.level = level;

    if (!updatedData.evaluator) {
      updatedData.evaluator = [];
    }

    if (managerTypeResult === 'diretoria') {
      updatedData.multiplicador = 12;
      updatedData.status = 'selo';
      updatedData.evaluator.push('Selo Missão Destaque');
    } else if (playerInfo.gestor || managerProsseguir) {
      if (typeof managerProsseguir === 'string') {
        updatedData.evaluator.push(managerProsseguir);
      } else {
        updatedData.evaluator.push(playerInfo.gestor);
      }
    }

    if (isVacationManager) {
      if (!updatedData.substitute_managers) {
        updatedData.substitute_managers = [];
      }

      const timestamp = await getServerTime();

      updatedData.substitute_managers.push({
        playerId: user?._id,
        playerName: user?.name,
        status: updatedData.status,
        time: { $date: timestamp },
      });
    }

    await updateTask(updatedData);

    loadData();
  }

  const loadData = useCallback(async () => {
    const myTasks = await getTasksManager(internalUser?.extra.id);
    setData(myTasks);
  }, [internalUser?.extra.id]);

  // const checkEvaluationFinish = useCallback(async () => {
  //   if (!internalUser) return;

  //   try {
  //     const logs = await getActions(internalUser._id, 'evaluation_finish');
  //     const cycleIsFinished = logs.find(
  //       (item: any) =>
  //         !!taskCycles &&
  //         item.attributes.schedule_id === taskCycles[currentCycle]._id
  //     );
  //     setCycleEvaluationFinished(!!cycleIsFinished);
  //   } catch (e) {
  //     setCycleEvaluationFinished(false);
  //   }
  // }, [currentCycle, internalUser, taskCycles]);

  // async function handleEvaluationFinish() {
  //   // if(managerType === 'equipe'){
  //   // }
  //   // else if(managerType === 'solucoes'){
  //   // } else if(managerType === 'executivo'){
  //   // } else if(managerType === 'diretoria'){
  //   // };
  //   try {
  //     setCycleEvaluationFinished(true);

  //     const payload: any = {
  //       schedule_id: !!taskCycles ? taskCycles[currentCycle]._id : '',
  //     };

  //     if (isVacationManager) {
  //       payload.lateralidade_userId = user?._id;
  //     }

  //     actionLogBulk(internalUser?._id, 'evaluation_finish', payload);
  //   } catch (e) {}
  // }

  // function callBackConclude(obj: { id: string }) {
  //   if (!obj?.id) return;

  //   if (obj.id === 'conclude') {
  //     // console.log('conclude', obj);

  //     handleEvaluationFinish();
  //   }
  // }

  const isCurrentEvaluator = useCallback(
    (task: any) => {
      if (!internalUser || !task || !playerInfo) return false;

      let evaluators = [];

      if (!!task.original_manager?.length) {
        evaluators.push(task.original_manager);
      }

      if (Array.isArray(task.evaluator) && task.evaluator?.length) {
        evaluators = evaluators.concat(task.evaluator);
      }

      evaluators = evaluators.filter((x) => !x.match(/selo/gi));

      if (evaluators.length) {
        const matriculaEvaluator = parseInt(
          evaluators[evaluators.length - 1].replace('F', '')
        );
        const currentId = parseInt(internalUser.extra.id);

        return matriculaEvaluator === currentId;
      } else {
        return false;
      }
    },
    [playerInfo, internalUser]
  );

  // const isCurrentEvaluator = useCallback(
  //   async (item: any) => {
  //     if (!internalUser || !item.evaluator || !item.evaluator.length) return false;

  //     const matriculaEvaluator = parseInt(
  //       item.evaluator[item.evaluator.length - 1].replace('F', '')
  //     );
  //     const currentId = parseInt(internalUser.extra.id);
  //     return matriculaEvaluator === currentId;
  //   },
  //   [internalUser]
  // );

  const tasksProsseguirAssessor: any[] = useMemo((): any[] => {
    if (!Array.isArray(filteredData) || !filteredData.length || !playerInfo)
      return [];

    const tasks = filteredData.filter((task) => {
      if (
        !!task.prosseguir_idGestor &&
        task.prosseguir_idGestor === playerInfo._id
      )
        return true;

      return false;
    });

    return tasks;
  }, [filteredData, playerInfo]);

  const hasRede = useMemo(() => {
    if (!data || !data.length || !playerInfo || !activeCycleObj)
      return undefined;

    const isDirector = playerInfo.cod_etapa && playerInfo.cod_etapa === 5;

    if (isDirector) {
      const itemsByCycle = data?.filter((item) => {
        return (
          item.created >= activeCycleObj?.date_start &&
          item.created <= activeCycleObj?.date_finish
        );
      });

      if (itemsByCycle.length) {
        return !!itemsByCycle.filter(
          (x: { prosseguir_gst: string }) =>
            !!x.prosseguir_gst && x.prosseguir_gst === 'approved'
        ).length;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [activeCycleObj, data, playerInfo]);

  const htmlCountdownStep = useMemo(() => {
    if (
      !activeCycleObj ||
      !getActiveEvaluationStep ||
      !data ||
      !data.length ||
      !playerInfo
    )
      return <></>;

    const itemsByCycle: any[] = data.filter((task) => {
      return (
        task.created >= activeCycleObj.date_start &&
        task.created <= activeCycleObj.date_finish
      );
    });

    if (itemsByCycle.length) {
      const userTaskStep: number[] = itemsByCycle
        ?.map((task) =>
          typeof getPlayerStep(task) === 'number'
            ? (getPlayerStep(task) as number)
            : 0
        )
        .sort(() => -1);

      if (userTaskStep.includes(getActiveEvaluationStep)) {
        const date_finish =
          getActiveEvaluationStep === 2
            ? activeCycleObj?.date_evaluation_step2_finish
            : getActiveEvaluationStep === 3
            ? activeCycleObj?.date_evaluation_step3_finish
            : getActiveEvaluationStep === 4
            ? activeCycleObj?.date_evaluation_step4_finish
            : getActiveEvaluationStep === 4.1
            ? activeCycleObj?.date_evaluation_pitStop_finish
            : activeCycleObj?.date_evaluation_step5_finish;

        return (
          <div className="py-4">
            <p className="text-xs text-alice-blue block font-thin">
              Fluxo de apreciação atual:{' '}
              <strong className="text-xs text-alice-blue block font-normal whitespace-nowrap">
                {getActiveEvaluationStep === 4.1
                  ? 'Prosseguir'
                  : `Etapa ${getActiveEvaluationStep}`}
              </strong>
            </p>
            <p className="text-xs text-alice-blue block font-thin">
              O período de apreciação desta etapa termina em:{' '}
              <strong className="text-xs text-alice-blue block font-normal whitespace-nowrap">
                <Countdown key="missao" deadline={new Date(date_finish)} />
              </strong>
            </p>
          </div>
        );
      } else {
        const hasAfter = userTaskStep?.find(
          (x: number) => x > (getActiveEvaluationStep as number)
        );

        const date_start =
          hasAfter === 2
            ? activeCycleObj?.date_evaluation_step2_start
            : hasAfter === 3
            ? activeCycleObj?.date_evaluation_step3_start
            : hasAfter === 4
            ? activeCycleObj?.date_evaluation_step4_start
            : hasAfter === 4.1
            ? activeCycleObj?.date_evaluation_pitStop_start
            : activeCycleObj?.date_evaluation_step5_start;

        if (!!hasAfter) {
          return (
            <div className="py-4">
              <p className="text-xs text-alice-blue block font-thin">
                O período de apreciação começa em:{' '}
                <strong className="text-xs text-alice-blue block font-normal whitespace-nowrap">
                  <Countdown key="missao" deadline={new Date(date_start)} />
                </strong>
              </p>
            </div>
          );
        } else {
          return <></>;
        }
      }
    } else {
      const codEtapa =
        playerInfo.cod_etapa === 2
          ? 2
          : playerInfo.cod_etapa === 1 || playerInfo.cod_etapa === 3
          ? 3
          : playerInfo.cod_etapa === 4
          ? 4
          : playerInfo.cod_etapa === 5
          ? 5
          : null;

      if (codEtapa) {
        if (getActiveEvaluationStep === codEtapa) {
          const date_finish =
            getActiveEvaluationStep === 2
              ? activeCycleObj?.date_evaluation_step2_finish
              : getActiveEvaluationStep === 3
              ? activeCycleObj?.date_evaluation_step3_finish
              : getActiveEvaluationStep === 4
              ? activeCycleObj?.date_evaluation_step4_finish
              : activeCycleObj?.date_evaluation_step5_finish;

          return (
            <div className="py-4">
              <p className="text-xs text-alice-blue block font-thin">
                Fluxo de apreciação atual:{' '}
                <strong className="text-xs text-alice-blue block font-normal whitespace-nowrap">
                  {`Etapa ${getActiveEvaluationStep}`}
                </strong>
              </p>
              <p className="text-xs text-alice-blue block font-thin">
                O período de apreciação desta etapa termina em:{' '}
                <strong className="text-xs text-alice-blue block font-normal whitespace-nowrap">
                  <Countdown key="missao" deadline={new Date(date_finish)} />
                </strong>
              </p>
            </div>
          );
        } else {
          if (getActiveEvaluationStep < codEtapa) {
            const date_start =
              codEtapa === 2
                ? activeCycleObj?.date_evaluation_step2_start
                : codEtapa === 3
                ? activeCycleObj?.date_evaluation_step3_start
                : codEtapa === 4
                ? activeCycleObj?.date_evaluation_step4_start
                : activeCycleObj?.date_evaluation_step5_start;

            return (
              <div className="py-4">
                <p className="text-xs text-alice-blue block font-thin">
                  O período de apreciação começa em:{' '}
                  <strong className="text-xs text-alice-blue block font-normal whitespace-nowrap">
                    <Countdown key="missao" deadline={new Date(date_start)} />
                  </strong>
                </p>
              </div>
            );
          } else {
            return <></>;
          }
        }
      } else {
        return <></>;
      }
    }
  }, [
    activeCycleObj,
    data,
    getActiveEvaluationStep,
    getPlayerStep,
    playerInfo,
  ]);

  useEffect(() => {
    async function updateData() {
      if (typeof hasRede !== 'boolean') return setFilteredData([]);

      const isDirector = playerInfo.cod_etapa && playerInfo.cod_etapa === 5;

      try {
        let cycleData: any = data?.filter((item) => {
          return (
            !!activeCycleObj &&
            item.created >= activeCycleObj?.date_start &&
            item.created <= activeCycleObj?.date_finish
          );
        });

        if (!cycleData) cycleData = [];

        if (hasRede) {
          if (activeTabDirector === 'rede') {
            cycleData = cycleData.filter(
              (x: { prosseguir_gst: string }) =>
                !!x.prosseguir_gst && x.prosseguir_gst === 'approved'
            );
          } else {
            cycleData = cycleData.filter(
              (x: { prosseguir_gst: string }) => !x.prosseguir_gst
            );
          }
        }

        let dataWithoutProsseguir = cycleData;

        if (!isDirector) {
          dataWithoutProsseguir = cycleData?.filter((task: any) => {
            if (
              !!task.prosseguir_idGestor &&
              task.prosseguir_idGestor === playerInfo._id
            )
              return false;

            return true;
          });
        }

        setMaxValidTasks(cycleData.length);

        setMaxRejectedTasks(cycleData.length);

        const currentMaxDoubledTasks = dataWithoutProsseguir.filter(
          (task: any) => {
            if (task.status === 'rejeitado') return false;

            return true;
          }
        );
        setMaxDoubledTasks(
          clamp(Math.ceil(currentMaxDoubledTasks.length * 0.4), 1, Infinity)
        );

        setMaxPromotedTasks(
          clamp(Math.ceil(dataWithoutProsseguir.length * 0.1), 1, Infinity)
        );

        const currentValidTasks = cycleData.filter(
          (item: any) =>
            item.status !== 'rejeitado' && item.status !== 'aguardando'
        );
        setValidTasks(currentValidTasks);

        const currentRejectedTasks = cycleData.filter(
          (item: any) => item.status === 'rejeitado'
        );
        setRejectedTasks(currentRejectedTasks);

        const currentDoubledTasks = dataWithoutProsseguir.filter(
          (task: any) => {
            if (isCurrentEvaluator(task) && task.status === 'dobrado') {
              return true;
            }

            if (
              typeof getTaskStep(task) === 'number' &&
              (getTaskStep(task) as number) > 2
            ) {
              if (
                (getTaskStep(task) as number) === 5 &&
                getPlayerStep(task) === 5 &&
                task.multiplicador >= 8
              ) {
                return true;
              }

              if ((getTaskStep(task) as number) > getPlayerStep(task)) {
                return true;
              }

              return false;
            }

            return false;
          }
        );
        setDoubledTasks(currentDoubledTasks);

        const currentPromotedTasks = dataWithoutProsseguir.filter(
          (task: any) => {
            if (
              typeof getTaskStep(task) === 'number' &&
              (getTaskStep(task) as number) > 2
            ) {
              if (
                (getTaskStep(task) as number) === 5 &&
                getPlayerStep(task) === 5 &&
                task.multiplicador >= 12
              ) {
                return true;
              }

              if ((getTaskStep(task) as number) > getPlayerStep(task)) {
                return true;
              }

              return false;
            }

            return false;
          }
        );
        setPromotedTasks(currentPromotedTasks);

        setFilteredData(cycleData);

        // await checkEvaluationFinish();

        setLoading(false);
        // console.log(promotedTasks, maxValidTasks, maxPromotedTasks);

        // carregar
        // setCycleStats(cycleData.status);
      } catch (e) {
        setFilteredData([]);
      }
    }

    updateData();
  }, [
    activeCycleObj,
    activeTabDirector,
    data,
    getPlayerStep,
    getTaskStep,
    hasRede,
    isCurrentEvaluator,
    playerInfo,
  ]);

  useEffect(() => {
    if (!internalUser) return;

    setLoading(true);
    getUserInfo();
  }, [getUserInfo, internalUser]);

  useEffect(() => {
    if (!gestor) return;

    setLoading(true);
    loadData();
  }, [gestor, loadData]);

  // useEffect(() => {
  //   if (Array.isArray(data) && playerInfo) {
  //     let _isAssessor = false;
  //     data?.forEach((item) => {
  //       if (CheckManagerType({ user: playerInfo, item }) === 'pitstop') {
  //         _isAssessor = true;
  //       }
  //     });
  //     setIsAssessor(_isAssessor);
  //   }
  // }, [data, playerInfo]);

  useEffect(() => {
    try {
      const locationState: any = location.state;
      // console.log(locationState);
      if (locationState && locationState.direction) {
        if (locationState.direction === 'back') {
          if (gestor) {
            loadData();
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [gestor, loadData, location]);

  useEffect(() => {
    if (!user) return;

    async function handleUser() {
      if (!!user) {
        const storedManager = getStorageItem('manager_lateralidade');

        if (!!storedManager && storedManager.length) {
          const vacationManagers = await getVacationManager(user._id);

          const vacationManager = vacationManagers?.length
            ? vacationManagers.find((x) => x._id === storedManager)
            : null;

          if (!!vacationManager?._id && !!vacationManager.extra?.id) {
            setInternalUser(vacationManager);
          } else {
            setInternalUser(user);
          }
        } else {
          setInternalUser(user);
        }
      }
    }

    handleUser();
  }, [user]);

  // useEffect(() => {
  //   return () => removeStorageItem('manager_lateralidade');
  // }, []);

  if (!user || !internalUser || typeof gestor !== 'boolean') return null;

  if (!gestor && !loading) {
    return (
      <div className="mx-auto container p-4 lg:p-10">
        <div className="flex flex-row items-center space-x-4 mb-16">
          <div>
            <Link to="../">
              <AiFillLeftCircle size={36} className="text-slate-blue" />
            </Link>
          </div>
          <h1 className="text-xl lg:text-3xl text-white font-semibold">
            Realização Destaque
          </h1>
        </div>

        <div className="border-2 border-electric-blue rounded-3xl p-8 bg-oxford-blue">
          <p className="text-dandelion text-lg font-semibold block text-center block">
            Você não possui acesso à esta página.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mx-auto container p-4 lg:p-10">
        <div className={`flex flex-row items-center space-x-4 mb-16`}>
          <div>
            <Link to="../">
              <AiFillLeftCircle size={36} className="text-slate-blue" />
            </Link>
          </div>
          <h1 className="text-xl lg:text-3xl text-white font-semibold">
            Realização Destaque
          </h1>
        </div>

        {!!isVacationManager && (
          <div className="border-2 border-electric-blue rounded-3xl px-8 py-6 mb-12 bg-rich-black bg-opacity-80 ">
            <p className="text-dandelion text-lg font-semibold block mb-3">
              Você está avaliando por:
            </p>

            <div className="flex flex-row items-center space-x-4">
              <div className="w-[44px] h-[44px]">
                <UserAvatar
                  data={{
                    image: internalUser.image,
                    ...internalUser.extra,
                  }}
                />
              </div>
              <p>
                <span className="text-xs font-thin">
                  <strong className="text-base font-semibold">
                    {internalUser.name}
                  </strong>{' '}
                  ({internalUser._id})
                </span>
                <span className="block text-xs font-thin">
                  <span className="text-electric-blue uppercase">
                    {internalUser.extra?.dependencia}
                  </span>{' '}
                  - {internalUser.extra?.funcao}
                </span>
              </p>
              <Link
                to="../"
                className={`!ml-8 leading-none ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white hover:text-white hover:no-underline no-underline rounded py-2 px-3`}
              >
                SAIR
              </Link>
            </div>
          </div>
        )}

        <div className="border-2 border-electric-blue rounded-3xl px-8 py-6 mb-12 bg-oxford-blue">
          <p className="text-dandelion text-lg font-semibold block mb-3">
            Apreciação das entregas enviadas por sua equipe
          </p>
          <p className="text-white text-base font-light m-0">
            Cada entrega recebida por você foi promovida pelo gerente de
            soluções do time responsável pela missão e pontuada com 600 pontos.
            Essa pontuação poderá ser dobrada por você, caso a entrega seja
            relevante, e promovida para o próximo nível se for extraordinária.
            Envie feedback para a equipe e seja bonificado com pontos.
          </p>

          <p className="font-bold mt-6">
            <span className="text-electric-blue">ATENÇÃO:</span> Verifique todas
            as missões cadastradas antes de iniciar o processo de validação.
          </p>

          {/* <p>Agora, cada entrega cadastrada e validada pelo gestor da sua equipe soma 300 pontos no seu saldo.</p>
          <p>Essa pontuação poderá ser dobrada pelo seu gestor. Dependendo da relevância da sua entrega o gestor poderá enviá-la para validação do nível gerencial acima.</p>
          <p>Assim, a entrega poderá ser reconhecida em diversos níveis da organização e alcançar até 3600 pontos.</p> */}
        </div>

        <div className="w-full flex flex-col lg:flex-row lg:justify-between">
          <div className="lg:w-[32.5%]">
            <h2 className="text-xl text-white block mb-6 font-semibold">
              Acompanhe as Realizações Destaque
            </h2>

            <Box>
              {loading || !filteredData ? (
                <div className="grid grid-cols-1 gap-3">
                  <ArticlePlaceholder />
                </div>
              ) : (
                <>
                  <div className="px-8 mb-8">
                    <p className="text-white mb-8 text-base font-normal block">
                      Escolha a fase
                    </p>

                    <ul className="space-y-4 flex flex-col">
                      {taskCycles &&
                        taskCycles.map((cycle: any, index: number) => {
                          if (
                            cycle.status !== 'evaluation' &&
                            !!isVacationManager
                          )
                            return null;

                          return (
                            <li key={cycle._id}>
                              <button
                                className={`${
                                  currentCycle === index
                                    ? 'text-alice-blue bg-slate-blue'
                                    : 'text-white'
                                } text-sm flex items-center justify-center font-semibold uppercase ring-2 ring-slate-blue rounded w-full h-10`}
                                onClick={() => setCurrentCycle(index)}
                              >
                                {cycle.title} -{' '}
                                {GetTaskStatusLabel(cycle.status)}
                              </button>
                            </li>
                          );
                        })}
                    </ul>
                  </div>

                  {taskCycles &&
                  taskCycles[currentCycle]?.status !== 'closed' ? (
                    <div className="bg-maastricht-blue rounded-3xl p-8 space-y-4">
                      <p className="block text-white leading-none font-normal text-base">
                        Apreciação: {activeCycleObj?.title}
                      </p>

                      {!!hasRede && (
                        <div className="flex items-center justify-between">
                          <button
                            className={`${
                              activeTabDirector === 'diretoria'
                                ? 'text-alice-blue bg-slate-blue'
                                : 'text-white'
                            } text-sm flex items-center justify-center font-semibold uppercase ring-2 ring-slate-blue rounded w-[45%] h-10`}
                            onClick={() => setActiveTabDirector('diretoria')}
                          >
                            Diretoria
                          </button>

                          <button
                            className={`${
                              activeTabDirector === 'rede'
                                ? 'text-alice-blue bg-slate-blue'
                                : 'text-white'
                            } text-sm flex items-center justify-center font-semibold uppercase ring-2 ring-slate-blue rounded w-[45%] h-10`}
                            onClick={() => setActiveTabDirector('rede')}
                          >
                            Rede
                          </button>
                        </div>
                      )}

                      {activeCycleObj && activeCycleObj.status === 'open' ? (
                        <>
                          <div>
                            <p className="text-xs text-alice-blue block font-thin">
                              O período de apreciação desta fase inicia em:
                            </p>
                            <div className="text-xs text-alice-blue block font-normal">
                              <Countdown
                                key="missao"
                                deadline={new Date(activeCycleObj.date_finish)}
                              />
                            </div>
                          </div>
                        </>
                      ) : null}

                      {activeCycleObj &&
                      activeCycleObj.status === 'evaluation' ? (
                        <>
                          {htmlCountdownStep}

                          {/* {cycleEvaluationFinished ? (
                            <div>
                              <div className="text-xs text-alice-blue inline-block font-normal">
                                Você já fez a validação desse ciclo.
                              </div>
                            </div>
                          ) : null} */}

                          {playerInfo?.cod_etapa &&
                          playerInfo?.cod_etapa >= 1 &&
                          playerInfo?.cod_etapa <= 3 ? (
                            <>
                              <div className="space-x-3 flex flex-row items-center justify-between pt-4 border-t border-prussian-blue-2">
                                <HiOutlineHandThumbUp className="text-xl" />
                                <span className="flex-1 text-white text-xs leading-none font-thin">
                                  Total de realizações validadas
                                </span>
                                <div className="text-right text-yellow font-normal leading-none text-base">
                                  {validTasks.length}/{maxValidTasks}
                                </div>
                              </div>

                              <div className="space-x-3 flex flex-row items-center justify-between pt-4 border-t border-prussian-blue-2">
                                <HiOutlineHandThumbDown className="text-xl" />
                                <span className="flex-1 text-white text-xs leading-none font-thin">
                                  Total de realizações devolvidas
                                </span>
                                <div className="text-right text-yellow font-normal leading-none text-base">
                                  {rejectedTasks.length}/{maxRejectedTasks}
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {playerInfo?.cod_etapa &&
                          playerInfo?.cod_etapa !== 2 ? (
                            <>
                              <div className="space-x-3 flex flex-row items-center justify-between pt-4 border-t border-prussian-blue-2">
                                <FaCoins className="text-xl" />
                                <span className="flex-1 text-white text-xs leading-none font-thin">
                                  Total de realizações bonificadas
                                </span>
                                <div className="text-right text-yellow font-normal leading-none text-base">
                                  {doubledTasks.length}/{maxDoubledTasks}
                                </div>
                              </div>

                              <div className="space-x-3 flex flex-row items-center justify-between pt-4 border-t border-prussian-blue-2">
                                {playerInfo?.cod_etapa === 5 ? (
                                  <TfiMedallAlt className="text-xl" />
                                ) : (
                                  <BiUpvote className="text-xl" />
                                )}
                                <span className="flex-1 text-white text-xs leading-none font-thin">
                                  {playerInfo?.cod_etapa === 5
                                    ? 'Total de selos concedidos'
                                    : 'Total de realizações promovidas'}
                                </span>
                                <div className="text-right text-yellow font-normal leading-none text-base">
                                  {promotedTasks.length}/{maxPromotedTasks}
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {tasksProsseguirAssessor.length ? (
                            <>
                              <div className="space-x-3 flex flex-row items-center justify-between pt-4 border-t border-prussian-blue-2">
                                <BiUpvote
                                  className={`text-xl rotate-90 transform`}
                                />
                                <span className="flex-1 text-white text-xs leading-none font-thin">
                                  Total de realizações enviadas para apreciação
                                  da diretoria
                                </span>
                                <div className="text-right text-yellow font-normal leading-none text-base">
                                  {
                                    tasksProsseguirAssessor.filter(
                                      (item) =>
                                        item.prosseguir_who_approved &&
                                        item.prosseguir_who_approved ===
                                          internalUser.extra.id
                                    ).length
                                  }
                                  /{tasksProsseguirAssessor.length}
                                </div>
                              </div>
                            </>
                          ) : (
                            <></>
                          )}

                          {/* {!loading && !cycleEvaluationFinished ? (
                            <div className="mt-11">
                              <Button
                                onClick={() => showModal('validation-conclude')}
                                className="w-full p-2 mx-auto"
                              >
                                CONCLUIR VALIDAÇÃO
                              </Button>
                            </div>
                          ) : null} */}
                        </>
                      ) : null}
                    </div>
                  ) : null}
                </>
              )}
            </Box>
          </div>

          <div className="lg:w-[64.8%]">
            <h2 className="text-xl text-white block mb-6 font-semibold">
              Realizações Destaque cadastradas
              {!loading && filteredData ? `: ${activeCycleObj?.title}` : ''}
            </h2>

            <Box className="relative bg-prussian-blue border-electric-blue p-4 lg:p-8 border-opacity-100 bg-opacity-100 w-full rounded-[20px] space-y-6">
              {loading || !filteredData ? (
                <div className="grid grid-cols-1 gap-3">
                  <ArticlePlaceholder />
                </div>
              ) : filteredData?.length ? (
                filteredData?.map((item) => (
                  <TaskItem
                    key={item._id}
                    editable={canEdit(item)}
                    promote={canPromote(item)}
                    double={canDouble(item)}
                    onClearStatus={(item: any, status: any) =>
                      console.log('remove status', status, item)
                    }
                    data={item}
                    manager={gestor}
                    managerType={CheckManagerType({
                      user: playerInfo,
                      item,
                    })}
                    playerInfo={playerInfo}
                    canEvaluate={canEvaluate(item)}
                    onPromote={handlePromote}
                    onAccept={handleAccept}
                    onReject={handleReject}
                    onDouble={handleDoubled}
                    onStop={handleStop}
                    onFeedback={() => loadData()}
                    cycle={activeCycleObj}
                    activeEvaluationStep={getActiveEvaluationStep}
                    taskStep={getTaskStep(item)}
                    playerStep={getPlayerStep(item)}
                    onReset={handleReset}
                    reset={canReset(item)}
                  />
                ))
              ) : (
                <div className="flex justify-center items-center flex-col space-y-2 lg:space-y-8 bg-maastricht-blue-3 rounded-[20px] p-8 text-center">
                  <img src={ImgFeedback} className="max-w-[150px]" alt=" " />

                  <p className="text-lg block text-center font-thin text-white leading-loose tracking-tight">
                    <strong className="block text-center text-yellow font-semibold text-xl">
                      Sua equipe
                      {activeCycleObj?.status === 'open' ? ' ainda ' : ' '}não
                      cadastrou Realizações Destaque nesta fase.
                    </strong>
                  </p>
                </div>
              )}
            </Box>
          </div>
        </div>
      </div>

      {/* <ModalConclude callback={callBackConclude} /> */}

      <Outlet />
    </>
  );
}
