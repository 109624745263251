import Nav from 'components/nav';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import { BiLogOut } from 'react-icons/bi';
import { RiCloseFill } from 'react-icons/ri';
import ReactModal from 'react-modal';
import { VERSION } from 'utils/constants';
import Logo from 'assets/img/logo.svg';
import Button from 'components/ui/button';


export function NavMobile({ ...props }) {
  const { user, signOut } = useAuth();

  ReactModal.setAppElement('#modal');

  const [modalIsOpen, setIsOpen] = useState(false);

  // function openModal() {
  // 	setIsOpen(true);
  // }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = "#f00";
  }

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    const onOpenMdal = () => {
      setIsOpen(true);
    };

    document.addEventListener('openNavMobile', onOpenMdal);

    return () => {
      document.removeEventListener('openNavMobile', onOpenMdal);
    };
  }, []);

  return (
    <div className="relative z-30">
      <ReactModal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        contentLabel="Menu Mobile"
        portalClassName=""
        overlayClassName="fixed z-50 top-0 left-0 w-full h-full bg-oxford-blue-3 flex flex-col items-center"
        contentElement={() => (
          <div className="flex flex-col relative w-full h-full overflow-y-auto p-6">
            <div>
              <div className="flex items-center justify-between w-full mb-8">
                <p className="text-xl font-semibold text-electric-blue">Olá, {user?.name?.split(' ')[0]}!</p>

                <div className="flex items-center justify-center w-8 h-8 bg-slate-blue rounded-full text-white text-xl">
                  <RiCloseFill className="block" />
                </div>
              </div>

              <div className="">
                <Nav />

                <button onClick={() => signOut()} className="flex items-center justify-between text-sm uppercase font-light text-[#C2D2FF] p-0 mt-14">
                  Sair do jogo
                </button>

                <div className="text-center text-xs text-white mt-8 text-[#C2D2FF] text-opacity-50">{VERSION}</div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
}
