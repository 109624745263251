import { Editor } from '@tinymce/tinymce-react';
import Button from 'components/ui/button';
import { useRef, useState } from 'react';
import { IoIosClose } from 'react-icons/io';
import { toast } from 'react-toastify';

interface Props {
  sendMessage: (message: string, id: string) => void;
  close: () => void;
  id: string;
}

export default function ModalComment(
  data: Props,
  initialValue: any,
  limit: number
) {
  const { sendMessage, close, id } = data;

  const sizeLimit = 150;
  const [value, setValue] = useState('');
  const [length, setLength] = useState(0);

  function filterMessage() {
    if (typeof value !== 'string' || !value.trim().length)
      return toast(`Você precisa inserir um comentário antes de enviar.`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error',
      });

    const item = value.trim();

    if (item.length > sizeLimit) {
      toast(
        `O seu comentário não pode ter mais de ${sizeLimit} caracteres, você digitou ${item.length}`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          type: 'error',
        }
      );
    } else if (item) {
      sendMessage(item, id);
    }
  }

  const handleInit = (evt: any, editor: any) => {
    //A method for retrieving the character count in the textarea
    setLength(editor.getContent({ format: 'text' }).length);
  };

  const handleUpdate = (value: any, editor: any) => {
    const _value = editor.getContent({ format: 'text' });

    if (_value.length <= sizeLimit) {
      setValue(value);
      setLength(_value.length);
    } else {
      setValue(_value.slice(0, sizeLimit));
      setLength(_value.slice(0, sizeLimit).length);
    }
  };

  const handleBeforeAddUndo = (evt: any, editor: any) => {
    const length = editor.getContent({ format: 'text' }).length;
    // note that this is the opposite test as in handleUpdate
    // because we are determining when to deny adding an undo level
    if (length > sizeLimit) {
      evt.preventDefault();
    }
  };

  return (
    <div className="user-tutorial fixed bg-black bg-opacity-40 top-0 left-0 w-screen h-screen flex justify-center items-center">
      <div className="relative border-2 border-solid border-electric-blue bg-oxford-blue rounded-lg p-4 lg:w-[60vh] w-[90vw] h-[70vh] lg:h-[400px]">
        <Button
          color="primary"
          className="absolute -right-5 -top-5  rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center z-10"
          onClick={() => close()}
        >
          <IoIosClose color="white" size={30} />
        </Button>

        <div className="h-full w-full">
          <p className="block text-xl text-white  leading-none mb-3">
            Escreva o seu comentário
          </p>

          <div className="h-[75%] w-full bg-transparent">
            <Editor
              apiKey="jwzkyeee3alqgkm2a3zyf9e4to1lkzlejkyp9fkcfgnyhvg3"
              init={{
                plugins: 'emoticons wordcount',
                toolbar: 'emoticons',
                toolbar_location: 'bottom',
                menubar: false,
                resize: false,
                min_height: 0,
                height: '90%',
                language: 'pt_BR',
                skin: 'oxide-dark',
                content_css: '/assets/editor/darkmode.css',
              }}
              value={value}
              onInit={handleInit}
              onEditorChange={handleUpdate}
              onBeforeAddUndo={handleBeforeAddUndo}
            />

            <small className="block w-full mt-2 text-right font-light text-xs">
              Caracteres restantes: <strong>{sizeLimit - length}</strong>
            </small>

            <div className="flex justify-center mt-4">
              <button
                disabled={typeof value !== 'string' || !value.trim().length}
                onClick={() => filterMessage()}
                className={`leading-none disabled:opacity-50 ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white rounded py-3 px-4`}
              >
                <p className="font-bold text-sm leading-none uppercase">
                  Enviar
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
