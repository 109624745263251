import { Countdown } from 'components/countdown';
import Button from 'components/ui/button';
import { useAuth } from 'context/AuthContext';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import {
  getTaskCycles,
  getPlayerInfo,
  getTasks,
  getTasksManager,
  getVacationManager,
  getServerTime,
} from 'services/dashboard.service';
import { GetTaskLevelLabel, GetTaskStatusLabel } from 'utils/strings';

import ImgFeedback from 'assets/img/comportamento/bg-missoes-not-found.png';
import { VacationManagerInterface } from 'types/user';
import VacationManagerModal from 'components/vacation-manager-modal';
import { useModal } from 'context/ModalContext';
import { removeStorageItem } from 'utils/storage';

export function TaskCycles() {
  const navigate = useNavigate();
  const { user, cycles, currentCycle } = useAuth();
  const { showModal, closeModal } = useModal();
  const [canRegister, setCanRegister] = useState(true);

  // const [tasks, setTasks] = useState<any>();
  const [currentTaskCycle, setCurrentTaskCycle] = useState<any>();
  const [loadingTasks, setLoadingTasks] = useState(true);
  const [taskCycles, setTaskCycles] = useState<any>();
  const [myTasks, setMyTasks] = useState<any[]>();
  const [vacationManagers, setVacationManagers] =
    useState<VacationManagerInterface[]>();
  const [evaluateTasks, setEvaluateTasks] = useState<any[]>();
  const [playerInfo, setPlayerInfo] = useState<any>();
  const [cycleActive, setCycleActive] = useState<string>();
  const [evaluateCycleActive, setEvaluateCycleActive] = useState<string>();

  const [realDatetime, setRealDatetime] = useState<number>(0);

  useEffect(() => {
    let interv: any = undefined;

    getServerTime().then((timestamp) => {
      setRealDatetime(timestamp);

      interv = window.setInterval(() => {
        setRealDatetime((prevstate) => prevstate + 1000);
      }, 1000);
    });

    return () => clearInterval(interv);
  }, []);

  const activeCycleObj = useMemo(
    () => taskCycles?.find((item: any) => item._id === cycleActive),
    [cycleActive, taskCycles]
  );

  const evaluateActiveCycleObj = useMemo(
    () => taskCycles?.find((item: any) => item._id === evaluateCycleActive),
    [evaluateCycleActive, taskCycles]
  );

  const taskPerCycle: any = useMemo(() => {
    if (!Array.isArray(myTasks) || !taskCycles?.length || !cycleActive)
      return null;

    const cycle = taskCycles.find((cycle: any) => cycle._id === cycleActive);
    const tasks = myTasks.filter(
      (item) =>
        item.created >= cycle.date_start && item.created <= cycle.date_finish
    );

    return tasks?.length ? tasks[0] : null;
  }, [cycleActive, myTasks, taskCycles]);

  // const isCurrentEvaluator = useCallback(
  //   (item: any) => {
  //     if (!user || !item || !playerInfo) return false;

  //     if (item.evaluator?.length) {
  //       const matriculaEvaluator = parseInt(
  //         item.evaluator[item.evaluator.length - 1].replace('F', '')
  //       );
  //       const currentId = parseInt(user.extra.id);

  //       return matriculaEvaluator === currentId;
  //     } else {
  //       return playerInfo.cod_etapa && playerInfo.cod_etapa > 0;
  //     }
  //   },
  //   [playerInfo, user]
  // );

  const evaluateTaskPerCycle: any = useMemo(() => {
    if (
      !Array.isArray(evaluateTasks) ||
      !taskCycles?.length ||
      !evaluateCycleActive
    )
      return null;

    const cycle = taskCycles.find(
      (cycle: any) => cycle._id === evaluateCycleActive
    );

    const tasks = evaluateTasks.filter(
      (item) =>
        item.created >= cycle.date_start && item.created <= cycle.date_finish
    );

    return tasks?.length ? tasks[tasks.length - 1] : null;

    // let tasksFiltered = tasks.filter((item: any) => !!isCurrentEvaluator(item));

    // if (tasksFiltered?.length) {
    //   tasksFiltered = tasksFiltered[tasksFiltered.length - 1];
    // } else {
    //   tasksFiltered = tasks[tasks.length - 1];
    // }

    // console.log(tasksFiltered);

    // return !!tasksFiltered ? tasksFiltered : null;
  }, [evaluateCycleActive, evaluateTasks, taskCycles]);

  const getActiveEvaluationStep: 0 | 2 | 3 | 4 | 4.1 | 5 | null = useMemo(():
    | 0
    | 2
    | 3
    | 4
    | 4.1
    | 5
    | null => {
    if (!evaluateActiveCycleObj || !realDatetime) return null;

    if (
      realDatetime > evaluateActiveCycleObj.date_evaluation_step2_start &&
      realDatetime < evaluateActiveCycleObj.date_evaluation_step2_finish
    ) {
      // Etapa 2
      return 2;
    } else if (
      realDatetime > evaluateActiveCycleObj.date_evaluation_step3_start &&
      realDatetime < evaluateActiveCycleObj.date_evaluation_step3_finish
    ) {
      // Etapa 3
      return 3;
    } else if (
      realDatetime > evaluateActiveCycleObj.date_evaluation_step4_start &&
      realDatetime < evaluateActiveCycleObj.date_evaluation_step4_finish
    ) {
      // Etapa 4
      return 4;
    } else if (
      realDatetime > evaluateActiveCycleObj.date_evaluation_pitStop_start &&
      realDatetime < evaluateActiveCycleObj.date_evaluation_pitStop_finish
    ) {
      // Pit Stop
      return 4.1;
    } else if (
      realDatetime > evaluateActiveCycleObj.date_evaluation_step5_start &&
      realDatetime < evaluateActiveCycleObj.date_evaluation_step5_finish
    ) {
      // Etapa 5
      return 5;
    } else {
      return 0;
    }
  }, [evaluateActiveCycleObj, realDatetime]);

  const htmlCountdownEvaluation = useMemo(() => {
    if (
      !evaluateActiveCycleObj ||
      !getActiveEvaluationStep ||
      !playerInfo ||
      !Array.isArray(evaluateTasks) ||
      !taskCycles?.length ||
      !evaluateCycleActive
    )
      return <></>;

    const getPlayerStep = (task: any) => {
      if (
        !!task.prosseguir_idGestor &&
        task.prosseguir_idGestor === playerInfo._id
      ) {
        return 4.1;
      }

      if (typeof playerInfo.cod_etapa === 'number') {
        if (playerInfo.cod_etapa === 2) {
          return 2;
        }

        if (playerInfo.cod_etapa === 4) {
          return 4;
        }

        if (playerInfo.cod_etapa === 5) {
          return 5;
        }

        if (playerInfo.cod_etapa === 1 || playerInfo.cod_etapa === 3) {
          if (String(task.original_manager) === String(playerInfo._id)) {
            if (task.status === 'rejeitado' || task.status === 'aguardando') {
              return 2;
            } else {
              return 3;
            }
          } else {
            return 3;
          }
        }

        return 0;
      }

      return 0;
    };

    const cycle = taskCycles.find(
      (cycle: any) => cycle._id === evaluateCycleActive
    );

    const tasks: any[] = evaluateTasks.filter(
      (task) =>
        task.created >= cycle.date_start && task.created <= cycle.date_finish
    );

    if (evaluateActiveCycleObj.status === 'open') {
      return (
        <span className="text-white font-thin">
          O período de avaliação desta fase inicia em:{' '}
          <strong className="font-semibold whitespace-nowrap">
            <Countdown
              key="missao"
              deadline={new Date(evaluateActiveCycleObj.date_finish)}
            />
          </strong>
        </span>
      );
    } else if (evaluateActiveCycleObj.status === 'evaluation') {
      if (tasks.length) {
        const userTaskStep: number[] = tasks
          .map((task) =>
            typeof getPlayerStep(task) === 'number'
              ? (getPlayerStep(task) as number)
              : 0
          )
          .sort(() => -1);

        if (userTaskStep.includes(getActiveEvaluationStep)) {
          const date_finish =
            getActiveEvaluationStep === 2
              ? evaluateActiveCycleObj?.date_evaluation_step2_finish
              : getActiveEvaluationStep === 3
              ? evaluateActiveCycleObj?.date_evaluation_step3_finish
              : getActiveEvaluationStep === 4
              ? evaluateActiveCycleObj?.date_evaluation_step4_finish
              : getActiveEvaluationStep === 4.1
              ? evaluateActiveCycleObj?.date_evaluation_pitStop_finish
              : evaluateActiveCycleObj?.date_evaluation_step5_finish;

          return (
            <span className="text-white font-thin">
              O período de apreciação desta etapa termina em:{' '}
              <strong className="font-semibold whitespace-nowrap">
                <Countdown key="missao" deadline={new Date(date_finish)} />
              </strong>
            </span>
          );
        } else {
          const hasAfter = userTaskStep?.find(
            (x: number) => x > (getActiveEvaluationStep as number)
          );

          const date_start =
            hasAfter === 2
              ? evaluateActiveCycleObj?.date_evaluation_step2_start
              : hasAfter === 3
              ? evaluateActiveCycleObj?.date_evaluation_step3_start
              : hasAfter === 4
              ? evaluateActiveCycleObj?.date_evaluation_step4_start
              : hasAfter === 4.1
              ? evaluateActiveCycleObj?.date_evaluation_pitStop_start
              : evaluateActiveCycleObj?.date_evaluation_step5_start;

          if (!!hasAfter) {
            return (
              <span className="text-white font-thin">
                O período de apreciação começa em:{' '}
                <strong className="font-semibold whitespace-nowrap">
                  <Countdown key="missao" deadline={new Date(date_start)} />
                </strong>
              </span>
            );
          } else {
            return <></>;
          }
        }
      } else {
        const codEtapa =
          playerInfo.cod_etapa === 2
            ? 2
            : playerInfo.cod_etapa === 1 || playerInfo.cod_etapa === 3
            ? 3
            : playerInfo.cod_etapa === 4
            ? 4
            : playerInfo.cod_etapa === 5
            ? 5
            : null;

        if (codEtapa) {
          if (getActiveEvaluationStep === codEtapa) {
            const date_finish =
              getActiveEvaluationStep === 2
                ? evaluateActiveCycleObj?.date_evaluation_step2_finish
                : getActiveEvaluationStep === 3
                ? evaluateActiveCycleObj?.date_evaluation_step3_finish
                : getActiveEvaluationStep === 4
                ? evaluateActiveCycleObj?.date_evaluation_step4_finish
                : evaluateActiveCycleObj?.date_evaluation_step5_finish;

            return (
              <span className="text-white font-thin">
                O período de apreciação desta etapa termina em:{' '}
                <strong className="font-semibold whitespace-nowrap">
                  <Countdown key="missao" deadline={new Date(date_finish)} />
                </strong>
              </span>
            );
          } else {
            if (getActiveEvaluationStep < codEtapa) {
              const date_start =
                codEtapa === 2
                  ? evaluateActiveCycleObj?.date_evaluation_step2_start
                  : codEtapa === 3
                  ? evaluateActiveCycleObj?.date_evaluation_step3_start
                  : codEtapa === 4
                  ? evaluateActiveCycleObj?.date_evaluation_step4_start
                  : evaluateActiveCycleObj?.date_evaluation_step5_start;

              return (
                <span className="text-white font-thin">
                  O período de apreciação começa em:{' '}
                  <strong className="font-semibold whitespace-nowrap">
                    <Countdown key="missao" deadline={new Date(date_start)} />
                  </strong>
                </span>
              );
            } else {
              return <></>;
            }
          }
        } else {
          return <></>;
        }
      }
    } else {
      return <></>;
    }
  }, [
    evaluateActiveCycleObj,
    evaluateCycleActive,
    evaluateTasks,
    getActiveEvaluationStep,
    playerInfo,
    taskCycles,
  ]);

  useEffect(() => {
    async function getUserInfo() {
      try {
        const _playerInfo = await getPlayerInfo(user?._id);

        if (_playerInfo) {
          setPlayerInfo(_playerInfo);

          const playerCadMissao =
            _playerInfo.cad_missao && _playerInfo.cad_missao > 0;

          setCanRegister(playerCadMissao);

          const taskCycleResults = await getTaskCycles();
          setTaskCycles(taskCycleResults);

          // Pode cadastrar
          if (playerCadMissao) {
            const data = await getTasks(user?._id);

            const my_tasks = data.filter(
              (item: any) => item.owner === user?._id
            );

            setMyTasks(my_tasks);

            const currentCycle = taskCycleResults.find(
              (item: any) => item.status === 'open'
            );

            setCycleActive(currentCycle?._id || taskCycleResults[0]._id);
          }

          // Gestor
          if (_playerInfo.cod_etapa && _playerInfo.cod_etapa > 0) {
            const data = await getTasksManager(user?.extra.id);
            setEvaluateTasks(data);

            const _vacationManager = await getVacationManager(user?._id);
            setVacationManagers(_vacationManager ? _vacationManager : []);

            const currentCycle = taskCycleResults.find(
              (item: any) => item.status === 'evaluation'
            );

            setEvaluateCycleActive(
              currentCycle?._id || taskCycleResults[0]._id
            );
          }
        }

        setLoadingTasks(false);
      } catch (e) {
        console.log(e);
      }
    }

    if (user) {
      getUserInfo();
    }
  }, [user]);

  useEffect(() => {
    removeStorageItem('manager_lateralidade');
  }, []);

  return (
    <>
      <div className="flex flex-col">
        {!loadingTasks ? (
          <>
            <div className="space-y-6 flex flex-col w-full">
              {!!taskPerCycle || !!evaluateTaskPerCycle ? (
                <p className="text-white text-xs lg:text-sm block font-thin">
                  Visualize suas Realizações Destaque e acompanhe os status de
                  validação
                </p>
              ) : null}

              {/* Cadastro */}
              {!!canRegister ? (
                <div>
                  {!!taskPerCycle ? (
                    <div>
                      <p className="font-semibold text-base lg:text-lg leading-none block mb-3 lg:mb-4 text-electric-blue">
                        {!!playerInfo?.cod_etapa && playerInfo?.cod_etapa > 0
                          ? 'Sua última'
                          : 'Última'}{' '}
                        Realização Destaque cadastrada
                      </p>

                      <div className="flex items-center justify-between flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-8">
                        <div className="flex-1 bg-maastricht-blue rounded-lg p-4 lg:p-6 text-sm space-y-3">
                          <p className="font-thin">
                            <strong className="font-normal">
                              Nome da ação:
                            </strong>{' '}
                            {taskPerCycle.title}
                          </p>
                          <p className="font-thin">
                            <strong className="font-normal">
                              Status da entrega:
                            </strong>{' '}
                            {GetTaskLevelLabel(taskPerCycle.status)}
                          </p>
                        </div>

                        {activeCycleObj?.status === 'open' ? (
                          <span className="text-white block lg:hidden text-xs font-thin pb-3">
                            A fase será finalizado em:{' '}
                            <strong className="font-semibold">
                              <Countdown
                                key="missao"
                                deadline={new Date(activeCycleObj?.date_finish)}
                              />
                            </strong>
                          </span>
                        ) : null}

                        <button
                          className={`text-alice-blue bg-slate-blue text-sm flex items-center justify-center font-normal lg:font-semibold uppercase ring-2 ring-slate-blue rounded w-auto px-3 lg:px-4 h-10`}
                          onClick={() => navigate('/realizacao-destaque')}
                        >
                          Acompanhar Realizações Destaque
                        </button>
                      </div>

                      {activeCycleObj?.status === 'open' ? (
                        <span className="text-white hidden lg:block mt-3 text-sm font-thin">
                          A fase será finalizada em:{' '}
                          <strong className="font-semibold">
                            <Countdown
                              key="missao"
                              deadline={new Date(activeCycleObj?.date_finish)}
                            />
                          </strong>
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <div className="flex justify-center items-center flex-col space-y-4 lg:space-y-6 bg-maastricht-blue-3 rounded-2xl lg:rounded-[20px] p-6 text-center w-full">
                      <img
                        src={ImgFeedback}
                        className="max-w-[150px]"
                        alt=" "
                      />

                      {activeCycleObj?.status === 'open' ? (
                        <>
                          <p className="text-white text-sm block mb-6 font-thin text-center tracking-tight">
                            <strong className="font-normal text-base lg:text-lg block mb-4 text-yellow">
                              Você ainda não possui uma Realização Destaque
                              nesta fase.
                            </strong>
                            Cadastre sua Realização Destaque, seja reconhecido
                            pelos seus superiores e ganhe recompensas! <br />
                            Lembre-se: você só pode cadastrar uma realização por
                            fase.
                          </p>

                          <p>
                            <b>Atenção</b>: só devem ser cadastradas realizações{' '}
                            <b>concluídas</b>.
                          </p>

                          <span className="text-white hidden lg:block mt-3 text-sm font-thin">
                            A fase será finalizada em:{' '}
                            <strong className="font-semibold">
                              <Countdown
                                key="missao"
                                deadline={new Date(activeCycleObj?.date_finish)}
                              />
                            </strong>
                          </span>

                          <div className="flex items-center justify-center space-x-6">
                            <button
                              className={`text-white text-sm flex items-center justify-center font-normal lg:font-semibold uppercase ring-2 ring-slate-blue rounded w-auto px-3 lg:px-4 h-10`}
                              onClick={() => navigate('/realizacao-destaque')}
                            >
                              Acompanhar Realizações Destaque
                            </button>

                            <button
                              className="text-alice-blue bg-slate-blue text-sm flex items-center justify-center font-normal lg:font-semibold uppercase ring-2 ring-slate-blue rounded w-auto px-3 lg:px-4 h-10"
                              onClick={() =>
                                navigate('/realizacao-destaque/cadastrar')
                              }
                            >
                              Cadastrar Realização Destaque
                            </button>
                          </div>
                        </>
                      ) : (
                        <>
                          <p className="text-white text-sm block mb-6 font-thin text-center tracking-tight">
                            <strong className="font-normal text-base lg:text-lg block mb-4 text-yellow">
                              Você não possui Realização Destaque cadastrada
                              nesta fase.
                            </strong>
                          </p>

                          <button
                            className={`text-alice-blue bg-slate-blue text-sm flex items-center justify-center font-normal lg:font-semibold uppercase ring-2 ring-slate-blue rounded w-auto px-3 lg:px-4 h-10`}
                            onClick={() => navigate('/realizacao-destaque')}
                          >
                            Acompanhar Realizações Destaque
                          </button>
                        </>
                      )}
                    </div>
                  )}
                </div>
              ) : null}

              {/* Gestor */}
              {!!playerInfo?.cod_etapa && playerInfo?.cod_etapa > 0 ? (
                <div>
                  {!!evaluateTaskPerCycle ? (
                    <div>
                      <p className="font-semibold text-base lg:text-lg leading-none block mb-3 lg:mb-4 text-electric-blue">
                        Última Realização Destaque cadastrada
                        {!!canRegister ? ' para avaliação' : ''}
                      </p>

                      <div className="flex items-center justify-between flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-8">
                        <div className="flex-1 bg-maastricht-blue rounded-lg p-4 lg:p-6 text-sm space-y-3">
                          <p className="font-thin">
                            <strong className="font-normal">
                              Nome da ação:
                            </strong>{' '}
                            {evaluateTaskPerCycle.title}
                          </p>
                          <p className="font-thin">
                            <strong className="font-normal">
                              Status da entrega:
                            </strong>{' '}
                            {GetTaskLevelLabel(evaluateTaskPerCycle.status)}
                          </p>
                        </div>

                        <span className="block lg:hidden text-xs pb-3">
                          {htmlCountdownEvaluation}
                        </span>

                        <div className="flex items-center justify-center flex-col space-y-5">
                          <button
                            className={`text-alice-blue bg-slate-blue text-sm flex items-center justify-center font-normal lg:font-semibold uppercase ring-2 ring-slate-blue rounded w-auto px-3 lg:px-4 h-10`}
                            onClick={() => navigate('/validar-destaque')}
                          >
                            Avaliar Realizações Destaque
                          </button>

                          {!!vacationManagers?.length &&
                            evaluateActiveCycleObj?.status === 'evaluation' && (
                              <button
                                className={`text-alice-blue bg-slate-blue text-sm flex items-center justify-center font-normal lg:font-semibold uppercase ring-2 ring-slate-blue rounded w-auto px-3 lg:px-4 h-10`}
                                onClick={() => showModal(`vacation-manager`)}
                              >
                                Avaliar Realizações Destaque lateralidade
                              </button>
                            )}
                        </div>
                      </div>

                      <span className="hidden lg:block mt-3 text-sm">
                        {htmlCountdownEvaluation}
                      </span>
                    </div>
                  ) : (
                    <div className="flex justify-center items-center flex-col space-y-4 lg:space-y-6 bg-maastricht-blue-3 rounded-2xl lg:rounded-[20px] p-6 text-center w-full">
                      <img
                        src={ImgFeedback}
                        className="max-w-[150px]"
                        alt=" "
                      />

                      {evaluateActiveCycleObj?.status === 'open' ? (
                        <>
                          <p className="text-white text-sm block mb-6 font-thin text-center tracking-tight">
                            <strong className="font-normal text-base lg:text-lg block mb-4 text-yellow">
                              Sua equipe ainda não cadastrou Realizações
                              Destaque nesta fase.
                            </strong>
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="text-white text-sm block mb-6 font-thin text-center tracking-tight">
                            <strong className="font-normal text-base lg:text-lg block mb-4 text-yellow">
                              Sua equipe não cadastrou Realizações Destaque
                              nesta fase.
                            </strong>
                          </p>
                        </>
                      )}

                      <div className="flex items-center justify-center space-x-5">
                        <button
                          className={`text-alice-blue bg-slate-blue text-sm flex items-center justify-center font-normal lg:font-semibold uppercase ring-2 ring-slate-blue rounded w-auto px-3 lg:px-4 h-10`}
                          onClick={() => navigate('/validar-destaque')}
                        >
                          Avaliar Realizações Destaque
                        </button>

                        {!!vacationManagers?.length &&
                          evaluateActiveCycleObj?.status === 'evaluation' && (
                            <button
                              className={`text-alice-blue bg-slate-blue text-sm flex items-center justify-center font-normal lg:font-semibold uppercase ring-2 ring-slate-blue rounded w-auto px-3 lg:px-4 h-10`}
                              onClick={() => showModal(`vacation-manager`)}
                            >
                              Avaliar Realizações Destaque lateralidade
                            </button>
                          )}
                      </div>
                    </div>
                  )}
                </div>
              ) : null}

              {/* {canRegister ? (
                    <></>
                  ) : (
                    <>
                      <p className="text-white text-sm block mb-6 font-thin text-center tracking-tight">
                        <strong className="font-semibold text-base lg:text-lg block mb-4 text-yellow">
                          Sua equipe ainda não cadastrou Realizações Destaque nesta fase
                        </strong>
                      </p>
                    </>
                  )} */}

              {/* <div className="flex justify-center items-center flex-col space-y-2 lg:space-y-8 bg-maastricht-blue-3 rounded-[20px] p-8 text-center mt-8 w-full"> */}
              {/* {taskCycles?.map((item: any) => {
            // const buttonColor =
            //   item.status === "open" ? "secondary" : "outline";
            return (
              <div className="flex items-center space-x-5" key={item._id}>
                <div className="bg-maastricht-blue rounded-md px-5 py-3 text-sm">
                  <p className="font-thin ">
                    <b className="font-normal">Nome da ação:</b> Protótipo para
                    o JogaJuntoBB
                  </p>
                  <p className="font-thin">
                    <b className="font-normal">Status da entrega:</b> Aguardando
                    validação
                  </p>
                </div>

                <Button
                  color="primary"
                  className="uppercase py-2 text-sm"
                  onClick={() => navigate("/realizacao-destaque")}
                >
                  {item.title} - {GetTaskStatusLabel(item.status)}
                </Button>
              </div>
            );
          })} */}

              {/* {!canRegister && (
            <Button color="outline" className="uppercase py-2 bg-yellow" onClick={() => navigate('/realizacao-destaque')}>
              1º Fase - Em validação
            </Button>
          )}

          {!canRegister && !tasks && (
            <Button className="uppercase py-2" onClick={() => navigate('/realizacao-destaque')}>
              2º Fase - cadastrar
            </Button>
          )}

          {!canRegister && !!tasks && (
            <Button
              onClick={() => navigate(`/realizacao-destaque`)}
              color="clear"
              className="uppercase py-2 bg-blue-light text-blue-dark"
            >
              <div className="flex flex-row items-center space-x-2">
                <span>Ver suas entregas</span>
                <BiEdit />
              </div>
            </Button>
          )} */}

              {/* {!!tasks && tasks.status === 'ok' && (
        <div className="py-2 px-3 rounded-full bg-green-700 ring-2 ring-white text-white">
          <div className="">
            <span>Entrega Reconhecida: </span> +{getTaskPoints(tasks)} pontos
          </div>
        </div>
      )} */}

              {/* {canRegister && (
            <Button
              color="clear"
              className="uppercase py-2 bg-yellow text-blue-dark"
              onClick={() => navigate("/validar-destaque")}
            >
              Avaliar Entregas da Equipe
            </Button>
          )} */}
            </div>
          </>
        ) : (
          <div className="text-center text-lg p-2 px-4 border rounded-full mt-3">
            Carregando informações...
          </div>
        )}
      </div>

      {!!vacationManagers?.length &&
        evaluateActiveCycleObj?.status === 'evaluation' && (
          <VacationManagerModal managers={vacationManagers} />
        )}
    </>
  );
}
