import {
  Uau,
  MandouBem,
  Valeu,
  Colaboracao,
  Inovacao,
  FocoNoCliente,
  Resolutividade,
} from 'components/icons';
import { useEffect, useMemo, useState } from 'react';
import { BiCoinStack, BiGift, BiTrophy, BiUpvote } from 'react-icons/bi';
import { FaThumbsUp } from 'react-icons/fa';
import { RiThumbUpLine } from 'react-icons/ri';

const iconSize = 20;
const Coins: any = {
  uau: <Uau size={iconSize} />,
  mandou: <MandouBem size={iconSize} />,
  valeu: <Valeu size={iconSize} />,
};

const CoinLabel: any = {
  uau: '#UAU',
  mandou: '#MANDOUBEM',
  valeu: '#VALEU',
};

const Behaviors: any = {
  colaboracao: <Colaboracao size={iconSize} />,
  inovacao: <Inovacao size={iconSize} />,
  foco: <FocoNoCliente size={iconSize} />,
  resolutividade: <Resolutividade size={iconSize} />,
};

const BehaviorLabel: any = {
  colaboracao: 'Colaboração',
  inovacao: 'Inovação',
  foco: 'Foco no Cliente',
  resolutividade: 'Protagonismo',
};

interface PointItemProps {
  data: any;
}

export function PointReviewItem(props: PointItemProps) {
  const { data } = props;

  const [origin, setOrigin] = useState<any>();

  useEffect(() => {
    let defaultPoint = {
      type: 'default',
      title: 'Crédito de Pontos',
      total: data.total,
      time: data.time,
    };

    try {
      if (data?.log && data?.log.length) {
        const log = data?.log[0];
        setOrigin({
          type: data.extra.type,
          extra: data.extra,
          log,
          total: data.total,
          time: data.time,
        });
      } else if (data.type) {
        setOrigin(data);
      } else {
        setOrigin(defaultPoint);
      }
    } catch (e) {
      setOrigin(defaultPoint);
    }
  }, [data]);

  return useMemo(() => {
    function renderSeloBB() {
      try {
        return (
          <div className="flex flex-col md:flex-row md:items-center md:justify-between bg-black bg-opacity-60 p-3 md:p-5 space-y-5 md:space-y-0 md:space-x-3 rounded-xl">
            <div className="flex flex-row space-x-3 items-center">
              <div>
                <BiTrophy size={24} className="text-yellow" />
              </div>
              <div className="flex-1">
                Recebeu o Selo BB em{' '}
                <b className="text-yellow">{origin.log.attributes.selo}</b>
              </div>
            </div>
            <div className="flex flex-row space-x-3 items-center justify-center">
              <div className="flex flex-row items-center space-x-2 px-2 text-blue-dark py-1 bg-yellow rounded-full">
                <b>+{origin.total} pontos</b>
              </div>
            </div>
          </div>
        );
      } catch (e) {
        return null;
      }
    }

    function renderMoedasBonus() {
      try {
        return (
          <div className="flex flex-col md:flex-row md:items-center md:justify-between bg-black bg-opacity-60 p-3 md:p-5 space-y-5 md:space-y-0 md:space-x-3 rounded-xl">
            <div className="flex flex-row space-x-3 items-center">
              <div>
                <BiGift size={24} className="text-purple" />
              </div>
              <div className="flex-1">{origin.log.attributes.campanha}</div>
            </div>
            <div className="flex flex-row space-x-3 items-center justify-center">
              <div className="flex flex-row items-center space-x-2 px-2 bg-blue-dark py-1 text-yellow rounded-full">
                {Behaviors[origin.extra.behavior]}{' '}
                <span className="text-xs">
                  {BehaviorLabel[origin.extra.behavior]}
                </span>
              </div>
              <div className="flex flex-row items-center space-x-2 px-2 text-blue-dark py-1 bg-yellow rounded-full">
                <b>+1</b> {Coins[origin.extra.coin]}{' '}
                <span className="text-xs">{CoinLabel[origin.extra.coin]}</span>
              </div>
            </div>
          </div>
        );
      } catch (e) {
        return null;
      }
    }

    function renderValoracaoGestor() {
      try {
        return (
          <div className="flex flex-col md:flex-row md:items-center md:justify-between bg-black bg-opacity-60 p-3 md:p-5 space-y-5 md:space-y-0 md:space-x-3 rounded-xl">
            <div className="flex flex-row space-x-3 items-center">
              <div>
                <FaThumbsUp size={24} className="text-yellow" />
              </div>
              <div className="flex-1">
                Validação da Realização Destaque -{' '}
                <b className="text-yellow">Fase {origin.ciclo}</b>
              </div>
            </div>
            {/* <div className="flex flex-row space-x-3 items-center justify-center">
              <div className="flex flex-row items-center space-x-2 px-2 text-blue-dark py-1 bg-yellow rounded-full">
                <b>+{origin.total} pontos</b>
              </div>
            </div> */}
          </div>
        );
      } catch (e) {
        return null;
      }
    }

    function renderEntregaEquipe() {
      try {
        return (
          <div className="flex flex-col bg-maastricht-blue-3 p-3 md:p-5 space-y-5 rounded-xl">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between space-y-5 md:space-y-0">
              <div className="flex-1 flex flex-row space-x-3 items-center">
                {/* <div>
                  {origin.status === 'aprovado' ? <RiThumbUpLine size={24} className="text-yellow" /> : null}
                  {origin.status === 'dobrado' ? <BiCoinStack size={24} className="text-yellow" /> : null}
                  {origin.status === 'promovido' ? <BiUpvote size={24} className="text-yellow" /> : null}
                  {origin.status === 'selo' ? <BiTrophy size={24} className="text-yellow" /> : null}
                </div> */}
                <div className="flex-1 text-lg leading-none">
                  <p className="font-thin text-sm mb-4">Nome da ação</p>
                  <b className="text-sm">{origin.titulo}</b>
                </div>

                <div className="flex-1 text-lg leading-none">
                  <p className="font-thin text-sm mb-4">Status da entrega</p>
                  <b className="text-sm">{origin.status}</b>
                </div>

                <div className="w-1/5 text-lg leading-none">
                  <p className="font-thin text-sm mb-4">% de atuação</p>
                  <b className="text-sm bg-oxford-blue px-5 py-2 text-yellow rounded-full">
                    {origin.participacao}
                  </b>
                </div>
              </div>

              {/* <div className="flex flex-row space-x-3 items-center justify-center">
                <div className="flex flex-row items-center space-x-2 px-2 text-white py-1 bg-purple rounded-full">
                  <b>Participação: {origin.participacao}%</b>
                </div>

                <div className="flex flex-row items-center space-x-2 px-2 text-blue-dark py-1 bg-yellow rounded-full">
                <b>+{origin.total} pontos</b>
              </div>
              </div> */}
            </div>
            {/* <div className="bg-blue-dark flex-1 p-3 rounded-xl text-gray-300">
              <div dangerouslySetInnerHTML={{ __html: origin.descricao.replace(/\n/g, '<br />') }}></div>
            </div> */}
          </div>
        );
      } catch (e) {
        return null;
      }
    }

    function renderDestaqueCultura() {
      return <div>Destaque Cultura</div>;
    }

    function renderDefault() {
      return (
        <div className="flex flex-col md:flex-row md:items-center md:justify-between bg-black bg-opacity-60 p-3 md:p-5 space-y-5 md:space-y-0 md:space-x-3 rounded-xl">
          <div className="flex flex-row space-x-3 items-center">
            <div>
              <BiTrophy size={24} className="text-purple" />
            </div>
            <div className="flex-1">{origin.log.attributes.campanha}</div>
          </div>
          <div className="flex flex-row space-x-3 items-center justify-center">
            <div className="flex flex-row items-center space-x-2 px-2 text-blue-dark py-1 bg-yellow rounded-full">
              <b>+{origin.total} pontos</b>
            </div>
          </div>
        </div>
      );
    }

    if (!origin) return null;

    switch (origin.type) {
      case 'moedas_bonus':
        return renderMoedasBonus();
      case 'selobb':
        return renderSeloBB();
      case 'destaque_cultura':
        return renderDestaqueCultura();
      case 'entrega_equipe':
        return renderEntregaEquipe();
      case 'entrega_gestor':
        return renderValoracaoGestor();
      default:
        return renderDefault();
    }
  }, [origin]);
}
