import Tippy from "@tippyjs/react";
import {
  Colaboracao,
  Inovacao,
  FocoNoCliente,
  Resolutividade,
  MandouBem,
  Uau,
  Valeu,
} from "components/icons";
import { UserAvatar } from "components/user-avatar";
import { format } from "date-fns";
import { BiInfoCircle, BiMessage } from "react-icons/bi";
import { BehaviorLabel } from "utils/strings";

const iconSize = 20;

const Coins: any = {
  uau: <Uau yellow size={iconSize} />,
  mandou: <MandouBem yellow size={iconSize} />,
  valeu: <Valeu yellow size={iconSize} />,
};

const CoinLabel: any = {
  uau: "#UAU",
  mandou: "#MANDOUBEM",
  valeu: "#VALEU",
};

const Behaviors: any = {
  colaboracao: <Colaboracao size={iconSize} />,
  inovacao: <Inovacao size={iconSize} />,
  foco: <FocoNoCliente  size={iconSize} />,
  resolutividade: <Resolutividade size={iconSize} />,
};

export function RewardReviewItem(props: any) {
  const { data } = props;

  if (!data) return null;

  return (
    <div
      className="bg-maastricht-blue-3 flex flex-col rounded-2xl text-gray-300 space-y-2 py-3 px-6"
      key={data?._id}
    >
      <div className="relative flex flex-col lg:flex-col items-start lg:items-start py-2 px-2">
        {/* <div>
          <div className="text-gray-400 mr-3 text-center text-xs leading-none">
            <span>{format(new Date(data.time), 'dd/MM/yy')}</span>
            <br className="hidden lg:block" /> <span>às {format(new Date(data.time), 'HH:mm')}</span>
          </div>
        </div> */}
        <div className="flex-1 flex flex-row items-start space-x-2 leading-tight w-full">
          <div className="flex flex-1 w-full items-start">
            <div className="mr-2">
              <UserAvatar data={data.sender_avatar} />
            </div>
            <Tippy content={data.sender_name}>
              <div className="flex space-x-2">
                <p className="flex flex-row text-white font-bold hover:cursor-pointer hover:underline">
                  {data.sender_name.split(" ")[0]}{" "}
                  <span className="font-thin ml-1 block">({data.sender})</span>
                  <BiInfoCircle />
                </p>
                <span>enviou:</span>
              </div>
            </Tippy>
          </div>

          <div className="text-gray-400 text-xs leading-none">
            <span>{format(new Date(data.time), "dd MMM yyyy | HH:mm")}</span>
          </div>
        </div>
        
        <div className="flex flex-row flex-wrap items-center w-full lg:w-auto lg:space-x-2 ml-14 -mt-4">
          {/* <div className="w-1/2 lg:w-auto pr-1 lg:pr-0">
            <div className="flex flex-row items-center space-x-2 px-2 text-yellow py-1 bg-oxford-blue rounded-full">
              {Coins[data.coin]}{" "}
              <span className="text-xs text-yellow">{CoinLabel[data.coin]}</span>
            </div>
          </div> */}
          <div className="w-1/2 lg:w-auto pl-1 lg:pl-0">
            <div className="flex flex-row items-center space-x-2 px-2 bg-oxford-blue py-1 text-yellow rounded-full">
              {Behaviors[data.behavior]}{" "}
              <span className="text-xs">{BehaviorLabel[data.behavior]}</span>
            </div>
          </div>
          {/* <div onClick={()=>handleDetail(item)} className="top-0 right-0 absolute lg:relative flex flex-row items-center rounded-xl py-1 hover:bg-gray-600 cursor-pointer">
    <RiMore2Fill size={22} />
  </div> */}
        </div>
      </div>

      <hr className="border-prussian-blue" />

      {!!data.message && (
        <div className=" p-2 flex-1 flex-col">
          <p className="text-electric-blue">Mensagem recebida:</p>
          <div dangerouslySetInnerHTML={{ __html: data.message }}></div>
        </div>
      )}
    </div>
  );
}
