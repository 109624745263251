import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { IoIosClose } from 'react-icons/io';

import Button from 'components/ui/button';
import { useModal } from 'context/ModalContext';
import Box from 'components/ui/box';

import FeedbackImg from 'assets/img/comportamento/bg-feedback-moedas.png';

interface Props {
  callback: (obj: { id: string; message?: string }) => void;
  id: string;
  status: string | undefined;
  data?: any;
}

export default function ModalAccept(props: Props) {
  ReactModal.setAppElement('#modal');

  const modalID = 'validation-accept';

  const { openModal, closeModal } = useModal();
  const { callback, status, id, data } = props;

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    if (
      typeof id === 'string' &&
      typeof openModal === 'string' &&
      openModal === `${modalID}-${id}`
    ) {
      setSuccess(false);
      setIsOpen(true);
    } else {
      setSuccess(false);
      setIsOpen(false);
    }
  }, [id, openModal]);

  if (!modalIsOpen) return <></>;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Avaliação"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center w-screen h-screen p-6 pt-[10vh] overflow-y-auto"
        contentElement={() => (
          <Box className={`w-[90%] lg:w-auto lg:max-w-[600px]`}>
            <Button
              color="primary"
              className="absolute -right-5 -top-5 rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
              onClick={() => {
                closeModal();
                if (!!success) callback({ id: 'open_feedback' });
              }}
            >
              <IoIosClose color="white" size={40} />
            </Button>

            {!!success ? (
              <>
                <div className="w-full lg:w-[260px] flex flex-col items-center justify-center space-y-8">
                  <div className="flex items-center justify-center">
                    <img
                      src={FeedbackImg}
                      alt="Enviado!"
                      className="w-[173px] block"
                    />
                  </div>
                  <h4 className="block text-2xl font-semibold text-yellow text-center">
                    Mandou bem!
                  </h4>
                  <p className="text-sm font-white font-thin text-center block">
                    Sua validação foi realizada com sucesso.
                  </p>
                  <button
                    className={`text-white bg-slate-blue text-sm inline-flex items-center justify-center font-semibold uppercase rounded py-3 px-4 leading-none`}
                    onClick={() => {
                      closeModal();
                      callback({ id: 'open_feedback' });
                    }}
                  >
                    Fechar
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="w-full lg:w-[536px] flex flex-col items-center justify-center space-y-8">
                  <h4 className="block text-center text-yellow text-lg font-semibold leading-none">
                    Validar Realização Destaque
                  </h4>
                  <p className="text-alice-blue text-base font-thin block">
                    Você pode validar a entrega se ela estiver cadastrada de
                    forma adequada. Deseja confirmar essa ação?
                  </p>
                  <button
                    className={`text-white bg-slate-blue text-sm inline-flex items-center justify-center font-semibold uppercase rounded py-3 px-4 leading-none`}
                    onClick={() => {
                      callback({ id: 'accept' });
                      setSuccess(true);
                    }}
                  >
                    CONFIRMAR
                  </button>
                </div>
              </>
            )}
          </Box>
        )}
      />
    </>
  );
}
