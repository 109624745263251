import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from 'components/ui/box';
import ReactModal from 'react-modal';
import { VacationManagerInterface } from 'types/user';
import { RiCloseLine } from 'react-icons/ri';
import { IoIosClose } from 'react-icons/io';

import { UserAvatar } from 'components/user-avatar';
import Button from 'components/ui/button';
import { useModal } from 'context/ModalContext';

import './style.scss';
import { setStorageItem } from 'utils/storage';

type Props = {
  managers: VacationManagerInterface[];
};

export default function VacationManagerModal({ managers }: Props) {
  const modalID = 'vacation-manager';
  ReactModal.setAppElement('#modal');

  const navigate = useNavigate();
  const { openModal, closeModal } = useModal();

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [target, setTarget] = useState<VacationManagerInterface | undefined>();

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  const handleSelect = () => {
    if (target?.extra?.id) {
      setStorageItem('manager_lateralidade', target._id);
      navigate('/validar-destaque');
    }
  };

  useEffect(() => {
    if (
      typeof openModal === 'string' &&
      openModal === modalID &&
      managers?.length
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [managers, openModal]);

  useEffect(() => {
    if (modalIsOpen) {
      if (!target && managers.length === 1) {
        setTarget(managers[0]);
      }
    }
  }, [managers, modalIsOpen, target]);

  return (
    <ReactModal
      key={'modal-lateralidade'}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={handleCloseModal}
      contentLabel="Modal avaliar Realizações Destaque lateralidade"
      portalClassName="relative z-50"
      overlayClassName="fixed z-100 top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center w-screen h-screen p-6 pt-[10vh] overflow-y-auto"
      contentElement={() => (
        <>
          <Box className="w-[90%] lg:max-w-screen-md">
            <Button
              color="primary"
              className="absolute -right-5 -top-5 rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
              onClick={() => {
                closeModal();
              }}
            >
              <IoIosClose color="white" size={40} />
            </Button>

            <div className="mx-auto relative lg:container overflow-y-auto max-h-[85vh]">
              <div className="block mb-8 mx-8">
                <h1 className="text-xl mt-4 lg:mt-0 lg:text-lg block text-center text-yellow">
                  Avaliar Realizações Destaque lateralidade
                </h1>
              </div>

              <div className="space-y-8">
                <div>
                  <div className="space-x-4 flex flex-row items-center justify-between">
                    {!!target && (
                      <div className="relative flex-1">
                        <span className="text-alice-blue mb-3 block whitespace-nowrap text-base font-thin">
                          Você estará avaliando por:
                        </span>
                        <div className="flex-1 w-full flex flex-row items-center justify-between bg-rich-black bg-opacity-80 py-4 lg:px-8 rounded-3xl">
                          <div className="w-[44px] h-[44px] ml-2">
                            <UserAvatar
                              data={{
                                image: target.image,
                                ...target.extra,
                              }}
                            />
                          </div>
                          <p className="flex-1 pl-3 pr-2">
                            <span className="text-xs font-thin">
                              <strong className="text-base font-semibold">
                                {target.name}
                              </strong>{' '}
                              ({target._id})
                            </span>
                            <span className="block text-xs font-thin">
                              <span className="text-electric-blue uppercase">
                                {target.extra?.dependencia}
                              </span>{' '}
                              - {target.extra?.funcao}
                            </span>
                          </p>

                          {managers.length > 1 ? (
                            <RiCloseLine
                              size={44}
                              className="cursor-pointer hover:ring-2 p-2 rounded-full text-slate-blue"
                              onClick={() => setTarget(undefined)}
                            />
                          ) : null}
                        </div>
                      </div>
                    )}

                    {!target && managers.length > 0 && (
                      <div className="relative flex-1">
                        <span className="text-alice-blue mb-3 block whitespace-nowrap text-base font-thin">
                          Selecione por quem você deseja avaliar:
                        </span>

                        <div className="flex-1 bg-rich-black bg-opacity-80 py-4 px-8 rounded-3xl w-full rounded-3xl">
                          {managers.map((item, index) => (
                            <div
                              key={item._id}
                              onClick={() => setTarget(item)}
                              className={`${
                                index + 1 < managers.length
                                  ? 'border-b border-prussian-blue pb-4 mb-4'
                                  : ''
                              } cursor-pointer hover:opacity-75 w-full flex flex-row items-center justify-between`}
                            >
                              <div className="w-[44px] h-[44px] ml-2">
                                <UserAvatar
                                  data={{
                                    image: item.image,
                                    ...item.extra,
                                  }}
                                />
                              </div>
                              <p className="flex-1 pl-3 pr-2">
                                <span className="text-xs font-thin">
                                  <strong className="text-base font-semibold ">
                                    {item.name}
                                  </strong>{' '}
                                  ({item._id})
                                </span>
                                <span className="block text-xs font-thin">
                                  <span className="text-electric-blue uppercase">
                                    {item.extra?.dependencia}
                                  </span>{' '}
                                  - {item.extra?.funcao}
                                </span>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                {!!target && !!target.extra?.id && (
                  <div className="flex lg:block justify-center">
                    <div className="p-2 px-45 flex flex-row space-x-8 items-center justify-center">
                      <button
                        onClick={() => handleSelect()}
                        className={`leading-none disabled:opacity-50 ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white rounded py-3 px-4`}
                      >
                        <p className="font-bold text-sm leading-none uppercase">
                          Continuar
                        </p>
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Box>
        </>
      )}
    />
  );
}
