import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import Button from 'components/ui/button';
import { ModalContext } from 'context/ModalContext';

export function PontosHelpModal(props: any) {
  // const modalID = "achievement";

  const modalID = 'help-pontos';

  const [modalIsOpen, setIsOpen] = useState(false);

  const { openModal, closeModal } = useContext(ModalContext);

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    setIsOpen(openModal === modalID);
  }, [openModal]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="max-w-[800px] bg-blue-dark text-white rounded-xl p-10 flex flex-col items-center space-y-2 flex-none mx-5">
              <div className="text-center mb-3">
                <h1 className="text-yellow text-2xl mb-2">Meus Pontos</h1>
                <p>
                  Este é o saldo total de pontos acumulados como resultado do
                  reconhecimento de seus colegas, pontos das entregas feitas na
                  Realização Destaque, dentre outras atividades.
                </p>
              </div>
              <Button onClick={() => closeModal()}>FECHAR</Button>
            </div>
          </div>
        )}
      />
    </>
  );
}
