import Button from 'components/ui/button';
import { useNavigate } from 'react-router-dom';

import Logo from 'assets/img/logo.svg';

export function NotFound() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col space-y-5 justify-center items-center h-screen">
      <div className="w-72">
        <img src={Logo} alt="#JogaJunto BB" className="relative" />
      </div>

      <h1 className="text-2xl">Página não encontrada.</h1>

      <div className='pt-5'>
        <Button
          className="uppercase py-2 px-5 rounded w-auto"
          onClick={() => navigate('/')}
        >
          Voltar para Página inicial
        </Button>
      </div>
    </div>
  );
}
