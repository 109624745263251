import { useEffect, useState } from 'react';

// Msal imports
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import {
  InteractionStatus,
  InteractionType,
  InteractionRequiredAuthError,
  AccountInfo,
} from '@azure/msal-browser';
import { loginRequest } from '../authConfig';
import { GraphData, ProfileData } from './graph';
import { ErrorComponent } from './ErrorComponent';
import { Loading } from './Loading';
import { callMsGraph } from '../azure-api';

type AzureProfileProps = {
  onAuthenticate: (response: any) => void;
};
const AzureProfileContent = (props: AzureProfileProps) => {
  const { instance, inProgress } = useMsal();
  const [graphData, setGraphData] = useState<null | GraphData>(null);

  useEffect(() => {
    if (!graphData && inProgress === InteractionStatus.None) {
      callMsGraph()
        .then((response) => {
          setGraphData(response);
        })
        .catch((e) => {
          if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect({
              ...loginRequest,
              account: instance.getActiveAccount() as AccountInfo,
            });
          }
        });
    } else if (graphData) {
      // console.log('graphData', graphData);
      props.onAuthenticate(graphData);
    }
  }, [inProgress, graphData, instance, props]);

  return <>{graphData ? <ProfileData graphData={graphData} /> : null}</>;
};

export function AzureProfile(props: AzureProfileProps) {
  const authRequest = {
    ...loginRequest,
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Popup}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={Loading}
    >
      <AzureProfileContent onAuthenticate={props.onAuthenticate} />
    </MsalAuthenticationTemplate>
  );
}
