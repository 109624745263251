import { useContext, useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import { IoIosClose } from 'react-icons/io';

import Button from 'components/ui/button';
import { useModal } from 'context/ModalContext';
import Box from 'components/ui/box';

import FeedbackImg from 'assets/img/comportamento/bg-feedback-moedas.png';
import { toast } from 'react-toastify';

interface Props {
  callback: (obj: { id: string; message?: string }) => void;
  id: string;
  status: string | undefined;
  data?: any;
}

export default function ModalFeedback(props: Props) {
  ReactModal.setAppElement('#modal');

  const modalID = 'validation-feedback';

  const [message, setMessage] = useState<string>();

  const messageRef = useRef<any>();

  const { openModal, closeModal } = useModal();
  const { callback, status, id, data } = props;

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  const text = () => {
    if (status === 'rejeitado') {
      return 'Conte à equipe o motivo da entrega ter sido rejeitada:';
    } else if (status === 'aprovado') {
      return 'Conte à equipe o motivo da entrega ter sido validada:';
    } else if (status === 'promovido') {
      return 'Conte à equipe o motivo da entrega ter sido promovida:';
    } else if (status === 'dobrado') {
      return 'Conte à equipe o motivo da entrega ter sido bonificada:';
    } else if (status === 'selo') {
      return 'Conte à equipe o motivo da entrega ter sido indicada para Selo Realização Destaque:';
    }
  };

  function handleSubmit() {
    if (typeof message === 'string' && message.trim().length) {
      if (message.trim().length > 200 || message.trim().length < 20) {
        return toast(
          `Seu feedback não pode ter menos de 20 caracteres ou mais de 200 caracteres. Você digitou ${
            message.trim().length
          } ${message.trim().length > 1 ? `caracteres` : `caracter`}.`,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            type: 'error',
          }
        );
      }

      callback({ id: 'feedback', message: message.trim() });
      setSuccess(true);
    }
  }

  useEffect(() => {
    if (
      typeof id === 'string' &&
      typeof openModal === 'string' &&
      openModal === `${modalID}-${id}`
    ) {
      setMessage(undefined);
      setSuccess(false);
      setIsOpen(true);
    } else {
      setMessage(undefined);
      setSuccess(false);
      setIsOpen(false);
    }
  }, [id, openModal]);

  if (!modalIsOpen) return <></>;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Avaliação"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center w-screen h-screen p-6 pt-[10vh] overflow-y-auto"
        contentElement={() => (
          <Box className={`w-[90%] lg:w-auto lg:max-w-[600px]`}>
            <Button
              color="primary"
              className="absolute -right-5 -top-5 rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
              onClick={() => closeModal()}
            >
              <IoIosClose color="white" size={40} />
            </Button>

            {!!success ? (
              <>
                <div className="w-full lg:w-[260px] flex flex-col items-center justify-center space-y-8">
                  <div className="flex items-center justify-center">
                    <img
                      src={FeedbackImg}
                      alt="Enviado!"
                      className="w-[173px] block"
                    />
                  </div>
                  <h4 className="block text-2xl font-semibold text-yellow text-center">
                    Mandou bem!
                  </h4>
                  <p className="text-sm font-white font-thin text-center block">
                    Você ganhou pontos por enviar o feedback para a equipe.
                  </p>
                  <button
                    className={`text-white bg-slate-blue text-sm inline-flex items-center justify-center font-semibold uppercase rounded py-3 px-4 leading-none`}
                    onClick={() => closeModal()}
                  >
                    Fechar
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="w-full lg:w-[536px] flex flex-col items-center justify-center space-y-8">
                  <h4 className="block text-center text-yellow text-lg font-semibold leading-none">
                    Dê um feedback sobre essa Realização Destaque
                  </h4>
                  <div className="flex flex-col space-y-2">
                    <p className="text-alice-blue text-base font-thin block">
                      {text()}
                    </p>

                    <textarea
                      className="w-full bg-maastricht-blue rounded px-4 py-2 text-alice-blue text-base font-thin"
                      maxLength={200}
                      minLength={20}
                      ref={(ref) => (messageRef.current = ref)}
                      onChange={(e) => setMessage(e.target.value)}
                      defaultValue={message}
                    ></textarea>

                    <p className="text-xs text-alice-blue font-light block text-right -mt-1">
                      {typeof message === 'string' ? message?.trim().length : 0}
                      /200
                    </p>

                    <p className="text-xs text-electric-blue font-thin">
                      Você será recompensado com pontos ao dar feedback sobre as
                      realizações que você analisou.
                    </p>
                  </div>
                  <button
                    className={`disabled:opacity-60 disabled:cursor-not-allowed text-white bg-slate-blue text-sm inline-flex items-center justify-center font-semibold uppercase rounded py-3 px-4 leading-none`}
                    onClick={() => {
                      handleSubmit();
                    }}
                    disabled={
                      !message ||
                      !message.trim().length ||
                      message.trim().length < 20
                    }
                  >
                    Enviar feedback
                  </button>
                </div>
              </>
            )}
          </Box>
        )}
      />
    </>
  );
}
