import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { IoIosClose } from 'react-icons/io';

import Button from 'components/ui/button';
import { useModal } from 'context/ModalContext';
import Box from 'components/ui/box';

import FeedbackImg from 'assets/img/comportamento/bg-feedback-moedas.png';

interface Props {
  callback: (obj: { id: string; message?: string }) => void;
  id: string;
  status: string | undefined;
  data?: any;
}

export default function ModalStop(props: Props) {
  ReactModal.setAppElement('#modal');

  const modalID = 'validation-stop';

  const { openModal, closeModal } = useModal();
  const { callback, status, id, data } = props;

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    if (
      typeof id === 'string' &&
      typeof openModal === 'string' &&
      openModal === `${modalID}-${id}`
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [id, openModal]);

  if (!modalIsOpen) return <></>;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Avaliação"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center w-screen h-screen p-6 pt-[10vh] overflow-y-auto"
        contentElement={() => (
          <Box className={`w-[90%] lg:w-auto lg:max-w-[600px]`}>
            <Button
              color="primary"
              className="absolute -right-5 -top-5 rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
              onClick={() => {
                closeModal();
              }}
            >
              <IoIosClose color="white" size={40} />
            </Button>

            <div className="w-full lg:w-[536px] flex flex-col items-center justify-center space-y-8">
              <h4 className="block text-center text-yellow text-lg font-semibold leading-none">
                Encerrar Realização Destaque
              </h4>
              <p className="text-alice-blue text-base font-thin block">
                Ao encerrar, essa Realização Destaque não será enviada para
                apreciação da diretoria. Deseja confirmar essa ação?
              </p>
              <button
                className={`text-white bg-slate-blue text-sm inline-flex items-center justify-center font-semibold uppercase rounded py-3 px-4 leading-none`}
                onClick={() => {
                  callback({ id: 'stop' });
                  closeModal();
                }}
              >
                CONFIRMAR
              </button>
            </div>
          </Box>
        )}
      />
    </>
  );
}
