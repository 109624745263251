
import axios from "axios";
import { api } from "./api";

type SignInRequestData = {
	username: string;
	password: string;
	apiKey?: string;
	grant_type?: string;
};

export async function getSSOEnvironment(){
	try{
		await axios.get("https://login.intranet.bb.com.br/");
		return 'internal';
	}
	catch(e:any){
		if(e && e.code === 'ERR_NETWORK'){
			return 'external';
		}else{
			return 'internal';
		}
	}
}

export function signInRequest(data: SignInRequestData) {
	data["apiKey"] = process.env.REACT_APP_APIKEY;
	data["grant_type"] = "password";

	return api
		.post("/auth/token", data)
		.then((response) => {
			return response.data;
		})
		.catch((error) => {
			// console.error(error.response.data.message);
			return error.response.data;
		});
}

export function fetchUser() {
	return api
		.get("/player/me/status")
		.then((response) => {
			const user = response.data;
			if(user){
				return user;
			}
			else{
				document.dispatchEvent(new CustomEvent("signout"));
			}
		})
		.catch((error) => {
			// console.log(error);
		});
}

export function registerUser(user: any) {
	return api
		.put("/database/registration__c", user, {
			headers: {
				Authorization:
					"Basic " +
					btoa(
						`${process.env.REACT_APP_APIKEY}:${process.env.REACT_APP_REGISTRATION_SECRETKEY}`
					),
			},
		})
		.then((response) => {
			const user = response.data;
			return user;
		})
		.catch((error) => {
			// console.log(error);
		});
}

export async function recoverPasswordRequest(data: SignInRequestData) {
	data["apiKey"] = process.env.REACT_APP_APIKEY;
	data["grant_type"] = "password";

	try {
		const response = await api.post("/auth/token", data);
		return response.data.access_token;
	} catch (error) {
		console.log(error);
	}
}
