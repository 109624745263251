import { useAuth } from "context/AuthContext";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import ReactModal from "react-modal";

import badgeAnimation from "assets/lottie/trophy.json";

import Button from "components/ui/button";

export function AchievementModal(props: any) {
	// const modalID = "achievement";

	const [modalIsOpen, setIsOpen] = useState(false);
	const [modalData, setModalData] = useState<any>();
	const { refreshUser, user } = useAuth();
	// const { openModal, closeModal } = useContext(ModalContext);
	// const { children } = props;

	ReactModal.setAppElement("#modal-achievement");

	function handleCloseModal() {
		setIsOpen(false);
	}

	function afterOpenModal() {
		//
	}

	useEffect(() => {
		const listener = async ({ data }: any) => {
			if (data.type === "achievement") {
				setIsOpen(true);
				setModalData(data.value);
				refreshUser();
			}
		};
		window.addEventListener("message", listener);
		return () => {
			window.removeEventListener("message", listener);
		};
	}, [refreshUser]);

	if(!user) return null;

	return (
		<>
			<ReactModal
				shouldCloseOnOverlayClick={false}
				isOpen={modalIsOpen}
				onAfterOpen={afterOpenModal}
				onRequestClose={handleCloseModal}
				contentLabel="Example Modal"
				portalClassName="relative z-50"
				overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
				contentElement={() => (
					<div>
						<div className="bg-white rounded-xl p-3 flex flex-col items-center flex-none mx-5">
							<h1 className="text-xl lg:text-3xl mt-4 font-bold text-blue-500 text-center">
								Parabéns
							</h1>

							{!modalData?.name && <p className="text-gray-500 text-center">Você jogou junto!</p>}
							{modalData?.name && <p className="text-orange-500 font-bold text-center">{modalData?.name}</p>}

							<div className="relative w-[300px]">
								<Lottie animationData={badgeAnimation} loop={true} />
							</div>

							<div className="py-3 relative z-10">
								<Button onClick={() => handleCloseModal()}>FECHAR</Button>
							</div>
						</div>
					</div>
				)}
			/>
		</>
	);
}
