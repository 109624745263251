/* eslint-disable react-hooks/exhaustive-deps */
import ArticlePlaceholder from 'components/ui/article-placeholder';
import Box from 'components/ui/box';
import Button from 'components/ui/button';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import { AiFillLeftCircle } from 'react-icons/ai';
import { RiCoinsLine } from 'react-icons/ri';
import ReactModal from 'react-modal';
import { useNavigate, useParams } from 'react-router';
import { getVirtualGood, purchaseItem } from 'services/store.service';

export default function ShopDetail() {
  const [virtualGood, setVirtualGood] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [purchased, setPurchased] = useState(false);

  const { user, refreshUser } = useAuth();

  const params = useParams();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    navigate('../');
  }

  function afterOpenModal() {}

  useEffect(() => {
    if (!params.id || !user) {
      return;
    }
    const loadData = async () => {
      try {
        const good = await getVirtualGood(params.id as string);
        // console.log(good)
        setVirtualGood(good);
        setPurchased(isPurchased(good));
      } catch (err) {}
      setLoading(false);
    };
    loadData();
  }, [params, user]);

  function isPurchased(good?: any) {
    if (!user?.catalog_items) return false;
    try {
      const purchased = Object.keys(user?.catalog_items).find((item: any) => {
        // console.log('isPurchased', item, good._id);
        return item === good._id;
      });
      return !!purchased;
    } catch (e) {
      return false;
    }
  }

  function verifyRequirements() {
    // atualmente está verificando apenas um tipo de ponto
    // precisa refatorar para aceitar multiplos points no futuro
    const requirement = virtualGood?.requires
      .filter((item: any) => item.type === 0)
      .reduce((acc: any, item: any) => {
        acc = item.total;
        return acc;
      }, -1);

    return requirement <= currentBalance();
  }

  function currentBalance() {
    try {
      return user?.point_categories.point || 0;
    } catch (err) {
      return 0;
    }
  }

  async function handleRedeem(item: any) {
    if (!user) return;

    try {
      if (item.extra.url) {
        window.open(item.extra.url, '_blank');
      } else {
        setProcessing(true);
        setPurchased(false);
        const purchaseResult = await purchaseItem(item._id, user._id);
        if (purchaseResult && purchaseResult.achievements.length) {
          await refreshUser();
          setPurchased(true);
        }
        setProcessing(false);
      }
    } catch (err) {
      setProcessing(false);
    }
  }

  function renderLoading() {
    return (
      <div className="text-center p-4 w-full">
        <ArticlePlaceholder />
      </div>
    );
  }

  function invalidItem() {
    return <div className="text-center p-4">ITEM INVÁLIDO</div>;
  }

  function renderItem() {
    if (!virtualGood) {
      return null;
    }

    return (
      <div className="flex flex-col w-full space-y-4">
        <div className="flex flex-col lg:flex-row lg:space-x-5">
          {virtualGood.image && (
            <div className="lg:w-1/3 bg-gray-100 rounded-xl overflow-hidden border-2 lg:border-4 border-white">
              <img
                src={virtualGood.image.original.url}
                alt={virtualGood.name}
                className="w-full h-full object-contain"
              />
            </div>
          )}

          <div className="flex-1 flex flex-col items-center space-y-3 justify-between p-4 bg-black bg-opacity-20 rounded-xl">
            <div className="text-xl lg:text-xl lg:text-3xl text-yellow font-bold">
              {virtualGood.name}
            </div>
            {!!virtualGood.description && (
              <div className="w-full text-center">
                {virtualGood.description}
              </div>
            )}

            {virtualGood.requires.length > 0 && (
              <div
                className={`flex bg-yellow rounded-full shadow-lg text-blue-dark flex-row items-center justify-center font-bold text-2xl px-3 py-1 ${
                  !verifyRequirements() ? 'text-blue-dark' : ''
                }`}
              >
                {virtualGood.requires[0].total} <RiCoinsLine size={32} />
              </div>
            )}

            {processing && (
              <div className="p-4 flex items-center">
                <Button color="outline" className="text-xl">
                  Processando resgate...
                </Button>
              </div>
            )}

            {!processing && !purchased && verifyRequirements() && (
              <div className="p-4 flex items-center">
                <Button
                  color="success"
                  className="text-xl"
                  onClick={() => handleRedeem(virtualGood)}
                >
                  RESGATAR
                </Button>
              </div>
            )}

            {!processing && purchased && (
              <div className="p-4 flex items-center">
                <Button color="outline" className="text-xl pointer-events-none">
                  Você resgatou este item
                </Button>
              </div>
            )}

            {!processing && !verifyRequirements() && (
              <div className="flex items-center">
                <Button disabled color="danger" className="text-xl">
                  Você não possui pontos suficientes
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <ReactModal
      key={params.id}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={handleCloseModal}
      contentLabel="Example Modal"
      portalClassName=""
      overlayClassName="fixed z-50 top-0 left-0 w-full h-full w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
      contentElement={() => (
        <Box className="bg-blue-dark p-3 flex flex-col w-[95%] lg:max-w-screen-md justify-center items-center space-y-4">
          <div className="mx-auto container p-2 lg:p-5 overflow-y-auto max-h-[70vh]">
            <div className="flex flex-row items-start md:items-center lg:space-x-5">
              <div>
                <button onClick={() => setIsOpen(false)}>
                  <AiFillLeftCircle size={48} className="text-yellow" />
                </button>
              </div>
              <div className="flex-1 flex flex-col lg:flex-row justify-between items-end lg:items-center">
                <h1 className="py-1 text-2xl lg:text-xl lg:text-3xl">
                  Catálogo de Experiências
                </h1>
                <div>
                  <p className="text-blue-light">Saldo de Pontos</p>
                  <div className="flex flex-row justify-end items-center text-2xl text-yellow">
                    <b>{currentBalance()}</b>{' '}
                    <RiCoinsLine size={24} className="ml-1" />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              {loading && renderLoading()}
              {!virtualGood && !loading && invalidItem()}
              {!!virtualGood && !loading && renderItem()}
            </div>
          </div>
        </Box>
      )}
    />
  );
}
