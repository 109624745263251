import { api } from './api';
import {
  CyclesInterface,
  InviteInterface,
  LastManagerInterface,
  VacationManagerInterface,
  RankingKPI,
  UserGroupInterface,
} from 'types/user';
import { SortPagination } from 'utils/paginations';
import { string } from 'prop-types';

export async function getKPIDestaqueCultura(
  behavior: string,
  player?: string
): Promise<RankingKPI> {
  const dummy = { position: 0, total_positions: 0 };

  try {
    const payload = {
      behavior,
      player,
      start: new Date(2022, 9, 1),
      end: new Date(),
    };
    const { data } = await api.post('find/destaque_cultura', payload);
    // console.log('getKPIDestaqueCultura', behavior, data);

    if (!Array.isArray(data)) {
      return dummy;
    } else {
      if (data.length) {
        const { position, total_positions, points } = data[0] as RankingKPI;
        return { position, total_positions, points };
      } else {
        return dummy;
      }
    }
  } catch (e) {
    // console.log('ERROR: getKPIDestaqueCultura', e);
    return dummy;
  }
}

export async function getKPIGaleriaReconhecimento(
  player?: string,
  start?: any,
  end?: any
): Promise<RankingKPI> {
  const dummy = { position: 0, total_positions: 0 };

  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/galeria_conhecimento', payload);
    // console.log('getKPIDestaqueCultura', behavior, data);

    if (!Array.isArray(data)) {
      return dummy;
    } else {
      if (data.length) {
        // const { position, total_positions } = data[0] as RankingKPI;
        return data[0] as RankingKPI;
      } else {
        return dummy;
      }
    }
  } catch (e) {
    // console.log('ERROR: getKPIDestaqueCultura', e);
    return dummy;
  }
}

export async function getKPIPointsOnline(
  player?: string,
  start?: any,
  end?: any
): Promise<any> {
  const dummy = { position: 0, total_positions: 0 };

  try {
    const { data } = await api.post(`find/pontos_online?time=${+new Date()}`, {
      player,
    });

    if (!Array.isArray(data)) {
      return [];
    } else {
      if (data.length) {
        return data[0] as any;
      } else {
        return dummy;
      }
    }
  } catch (e) {
    return dummy;
  }
}

export async function getKPIPontosCultura(
  player?: string,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/pontos_cultura', payload);

    if (!Array.isArray(data)) {
      return null;
    } else {
      if (data?.length) {
        return data[0];
        // return data.reduce((value, item) => {
        //   if (item) {
        //     value[item._id] = item.total;
        //     return value;
        //   }
        //   return value;
        // }, {});
      } else {
        return null;
      }
    }
  } catch (e) {
    return null;
  }
}

export async function getResultadosCultura() {
  try {
    const payload = {};
    const { data } = await api.post('find/resultado_cultura', payload, {
      headers: { Range: 'items=0-10000' },
    });
    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

export async function getResultadosGaleriaReconhecimento(
  search?: string,
  rang?: number
) {
  try {
    const payload = {
      search,
    };

    const rangeStart = rang ? rang : 0;
    const data = await api.post('find/resultado_galeria', payload, {
      headers: { Range: `items=${rangeStart}-20` },
    });

    return SortPagination(data);
  } catch (e) {
    throw e;
  }
}

export async function getUserByPrefixo(
  prefixo: string,
  search?: string,
  rang?: number,
  total?: number
) {
  try {
    const payload = {
      search,
      prefixo,
    };

    const rangeStart = rang ? rang : 0;
    const data = await api.post('find/players_by_prefixo', payload, {
      headers: { Range: `items=${rangeStart}-${total ? total : 20}` },
    });

    return SortPagination(data);
  } catch (e) {
    throw e;
  }
}

export async function getEquieGestor(
  player: string,
  search?: string,
  rang?: number,
  total?: number
) {
  try {
    const payload = {
      search,
      player,
    };

    const rangeStart = rang ? rang : 0;
    const data = await api.post('find/equipe_gestor', payload, {
      headers: { Range: `items=${rangeStart}-${total ? total : 20}` },
    });

    return SortPagination(data);
  } catch (e) {
    throw e;
  }
}

export async function getResultadosSelosBB() {
  try {
    const payload = {};
    const { data } = await api.post('find/resultado_selo', payload, {
      headers: { Range: 'items=0-10000' },
    });
    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

export async function getKPIHistory(player?: string, start?: any, end?: any) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/extrato', payload, {
      headers: { Range: 'items=0-1000' },
    });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getKPIHistoryPag(
  player?: string,
  rang?: number,
  total?: number,
  start?: any,
  end?: any) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };

    const rangeStart = rang ? rang : 0;
  
    const data = await api.post('find/extrato', payload, {
      headers: { Range: `items=${rangeStart}-${total ? total : 5}` },
    });
    // console.log('getKPIHistorico', data);
      return SortPagination(data);
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getKPIHistoryByTime(
  player?: string,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/extrato_period', payload, {
      headers: { Range: 'items=0-1000' },
    });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getKPISentHistory(
  player?: string,
  rang?: number,
  total?: number,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };

    const rangeStart = rang ? rang : 0;
    const data = await api.post('find/extrato_enviado', payload, {
      headers: { Range: `items=${rangeStart}-${total ? total : 5}` },
    });
    // console.log('getKPIHistorico', data);

  
    return SortPagination(data);
    
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getPlayerInfo(player?: string) {
  try {
    const payload = {
      player,
    };
    const { data } = await api.post('find/info', payload);
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return null;
    } else {
      return data[0];
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getTaskCycles(
  reverse = true
): Promise<CyclesInterface[]> {
  try {
    const payload = [
      {
        $match: {
          status: {
            $ne: 'waiting',
          },
          date_start: {
            $gte: {
              $date: 1672542000000,
            },
          },
        },
      },
      {
        $sort: {
          date_finish: reverse ? -1 : 1,
        },
      },
    ];

    const { data } = await api.post(
      'database/task_schedule__c/aggregate',
      payload
    );
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getTasks(player?: string) {
  try {
    const payload = {
      player,
    };
    const { data } = await api.post('find/entregas', payload);
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getTasksManager(player?: string) {
  try {
    const payload = {
      player: String(player),
    };
    const { data } = await api.post('find/tasks_manager', payload);
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function deleteTask(id: string) {
  try {
    const { data } = await api.delete(`database/bb_task__c?q=_id:'${id}'`);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

export async function submitTask(payload: any) {
  try {
    const _payload = { ...payload };

    const serverTime = await getServerTime();

    if (!_payload.created) {
      _payload.created = { $date: serverTime };
    } else if (typeof _payload.created === 'number') {
      _payload.created = { $date: _payload.created };
    }

    _payload.updated = { $date: serverTime };

    if (typeof _payload.start === 'number') {
      _payload.start = { $date: _payload.start };
    }

    if (typeof _payload.end === 'number') {
      _payload.end = { $date: _payload.end };
    }

    // Informações trazidas pelo aggregate. Não precisa salvar na collection.
    delete _payload.owner_name;
    delete _payload.owner_avatar;
    delete _payload.target;
    delete _payload.prefixo;
    delete _payload.feedback;
    delete _payload.evaluator_detail;

    const { data } = await api.post('database/bb_task__c', _payload);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function updateTask(payload: any) {
  try {
    const _payload = { ...payload };

    const serverTime = await getServerTime();

    if (!_payload.created) {
      _payload.created = { $date: serverTime };
    } else if (typeof _payload.created === 'number') {
      _payload.created = { $date: _payload.created };
    }

    _payload.updated = { $date: serverTime };

    if (typeof _payload.start === 'number') {
      _payload.start = { $date: _payload.start };
    }

    if (typeof _payload.end === 'number') {
      _payload.end = { $date: _payload.end };
    }

    // Informações trazidas pelo aggregate. Não precisa salvar na collection.
    delete _payload.owner_name;
    delete _payload.owner_avatar;
    delete _payload.target;
    delete _payload.prefixo;
    delete _payload.feedback;
    delete _payload.evaluator_detail;

    const { data } = await api.put('database/bb_task__c', _payload);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getTaskById(id: any) {
  try {
    if (!id) return;
    const { data } = await api.post('find/task', { id });
    if (!Array.isArray(data)) {
      return [];
    } else {
      return data[0];
    }
  } catch (e) {
    throw e;
  }
}

export async function getConfig(id: string): Promise<any[]> {
  try {
    const { data } = await api.get(`database/config__c/${id}`);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function getUserGroup(
  player: string
): Promise<UserGroupInterface[]> {
  try {
    const { data } = await api.post(`find/user_group`, { player });

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    return [];
  }
}

export async function sendInvite(
  payload: InviteInterface
): Promise<InviteInterface> {
  try {
    const { data } = await api.post('database/invite__c', payload);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function checkInvite(
  player: string,
  sender: string
): Promise<boolean> {
  try {
    const { data } = await api.post('find/check_invite', { player, sender });

    if (!Array.isArray(data)) {
      return false;
    } else {
      if (data.length && data[0].total > 0) {
        return true;
      } else {
        return false;
      }
    }
  } catch (e) {
    return false;
  }
}

export async function submitFeedback(payload: any) {
  try {
    const serverTime = await getServerTime();

    payload.created = serverTime;

    const { data } = await api.post('database/bb_task_feedback__c', payload);
    return data;
  } catch (e) {
    throw e;
  }
}

export async function playerHasFeedback(
  taskId: string,
  evaluatorId: string
): Promise<boolean> {
  if (!taskId || !evaluatorId) return false;

  try {
    const payload = [
      {
        $match: {
          evaluatorId,
          taskId,
        },
      },
    ];

    const { data } = await api.post(
      'database/bb_task_feedback__c/aggregate',
      payload
    );

    if (!Array.isArray(data)) {
      return false;
    } else {
      return !!data.length;
    }
  } catch (e) {
    return false;
  }
}

export async function deleteMissionFeedback(taskId: string): Promise<boolean> {
  try {
    const { data } = await api.delete(
      `database/bb_task_feedback__c?q=taskId:'${taskId}'`
    );

    if (!Array.isArray(data)) {
      return false;
    } else {
      return !!data.length;
    }
  } catch (e) {
    return false;
  }
}

export async function deleteFeedbackByQuery(query: string): Promise<boolean> {
  try {
    const { data } = await api.delete(
      `database/bb_task_feedback__c?q=${query}`
    );

    if (!Array.isArray(data)) {
      return false;
    } else {
      return true;
    }
  } catch (e) {
    return false;
  }
}

export async function playerLastManager(
  player: string
): Promise<LastManagerInterface | null> {
  if (!player) return null;

  try {
    const { data } = await api.post('find/get_last_manager', { player });

    if (!Array.isArray(data)) {
      return null;
    } else {
      return data.length ? data[0] : null;
    }
  } catch (e) {
    return null;
  }
}

export async function getVacationManager(
  player: string | undefined
): Promise<VacationManagerInterface[] | null> {
  if (!player) return null;

  try {
    const { data } = await api.post('find/vacation_manager', { player });

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    return null;
  }
}

export async function getServerTime(): Promise<number> {
  const { data } = await api.post(`database/task_schedule__c/aggregate`, [
    {
      $facet: {
        data: [
          {
            $match: {
              _id: 'IDInexistenteParaPegarHoraDoServidor',
            },
          },
          {
            $project: {
              _id: 1,
            },
          },
        ],
      },
    },
    {
      $project: {
        server_time: {
          $ifNull: [
            {
              $arrayElemAt: ['$data._id', 0],
            },
            '$$NOW',
          ],
        },
      },
    },
  ]);

  return data[0].server_time;
}
