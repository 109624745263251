import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { AiFillLeftCircle } from "react-icons/ai";
import { BsPlusLg } from "react-icons/bs";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactPaginate from "react-paginate";

import Box from "components/ui/box";
import { useAuth } from "context/AuthContext";
import InputMask from "react-input-mask";
// import { useModal } from 'context/ModalContext';
import { useState, useEffect, useRef } from "react";
import {
  getResultadosGaleriaReconhecimento,
  getResultadosSelosBB,
} from "services/dashboard.service";
import ArticlePlaceholder from "components/ui/article-placeholder";
import Avatar from "funifier-nice-avatar";
import { TbSearch } from "react-icons/tb";
// import { GaleriaDetalheModal } from 'components/galeria-detalhe-modal';

import "./styles.scss";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { GaleriaConhecimentoDetail } from "./detail";

export default function GaleriaReconhecimento() {
  const [list, setList] = useState<unknown[]>();
  const [selosBB, setSelosBB] = useState<unknown[]>();
  const [page, setPage] = useState(0);
  const [activePag, setActivePag] = useState(0);
  const [showDetail, setShowDetail] = useState('');

  const [mask] = useState("");

  const searchRef = useRef<any>();

  const [loadingList, setLoadingList] = useState(true);
  const [loadingSelosBB, setLoadingSelosBB] = useState(true);

  const params = useParams();

  const { user } = useAuth();

  const [activeTab, setActiveTab] = useState(0);

  function handleDetail(item: any) {
    setShowDetail(item);
  }


  async function loadData(e?: any, itemPage?: number) {
    if (e) {
      e.preventDefault();
    }

    try {
      if (activeTab === 0) {
        const rangeItem = itemPage ? itemPage * 20 : 0;

        setActivePag(itemPage ? itemPage : 0);
        setLoadingList(true);
        setList([]);

        const homeSection = document.getElementById("main-body");
        if (homeSection) {
          homeSection.scrollTop = 0;
        }

        const data = await getResultadosGaleriaReconhecimento(
          searchRef.current.value,
          rangeItem
        );

        setPage(data.range.pages);

        setList(data.result);

        setLoadingList(false);
      } else if (activeTab === 1) {
        const data = await getResultadosSelosBB();
        setSelosBB(data);
        setLoadingSelosBB(false);
      }
    } catch (e) {}
  }

 if (showDetail) return <GaleriaConhecimentoDetail onClose={() => setShowDetail('')} id={showDetail} />

  return (
    <>
      {!params.id ? (
        <div className="mx-auto container p-3 lg:p-10 overflow-hidden">
          <div className=" mb-3 lg:mb-10">
            <div className="flex flex-ro items-center space-x-3 lg:space-x-1">
              <div>
                <Link to="../">
                  <AiFillLeftCircle size={48} className="text-purple" />
                </Link>
              </div>
              {/* <div className="w-36 hidden md:block">
          <img src={Logo} alt="#JogaJunto BB" />
        </div> */}
              <h1 className="text-lg lg:text-3xl  py-1 px-6 rounded-full">
                Galeria do Reconhecimento
              </h1>
            </div>
          </div>

          <div className="rounded-xl overflow-hidden items-center justify-between mb-12 hidden lg:flex">
            <button
              className={`${"bg-slate-blue"} w-full h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
              onClick={() => setActiveTab(0)}
            >
              Ciclo 2022/2
            </button>
            {/* <button
              className={`${"bg-maastricht-blue"} cursor-not-allowed w-full h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
              onClick={() => setActiveTab(0)}
            >
              Ciclo 2023/1
            </button> */}
          </div>

          <div className="mb-5 lg:flex items-center justify-between">
            <p className="text-lg mb-4 lg:mb-0">
              Reconhecimentos recebidos pelos participantes
            </p>

            <div className="space-x-4 flex flex-row items-center justify-between">
              <form onSubmit={(e) => loadData(e)} className="pl-1 -ml-1 flex-1">
                <InputMask
                  placeholder="Pesquise com nome, chave, divisão ou gerência"
                  alwaysShowMask={false}
                  mask={mask}
                  ref={(ref: any) => {
                    searchRef.current = ref;
                  }}
                  inputMode="search"
                  style={{ fontSize: 16 }}
                  className="min-w-full lg:min-w-[416px] bg-maastricht-blue text-sm text-white font-thin flex-1 rounded-md py-2 px-4"
                />
              </form>

              <TbSearch
                size={28}
                className="cursor-pointer hover:ring-2 mr-1 rounded-full"
                onClick={() => loadData(null)}
              />
            </div>
          </div>

          <div className="space-y-2 mb-2">
            <p>
              Olá, colega! <br />
              Na Galeria do Reconhecimento estarão os funcionários que mais se
              destacarem na pontuação final em cada grupo e seus nomes serão
              <br />
              divulgados aqui como referências no #JogaJuntoBB. <br />
            </p>

            <p>
              No ciclo 2022/2 estão os funcionários que foram destaques e que
              participaram da primeira fase Piloto do programa.
            </p>
            <p>
              Seu nome poderá aparecer aqui no final do ciclo 2023/1 caso esteja
              entre os 30% melhores colocados.
            </p>
          </div>

          {/* <Box className="mb-5">
          <div className="w-full flex flex-col md:flex-row md:justify-center md:items-center space-y-5 md:space-y-0 md:space-x-5 ">
            <Button
              color={activeTab === 0 ? 'featured' : 'outline'}
              className="uppercase py-2"
              onClick={() => setActiveTab(0)}
            >
              Galeria do Reconhecimento
            </Button>
            <Button
              color={activeTab === 1 ? 'featured' : 'outline'}
              className="uppercase py-2"
              onClick={() => setActiveTab(1)}
            >
              Selo BB
            </Button>
          </div>
        </Box> */}

          {/* {activeTab === 0 ? (
          <Box>
            <div className="space-y-5 lg:p-3">
              <div className="text-center">
                <h3 className="text-xl">Em breve</h3>
              </div>
            </div>
          </Box>
        ) : null} */}

          {activeTab === 0 ? (
            <div>
              <Box>
                <div className="space-y-5 lg:p-3">
                  {loadingList && (
                    <div className="space-y-2">
                      <ArticlePlaceholder />
                      <ArticlePlaceholder />
                    </div>
                  )}

                  {!loadingList && list?.length === 0 && (
                    <div className="text-center">
                      <h3 className="text-xl">Nenhum resultado encontrado</h3>
                    </div>
                  )}

                  <div className="space-y-2">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                      {list?.map((item: any) => (
                        <div
                          className="bg-maastricht-blue rounded-xl text-white space-y-2 cursor-pointer hover:ring-2 overflow-hidden"
                          key={item?._id}
                          onClick={() => handleDetail(item.matricula)}
                        >
                          <div className="px-4">
                            <div className="flex flex-col items-center justify-around md:h-40 space-y-1 text-center">
                              <div className="w-28 h-28 overflow-hidden rounded-full bg-purple text-3xl font-bold flex flex-row items-center justify-center">
                                {!!item.avatar_type &&
                                  (item.image ? (
                                    <img
                                      src={item.image}
                                      alt={item.nome}
                                      className="w-full h-full"
                                    />
                                  ) : (
                                    item.nome[0]
                                  ))}
                                {!item.avatar_type &&
                                  (!!item.avatar ? (
                                    <Avatar
                                      className="w-full h-full"
                                      {...item.avatar}
                                    />
                                  ) : (
                                    item.nome[0]
                                  ))}
                              </div>
                            </div>
                          </div>

                          <div className="bg-prussian-blue-3 w-full h-full text-center pb-7 pt-5 ">
                            <div className="font-bold">{item.nome}</div>
                            <div className="mt-1">
                              <span className="text-electric-blue font-thin">
                                {item.diretoria}{" "}
                              </span>
                              <span className="font-thin">- {item.funcao}</span>
                            </div>
                            <div className="text-electric-blue  mb-3">
                              <span className="font-thin">
                                {item.matricula}
                              </span>
                            </div>

                            <button className="mt-4">
                              <BsPlusLg
                                className="mr-2 inline-block"
                                size={17}
                                color="#C2D2FF"
                              />
                              <span className="uppercase">Ver mais</span>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>

                <div className="flex justify-center">
                  <ReactPaginate
                    activeClassName={"item active "}
                    breakClassName={"item break-me "}
                    breakLabel={"..."}
                    containerClassName={"pagination"}
                    disabledClassName={"disabled-page"}
                    nextClassName={"item next "}
                    pageClassName={"item pagination-page "}
                    previousClassName={"item previous"}
                    initialPage={0}
                    forcePage={activePag}
                    nextLabel={
                      <IoIosArrowForward style={{ fontSize: 18, width: 150 }} />
                    }
                    onPageChange={(data) => loadData(null, data.selected)}
                    pageRangeDisplayed={3}
                    pageCount={page}
                    previousLabel={
                      <IoIosArrowBack style={{ fontSize: 18, width: 150 }} />
                    }
                  />
                </div>
              </Box>
            </div>
          ) : null}

          {activeTab === 1 ? (
            <div className="space-y-5">
              <Box>
                <div className="space-y-5 lg:p-3 text-center">
                  <p className="text-lg text-yellow">
                    O selo é o reconhecimento a quem é destaque na Unidade nos
                    pilares Eficiência, Resultado ou Protagonismo.
                  </p>
                  <p>
                    É concedido pelo Comitê da Unidade e vale 1 bônus de 500
                    pontos.
                  </p>
                </div>
              </Box>

              <div className="grid grid-cols-3 gap-3 w-full">
                <Box>
                  <div className="space-y-5 lg:p-3">
                    <h4 className="text-xl text-yellow">Protagonismo</h4>
                    <p>
                      Destaque pelo comportamento ativo com foco na construção
                      de uma ideia ou causa com o objetivo de gerar valor para a
                      Empresa.
                    </p>
                  </div>
                </Box>
                <Box>
                  <div className="space-y-5 lg:p-3">
                    <h4 className="text-xl text-yellow">Resultado</h4>
                    <p>
                      Destaque por realizações que impactam positivamente a
                      empresa por meio de resultados consistentes e
                      sustentáveis.
                    </p>
                  </div>
                </Box>
                <Box>
                  <div className="space-y-5 lg:p-3">
                    <h4 className="text-xl text-yellow">Eficiência</h4>
                    <p>
                      Destaque pelo alcance de objetivos relevantes para a
                      empresa, utilizando os recursos disponíveis, com qualidade
                      e prazo excelentes.
                    </p>
                  </div>
                </Box>
              </div>

              <Box>
                <div className="space-y-5 lg:p-3">
                  {loadingSelosBB && (
                    <div className="space-y-2">
                      <ArticlePlaceholder />
                      <ArticlePlaceholder />
                    </div>
                  )}

                  <div className="space-y-2">
                    <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                      {selosBB?.map((item: any) => (
                        <div
                          className="bg-black bg-opacity-60 rounded-xl text-gray-300 space-y-2 p-4"
                          key={item?._id}
                        >
                          <div className="flex flex-col items-center justify-around md:h-52 space-y-2 text-center">
                            <div className="text-center">
                              <div className="text-yellow">
                                <b>{item.matricula}</b>
                              </div>
                              <div>{item.nome}</div>
                            </div>
                            <div>
                              <img
                                src={item.image}
                                alt={item.title}
                                className="w-32"
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </Box>
            </div>
          ) : null}
        </div>
      ) : null}
      {/* <GaleriaDetalheModal /> */}
      <Outlet />
    </>
  );
}
