import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import Button from 'components/ui/button';
import { ModalContext } from 'context/ModalContext';
import { GetMoedaDescription } from 'utils/strings';

const MoedasGeral = require("assets/img/moedas/geral.png");

export function SeloMoedasModal(props: any) {
  // const modalID = "achievement";

  const modalID = 'help-moedas-bb';

  const [modalIsOpen, setIsOpen] = useState(false);

  const { openModal, closeModal } = useContext(ModalContext);

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    setIsOpen(openModal === modalID);
  }, [openModal]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="bg-blue-dark text-white rounded-xl p-10 flex flex-col items-center space-y-2 flex-none mx-5">
              
                <h1 className='text-yellow text-2xl mb-4'>Saiba mais sobre as Moedas BB</h1>
                <div>
                  <img src={MoedasGeral} className="max-w-[300px]" alt="Moedas BB" />
                </div>
                <div className='divide-y'>
                <div className='py-2'>
                  <h1 className='text-yellow text-2xl'>#UAU = 50 pontos</h1>
                  <div dangerouslySetInnerHTML={{__html:GetMoedaDescription("uau")}}></div>
                </div>
                <div className='py-2'>
                  <h1 className='text-yellow text-2xl'>#MANDOU BEM = 30 pontos</h1>
                  <div dangerouslySetInnerHTML={{__html:GetMoedaDescription("mandou")}}></div>
                </div>
                <div className='py-2'>
                  <h1 className='text-yellow text-2xl'>#VALEU = 10 pontos</h1>
                  <div dangerouslySetInnerHTML={{__html:GetMoedaDescription("valeu")}}></div>
                </div>
                </div>
              
              <div className='pt-3'>
              <Button onClick={() => closeModal()}>FECHAR</Button>
              </div>
            </div>
          </div>
        )}
      />
    </>
  );
}
