import { api } from "./api";

export interface Extra {
  session: string;
}

export interface Regulation {
  bucket: string;
  extension: string;
  filename: string;
  filename_unique: string;
  size: number;
  content_type: string;
  extra: Extra;
  _id: string;
  time: number;
  url: string;
}

export interface Created {
  $date: number;
}

export interface Updated {
  $date: number;
}

export interface IRegulation {
  regulation: Regulation;
  regulation_text: string;
  created: Created;
  _id: string;
  updated: Updated;
}

export async function getRegulation() {
  const { data } = await api.get<IRegulation[]>(`database/regulation__c`,{
    headers: {
      'Authorization' : 'Basic NjMyYjU2YzE1NDVkZDA3Njc2NjIxNzA5OjYzNGQyN2QwOTI3MWYzMzdiMzlmMTQ1OQ=='
    }
  });
  if (Array.isArray(data)) {
    return data[0] as IRegulation;
  } else {
    return null;
  }
}

export async function getTopicRegulation() {
  const { data } = await api.get(`database/topicos_do_regulamento__c`, {
    headers: {
      'Authorization' : 'Basic NjMyYjU2YzE1NDVkZDA3Njc2NjIxNzA5OjYzNGQyN2QwOTI3MWYzMzdiMzlmMTQ1OQ=='
    }
  });
  if (Array.isArray(data)) {
    return data;
  } else {
    return null;
  }
}