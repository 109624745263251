import Tippy from '@tippyjs/react';
import {
  Colaboracao,
  FocoNoCliente,
  Inovacao,
  MandouBem,
  Resolutividade,
  Uau,
  Valeu,
} from 'components/icons';
import { format, differenceInDays } from 'date-fns';

import { UserAvatar } from 'components/user-avatar';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { FaHeart } from 'react-icons/fa';
import { MdModeComment } from 'react-icons/md';
import { registerInteraction } from 'services/interactions.service';
import { useModal } from 'context/ModalContext';
import { getKPISentHistory, getServerTime } from 'services/dashboard.service';
import Box from 'components/ui/box';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import { FiEdit3 } from 'react-icons/fi';
import { getActionById, updateAction } from 'services/actionlog.service';
import Button from 'components/ui/button';

const iconSize = 20;
const Coins: any = {
  uau: <Uau yellow size={iconSize} />,
  mandou: <MandouBem yellow size={iconSize} />,
  valeu: <Valeu yellow size={iconSize} />,
};

const CoinLabel: any = {
  uau: '#UAU',
  mandou: '#MANDOUBEM',
  valeu: '#VALEU',
};

const Behaviors: any = {
  colaboracao: <Colaboracao size={iconSize} />,
  inovacao: <Inovacao size={iconSize} />,
  foco: <FocoNoCliente size={iconSize} />,
  resolutividade: <Resolutividade size={iconSize} />,
};

const BehaviorLabel: any = {
  colaboracao: 'Colaboração',
  inovacao: 'Inovação',
  foco: 'Foco no Cliente',
  resolutividade: 'Protagonismo',
};

interface Props {
  activeModal: (data: { message: string; id: string }) => void;
  message?: { message: string; id: string } | null;
}

export default function Recognition(data: Props) {
  const { activeModal, message } = data;
  const { user, currentCycle } = useAuth();
  const [list, setList] = useState<any[]>([]);
  const [time, setTime] = useState<number>();
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [load, setLoad] = useState(false);

  const { showModal } = useModal();

  const [loadingList, setLoadingList] = useState(true);

  function hasLike(item: any) {
    return !!item?.interaction.find(
      (i: any) => i.player === user?._id && !!i.like
    );
  }

  function canEdit(item: any) {
    if (!!item && !!time) {
      const differenceTime = differenceInDays(
        new Date(time),
        new Date(item.time)
      );

      return differenceTime <= 10;
    }

    return false;
  }

  function getComment(item: any) {
    const interaction = item.interaction?.find(
      (e: any) => !!e.message?.trim().length
    );

    if (interaction) {
      return interaction;
    } else {
      return null;
    }
  }

  async function updateRecognition(data: { message: string; id: string }) {
    const resp = await getActionById(data.id);
    resp.attributes.message = data.message;

    await updateAction(resp);

    const newList = [...list];

    const index = newList.findIndex((e) => e._id === data.id);

    newList[index].attributes.message = data.message;

    setList(newList);
  }

  useEffect(() => {
    async function loadData() {
      const rangeItem = page ? page * 5 : 0;

      if (page) setLoad(true);

      const data = await getKPISentHistory(
        user?._id,
        rangeItem,
        5,
        currentCycle?.date_start,
        currentCycle?.date_finish
      );
      setList(page ? [...list, ...data.result] : data.result);

      setTotal(data.range.total);
      setTotalPage(data.range.pages);

      setLoadingList(false);
      setLoad(false);
    }

    if (!!user && !!currentCycle) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCycle, user, page]);

  useEffect(() => {
    async function loadTime() {
      const res = await getServerTime();
      setTime(res);
    }

    if (!!user && !!currentCycle) {
      loadTime();
    }
  }, [currentCycle, user]);

  useEffect(() => {
    if (!!message?.message && !!message?.id) {
      updateRecognition(message);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <>
      <h3 className="text-lg lg:text-2xl mb-3">
        Reconhecimentos enviados
        <span className="text-xl lg:text-2xl font-bold text-yellow ">
          {total ? ` (${total})` : null}
        </span>
      </h3>

      <Box className="relative bg-oxford-blue border-electric-blue lg:p-6 flex flex-col justify-center items-center border-opacity-100 bg-opacity-100">
        {loadingList && (
          <div className="space-y-2 w-full">
            <ArticlePlaceholder />
            <ArticlePlaceholder />
          </div>
        )}

        {!loadingList && (
          <div className="space-y-2 w-full">
            {!!list?.length &&
              list?.map((item: any) => (
                <div
                  className="bg-maastricht-blue-3 rounded-xl text-gray-300 space-y-2 py-6 px-6 lg:px-8"
                  key={item?._id}
                >
                  <div className="flex flex-col lg:flex-col items-start lg:items-start mb-5">
                    <div className="flex-1 flex flex-col-reverse lg:flex-row items-start space-x-2 leading-tight w-full">
                      <div className="flex flex-1 w-full items-start">
                        <div className="mr-2">
                          <UserAvatar data={item.target} />
                        </div>
                        <div className="flex-1 w-full block">
                          <Tippy
                            content={`${item.target.name} (${item.target.nome_guerra})`}
                          >
                            <div className="w-full block text-sm lg:text-base text-alice-blue font-thin">
                              VOCÊ enviou para: <br />
                              <span className="font-semibold">
                                {item.target.nome_guerra ||
                                  item.target.name.split(' ')[0]}
                              </span>{' '}
                              ({item.attributes.target})
                              <BiInfoCircle className="inline relative -top-[2px] ml-1" />
                            </div>
                          </Tippy>

                          <div className="block 2xl:flex flex-col 2xl:flex-row 2xl:items-center w-full space-y-2 2xl:space-y-0 2xl:space-x-2 mt-1">
                            <div className="inline-flex flex-row items-center space-x-2 px-3 py-1 text-yellow bg-oxford-blue rounded-full">
                              {Coins[item.attributes.coin]}{' '}
                              <span className="text-xs">
                                {CoinLabel[item.attributes.coin]}
                              </span>
                            </div>
                            <br className="block 2xl:hidden" />
                            <div className="inline-flex flex-row items-center space-x-2 px-3 py-1 text-yellow bg-oxford-blue rounded-full">
                              {Behaviors[item.attributes.behavior]}{' '}
                              <span className="text-xs">
                                {BehaviorLabel[item.attributes.behavior]}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-gray-400 text-xs leading-none flex justify-end w-full lg:w-auto my-3 lg:my-0">
                        <span>
                          {format(new Date(item.time), 'dd MMM yyyy | HH:mm')}
                        </span>
                      </div>
                    </div>
                  </div>

                  <hr className="border-prussian-blue" />

                  {!!item.attributes.message && (
                    <>
                      <div className="p-2 flex-1 flex-col">
                        <div className="flex justify-between mb-3">
                          <p className="text-electric-blue font-thin">
                            Reconhecimento enviado:
                          </p>
                          {canEdit(item) && (
                            <button
                              onClick={() => {
                                activeModal({
                                  id: item._id,
                                  message: item.attributes.message,
                                });
                              }}
                              className={`flex items-center cursor-pointer text-electric-blue`}
                            >
                              <FiEdit3 size={20} className="mr-2" /> Editar
                            </button>
                          )}
                        </div>
                        <div
                          className="text-alice-blue"
                          dangerouslySetInnerHTML={{
                            __html: item.attributes.message,
                          }}
                        />
                      </div>

                      {!!getComment(item) && (
                        <div className="p-2 flex-1 flex-col">
                          <p className="text-electric-blue font-thin">
                            Comentário:
                          </p>
                          <div
                            className="text-alice-blue"
                            dangerouslySetInnerHTML={{
                              __html: getComment(item).message,
                            }}
                          ></div>
                        </div>
                      )}
                    </>
                  )}

                  {!!hasLike(item) && (
                    <div className="flex justify-end space-x-3">
                      <button
                        className={`flex items-center uppercase cursor-default text-electric-blue`}
                      >
                        <FaHeart size={20} className="mr-2" />
                        Curtido
                      </button>
                    </div>
                  )}
                </div>
              ))}

            {!list?.length && (
              <>
                <p className="text-white text-base text-center font-light leading-none block py-6">
                  Nenhum reconhecimento enviado até o momento.
                </p>
              </>
            )}

            {page + 1 < totalPage && (
              <div className="w-full flex justify-center">
                <Button
                  disabled={load}
                  className="px-4 py-2 mx-auto"
                  type="button"
                  onClick={() => setPage(page + 1)}
                >
                  {load ? 'Carregando...' : 'Carregar mais'}
                </Button>
              </div>
            )}
          </div>
        )}
      </Box>
    </>
  );
}
