/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { Link, Outlet } from 'react-router-dom';
import { AuthContext } from 'context/AuthContext';

import ArticlePlaceholder from 'components/ui/article-placeholder';

import { getExperienceCatalog } from 'services/store.service';
import { AiFillLeftCircle } from 'react-icons/ai';

const bbConsorcios = require("assets/img/logo-bb-consorcios.png");
const bbSeguros = require("assets/img/logo-bb-seguros.png");
const visa = require("assets/img/logo-visa.png");

export function Catalog() {
  const { user } = useContext(AuthContext);
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<any[]>();

  function handleClick(good: any) {
    // if (!good || !hasPermission(good)) return;
    // navigate('/experiencias/' + good._id);
    window.open(good.url, '_blank');
  }

  // function hasPermission(good: any) {
  //   // return user?.extra.type === 'member' || good.extra.public
  //   return true;
  // }

  useEffect(() => {
    if (!user) return;
    setLoading(true);
    async function loadData() {
      const data = await getExperienceCatalog();
      // const items = data.filter((item: any) => {
      //   return item.catalogId === 'DtcL7Ty';
      // });
      setData(data);
      setLoading(false);
    }

    loadData();
  }, [user]);

  if (!user) return null;

  return (
    <>
      <div className="mx-auto container  p-4 lg:p-10">
        <div className="flex flex-row items-center space-x-5 lg:space-x-10 mb-10">
          <div>
            <Link to="../">
              <AiFillLeftCircle size={48} className="text-yellow" />
            </Link>
          </div>
          <h1 className="text-lg lg:text-3xl bg-blue-dark py-1 px-6 rounded-full border border-yellow">
            Catálogo de Experiências
          </h1>
        </div>

        <div className="border border-yellow p-3 rounded-xl mb-6 text-center md:text-left space-y-5 md:space-y-0 flex flex-col md:flex-row items-center justify-between">
          <div className='2xl:px-5 flex-1'>
          <p className="text-lg text-yellow leading-tight">
            Em breve vocês poderão trocar os seus pontos por produtos Livelo. Continuem participando!
          </p>
          <p>E tem mais! Os participantes do game terão ainda desconto de 30% na troca pelos produtos abaixo:</p>
          </div>
          <div className='w-full md:w-auto flex flex-col items-center md:items-start bg-black bg-opacity-50 rounded-xl space-y-3 py-3 px-5'>
            <b className='text-yellow leading-none'>Apoio:</b>
          <div className='w-full  flex flex-row justify-between space-x-5 leading-tight'>
            <img className='h-10 md:h-20' src={bbSeguros} alt="BB Seguros" />
            <img className='h-10 md:h-20' src={bbConsorcios} alt="BB Consórcios" />
            <img className='h-10 md:h-20' src={visa} alt="Visa" />
          </div>
          </div>
        </div>

        <div>
          {/* <Link to="./teste">ABRIR TESTE</Link> */}

          {(loading || !data) && (
            <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-5 gap-3">
              <ArticlePlaceholder />
              <ArticlePlaceholder />
              <ArticlePlaceholder />
              <ArticlePlaceholder />
              <ArticlePlaceholder />
              <ArticlePlaceholder />
              <ArticlePlaceholder />
              <ArticlePlaceholder />
              <ArticlePlaceholder />
              <ArticlePlaceholder />
            </div>
          )}

          {!loading && data && data.length > 0 && (
            <div className="grid grid-cols-1 md:grid-cols-3 2xl:grid-cols-5 gap-5">
              {data.map((good: any) => (
                <div
                  key={good._id}
                  className={`cursor-pointer flex flex-col justify-start items-center bg-black bg-opacity-60 rounded-xl pb-4`}
                  onClick={() => {
                    handleClick(good);
                  }}
                >
                  <div className="flex-0 h-48 w-full bg-gray-300 ring-4 ring-white rounded-xl overflow-hidden">
                    {good.image && (
                      <img src={good.image?.medium?.url} alt="item" className="w-full h-full object-cover" />
                    )}
                  </div>
                  <p className="flex-1 flex items-center justify-center text-center mt-5 mb-3 px-3 text-white leading-tight">
                    {good.title}
                  </p>
                  <div className="flex flex-row text-md w-full justify-center space-x-3">
                    <div className="bg-yellow text-blue-dark text-center rounded-xl leading-none p-2">
                      <b>Pontos Livelo:</b>
                      <p>{good.price}</p>
                    </div>
                    {good.price_club ? (
                      <div className="bg-purple text-white text-center rounded-xl leading-none p-2">
                        <b>Clube Livelo:</b>
                        <p>{good.price_club}</p>
                      </div>
                    ) : null}
                  </div>
                  {/* <div>RESGATAR</div> */}
                  {/* {!isItemPurchased(good) && (
                    <div className="flex items-center justify-center text-center font-bold text-2xl">
                      {!hasPermission(good) ? (
                        <AiFillLock size={24} className="mr-2" />
                      ) : getItemRequirements(good) > 0 ? (
                        <div className="flex flex-row items-center">
                          {getItemRequirements(good)}
                          <RiCoinsLine size={20} className="text-yellow-500 ml-1" />
                        </div>
                      ) : (
                        <div className="text-yellow-500">RESGATAR</div>
                      )}
                    </div>
                  )}
                  {isItemPurchased(good) && (
                    <p className="flex items-center justify-center text-center text-sm text-green-500 font-bold">
                      ITEM RESGATADO
                    </p>
                  )} */}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <Outlet />
    </>
  );
}
