import React from 'react'

const ArticlePlaceholder: React.FC = () => {
	return (
		<div className='space-y-2 w-full h-48 bg-white bg-opacity-70 p-4 rounded-lg border border-gray-300 animate-pulse'>
			<div className='rounded bg-gray-300 p-2 mb-2 w-3/4 animate-pulse'></div>
			<div className='rounded bg-gray-300 p-1 w-full animate-pulse'></div>
			<div className='rounded bg-gray-300 p-1 w-full animate-pulse'></div>
			<div className='rounded bg-gray-300 p-1 w-full animate-pulse'></div>
		</div>
	)
}

export default ArticlePlaceholder
