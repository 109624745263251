import Box from 'components/ui/box';
import Button from 'components/ui/button';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import { TbMailFast } from 'react-icons/tb';
import { FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { getEquieGestor, getPlayerInfo } from 'services/dashboard.service';
import { navLinks } from 'types/navigation';
import MegaPhone from 'assets/img/megaphone-icon.svg';

export default function Nav() {
  const { user } = useAuth();
  const [isGestor, setIsGestor] = useState(false);
  const [hasTeam, setHasTeam] = useState(false);

  const URLs: any = [
    {
      title: 'Galeria de reconhecimento',
      url: '/galeria',
    },
    hasTeam && {
      title: 'Minha Equipe',
      url: '/quadro-equipe',
    },
    {
      title: 'Extrato de pontos',
      url: '/extrato',
    },
    // {
    //   title: 'Catálogo de experiências',
    //   url: '/experiencias',
    // },
    {
      title: 'Regras do jogo',
      url: '/regulamento',
    },
    {
      title: 'Nosso Manifesto',
      url: '/manifesto',
    },
  ].filter(Boolean);

  useEffect(() => {
    async function getUserInfo() {
      try {
        const playerInfo = await getPlayerInfo(user?._id);

        if (playerInfo) {
          setIsGestor(
            playerInfo.tipo_ro === 'GERENCIAL' ||
              playerInfo.funcao === 'DIRETOR'
          );
        }
      } catch (e) {
        console.log(e);
      }
    }

    async function loadTeam() {
      if (user?.extra?.id) {
        const data = await getEquieGestor(user.extra.id, '', 0, 1);

        if (data?.result?.length) setHasTeam(true);
      }
    }

    if (user) {
      getUserInfo();
      loadTeam();
    }
  }, [user]);

  return (
    <div id="nav">
      <ul className="space-y-6">
        {/* <li className="pb-4">
          <Link
            to="/convidar"
            className="font-normal leading-none text-lg hover:text-yellow focus:text-yellow active:text-yellow text-yellow flex items-center justify-center space-x-2"
          >
            <TbMailFast className="text-[2rem]" />{' '}
            <span className="font-normal leading-none text-lg">
              Convide seus amigos!
            </span>
          </Link>
        </li> */}

        {URLs.map((item: any) => (
          <li key={item.url}>
            <Link
              to={item.url}
              className="flex items-center justify-between text-base uppercase font-normal text-[#C2D2FF]"
            >
              {item.title} <FiChevronRight className="text-xl" />
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
