import React from 'react';

const ArticlePlaceholderSmall: React.FC = () => {
  return (
    <div className="space-y-2 w-[70%] h-7 bg-white bg-opacity-70 p-4 rounded-lg border border-gray-300 animate-pulse">
      <div className="rounded bg-gray-300 p-1 w-full animate-pulse"></div>
    </div>
  );
};

export default ArticlePlaceholderSmall;
