import { api } from './api';

export async function checkNotifications() {
  const { data } = await api.get(
    `notification?player=me&scope=private&published_min=-30d&max_results=10`
  );
  return data;
}

export async function getNotifications() {
  const { data } = await api.get(
    `notification?player=me&scope=private&published_min=-30d&max_results=100`
  );
  return data;
}

export async function clearNotifications() {
  const { data } = await api.get(
    'notification?player=me&scope=private&max_results=1&delete=true'
  );
  return data;
}

export async function getMyNotifications(id: string) {
  const { data } = await api.get(
    `database/notification?q=player.id:'${id}'&max_results=100`
  );
  return data;
}

export async function deleteNotification(id: string) {
  const { data } = await api.delete(`database/notification?q=_id:'${id}'`);
  return data;
}

export async function getAllNotifications(cad_missao: number, date?: any) {
  const payload = [
    {
      $match: {
        $or: [
          {
            cad_missao: cad_missao?.toString(),
          },
          {
            cad_missao: {
              $exists: false,
            },
          },
        ],
      },
    },
    { $sort: { created: 1 } },
  ] as any;

  if (date) {
    payload.unshift({
      $match: {
        created: {
          $gt: {
            $date: date,
          },
        },
      },
    });
  }

  const { data } = await api.post(
    `database/notifications__c/aggregate`,
    payload
  );
  if (!Array.isArray(data)) {
    return null;
  } else {
    return data;
  }
}
