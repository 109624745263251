import { api } from './api';

export interface Created {
  $date: Date;
}

export interface Updated {
  $date: Date;
}

export interface IManifest {
  _id: string;
  created: Created;
  text: string;
  updated: Updated;
}

export async function getManifest() {
  const { data } = await api.get<IManifest[]>(`database/manifest__c`);
  if (Array.isArray(data)) {
    return data[0] as IManifest;
  } else {
    return null;
  }
}
