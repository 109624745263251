import Tippy from '@tippyjs/react';
import {
  Colaboracao,
  FocoNoCliente,
  Inovacao,
  MandouBem,
  Resolutividade,
  Uau,
  Valeu,
} from 'components/icons';
import { format } from 'date-fns';

import { UserAvatar } from 'components/user-avatar';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import { BiInfoCircle } from 'react-icons/bi';
import { FaHeart } from 'react-icons/fa';
import { MdModeComment } from 'react-icons/md';
import { registerInteraction } from 'services/interactions.service';
import { useModal } from 'context/ModalContext';
import { getKPIHistoryPag } from 'services/dashboard.service';
import Box from 'components/ui/box';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import Button from 'components/ui/button';

const iconSize = 20;
const Coins: any = {
  uau: <Uau yellow size={iconSize} />,
  mandou: <MandouBem yellow size={iconSize} />,
  valeu: <Valeu yellow size={iconSize} />,
};

const CoinLabel: any = {
  uau: '#UAU',
  mandou: '#MANDOUBEM',
  valeu: '#VALEU',
};

const Behaviors: any = {
  colaboracao: <Colaboracao size={iconSize} />,
  inovacao: <Inovacao size={iconSize} />,
  foco: <FocoNoCliente size={iconSize} />,
  resolutividade: <Resolutividade size={iconSize} />,
};

const BehaviorLabel: any = {
  colaboracao: 'Colaboração',
  inovacao: 'Inovação',
  foco: 'Foco no Cliente',
  resolutividade: 'Protagonismo',
};

// const likeTippy = function (data: any) {
//   return (
//     <div>
//       <p>
//         Curtidas:{' '}
//         {data?.interaction?.length
//           ? data.interaction?.filter((item: any) => item.like)?.length
//           : 0}
//       </p>

//       {data.interaction
//         ?.filter((item: any) => item.like)
//         .map((item: any) => (
//           <p key={item._id}>{item.senderName}</p>
//         ))}
//     </div>
//   );
// };

interface Props {
  activeModal: (data: any) => void;
  message: { message: string; id: string; item: any };
}

export default function RecognitionReceived(data: Props) {
  const { activeModal, message } = data;
  const { user, currentCycle } = useAuth();
  const [list, setList] = useState<any[]>([]);
  const { showModal } = useModal();
  const [loadingList, setLoadingList] = useState(true);
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [load, setLoad] = useState(false);

  function hasLike(item: any) {
    return !!item?.interaction.find(
      (i: any) => i.sender === user?._id && !!i.like
    );
  }

  async function sendInteraction(item: any, type: string, message?: string) {
    const index = list.findIndex((i: any) => i._id === item._id);

    let payload: any;

    if (!item.interaction?.length) {
      payload = {
        item: item.action_id as string,
        player: item.sender as string,
        sender: user?._id as string,
        senderName: user?.name as string,
        like:
          type === 'like' && hasLike(item)
            ? false
            : type === 'like' && !hasLike(item)
            ? true
            : hasLike(item),
        message:
          type === 'message' &&
          typeof message === 'string' &&
          message.trim().length
            ? message.trim()
            : undefined,
        created: {
          $date: new Date().getTime(),
        },
      };
    } else {
      if (type === 'like') {
        payload = { ...item.interaction[0], like: !hasLike(item) };
      } else if (type === 'message') {
        payload = { ...item.interaction[0], message };
      }
    }

    const data = await registerInteraction(payload);
    const newList = [...list];

    if (!!item.interaction?.length) {
      newList[index].interaction[0] = data;
    } else {
      newList[index].interaction.push(data);
    }

    setList(newList);
  }

  function getComment(item: any) {
    const interaction = item.interaction?.find(
      (e: any) => !!e.message?.trim().length
    );

    if (interaction) {
      return interaction;
    } else {
      return null;
    }
  }

  function handleDetail(item: any) {
    showModal('extrato-detalhe', item);
  }

  useEffect(() => {
    async function loadData() {
      const rangeItem = page ? page * 5 : 0;

      if (page) setLoad(true);

      const data = await getKPIHistoryPag(
        user?._id,
        rangeItem,
        5,
        currentCycle?.date_start,
        currentCycle?.date_finish
      );
      setList(page ? [...list, ...data.result] : data.result);
      setTotal(data.range.total);
      setTotalPage(data.range.pages);

      setLoadingList(false);
      setLoad(false);
    }

    if (!!user && !!currentCycle) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCycle, user, page]);

  useEffect(() => {
    if (message?.message && message?.id === 'received') {
      sendInteraction(message.item, 'message', message.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message]);

  return (
    <>
      <h3 className="text-lg lg:text-2xl mb-3">
        Reconhecimentos recebidos
        <span className="text-xl lg:text-2xl font-bold text-yellow ">
          {total ? ` (${total})` : null}
        </span>
      </h3>

      <Box className="relative bg-oxford-blue border-electric-blue lg:p-6 flex flex-col justify-center items-center border-opacity-100 bg-opacity-100">
        {loadingList && (
          <div className="space-y-2 w-full">
            <ArticlePlaceholder />
            <ArticlePlaceholder />
          </div>
        )}

        {!loadingList && (
          <div className="space-y-2 w-full">
            {!!list?.length &&
              list?.map((item: any) => (
                <div
                  className="bg-maastricht-blue-3 rounded-xl text-gray-300 space-y-2 py-6 px-6 lg:px-8"
                  key={item?._id}
                >
                  <div className="flex flex-col lg:flex-col items-start lg:items-start mb-5">
                    <div className="flex-1 flex flex-col-reverse lg:flex-row items-start space-x-2 leading-tight w-full">
                      <div className="flex flex-1 w-full items-start">
                        <div className="mr-2">
                          <UserAvatar data={item.sender_avatar} />
                        </div>
                        <div className="flex-1 w-full block">
                          <Tippy
                            content={`${item.sender_name} (${item.sender_nome_guerra})`}
                          >
                            <div className="flex items-center">
                              <p className="flex flex-row items-center text-sm lg:text-base text-alice-blue font-semibold hover:cursor-pointer hover:underline">
                                {item.sender_nome_guerra ||
                                  item.sender_name.split(' ')[0]}{' '}
                                <span className="font-thin ml-1">
                                  ({item.sender})
                                </span>
                                <BiInfoCircle className="hidden lg:block relative ml-1" />
                              </p>
                              <span className="hidden lg:block whitespace-nowrap font-thin text-sm lg:text-base text-alice-blue ml-1">
                                te enviou:
                              </span>
                              <span className="lg:hidden font-thin text-sm lg:text-base text-alice-blue ml-1">
                                enviou:
                              </span>
                            </div>
                          </Tippy>

                          <div className="block 2xl:flex flex-col 2xl:flex-row 2xl:items-center w-full space-y-2 2xl:space-y-0 2xl:space-x-2 mt-1">
                            <div className="inline-flex flex-row items-center space-x-2 px-3 py-1 text-yellow bg-oxford-blue rounded-full">
                              {Coins[item.coin]}{' '}
                              <span className="text-xs">
                                {CoinLabel[item.coin]}
                              </span>
                            </div>
                            <br className="block 2xl:hidden" />
                            <div className="inline-flex flex-row items-center space-x-2 px-3 py-1 text-yellow bg-oxford-blue rounded-full">
                              {Behaviors[item.behavior]}{' '}
                              <span className="text-xs">
                                {BehaviorLabel[item.behavior]}
                              </span>
                            </div>
                            <br className="block 2xl:hidden" />
                            <div className="inline-flex items-center space-x-2">
                              <div
                                onClick={() => handleDetail(item)}
                                className="cursor-pointer hover:ring-2 ring-white whitespace-nowrap 2xl:mt-0 flex-1 2xl:flex-none inline-flex flex-row items-center space-x-2 px-2 bg-oxford-blue py-1 text-electric-blue rounded-full"
                              >
                                <span>total = {item.total} pts</span>{' '}
                              </div>

                              <div>
                                <BiInfoCircle size={20} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="text-gray-400 text-xs leading-none flex justify-end w-full my-3 lg:my-0">
                        <span>
                          {format(new Date(item.time), 'dd MMM yyyy | HH:mm')}
                        </span>
                      </div>
                    </div>

                    {/* <div className="flex flex-col lg:flex-row flex-wrap lg:items-center w-full space-y-3 lg:space-y-0 lg:space-x-2 lg:ml-14 mt-4 lg:-mt-4">
                      <div className="w-1/2 lg:w-auto pr-1 lg:pr-0">
                        <div className="flex flex-row items-center space-x-2 px-2 text-yellow py-1 bg-oxford-blue rounded-full">
                          {Coins[item.coin]} <span className="text-xs">{CoinLabel[item.coin]}</span>
                        </div>
                      </div>
                      <div className="w-1/2 lg:w-auto lg:pl-0">
                        <div className="flex flex-row items-center space-x-2 px-2 bg-oxford-blue py-1 text-yellow rounded-full">
                          {Behaviors[item.behavior]} <span className="text-xs">{BehaviorLabel[item.behavior]}</span>
                        </div>
                      </div>
                      <div className="w-1/2 flex items-center space-x-2">
                        <div onClick={() => handleDetail(item)} className="cursor-pointer hover:ring-2 ring-white whitespace-nowrap lg:mt-0 flex-1 lg:flex-none flex flex-row items-center space-x-2 px-2 bg-oxford-blue py-1 text-electric-blue rounded-full">
                          <span>total = {item.total} pts</span>{' '}
                        </div>

                        <div>
                          <BiInfoCircle size={20} />
                        </div>
                      </div>
                    </div> */}
                  </div>

                  <hr className="border-prussian-blue" />

                  {!!item.message && (
                    <>
                      <div className="p-2 flex-1 flex-col">
                        <p className="text-electric-blue font-thin">
                          Reconhecimento recebido:
                        </p>
                        <div
                          className="text-alice-blue"
                          dangerouslySetInnerHTML={{ __html: item.message }}
                        />
                      </div>

                      {!!getComment(item) && (
                        <div className="p-2 flex-1 flex-col">
                          <p className="text-electric-blue font-thin">
                            Comentário:
                          </p>
                          <div
                            className="text-alice-blue"
                            dangerouslySetInnerHTML={{
                              __html: getComment(item).message,
                            }}
                          ></div>
                        </div>
                      )}
                    </>
                  )}

                  <div className="flex justify-end space-x-6">
                    <button
                      className={`flex items-center uppercase ${
                        hasLike(item) ? 'text-electric-blue' : 'text-white'
                      }`}
                      onClick={() => sendInteraction(item, 'like')}
                    >
                      <FaHeart size={20} className="mr-2" />
                      {hasLike(item) ? 'Curtido' : 'Curtir'}
                    </button>

                    {!getComment(item) && (
                      <button
                        className={`flex items-center uppercase text-white`}
                        onClick={() => activeModal(item)}
                      >
                        <MdModeComment size={20} className="mr-2" />
                        Comentar
                      </button>
                    )}
                  </div>
                </div>
              ))}

            {!list?.length && (
              <>
                <p className="text-white text-base text-center font-light leading-none block py-6">
                  Nenhum reconhecimento recebido até o momento.
                </p>
              </>
            )}

            {page + 1 < totalPage && (
              <div className="w-full flex justify-center">
                <Button
                  disabled={load}
                  className="px-4 py-2 mx-auto"
                  type="button"
                  onClick={() => setPage(page + 1)}
                >
                  {load ? 'Carregando...' : 'Carregar mais'}
                </Button>
              </div>
            )}
          </div>
        )}
      </Box>
    </>
  );
}
