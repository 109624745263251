import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import { GroupDetailInterface, UserGroupInterface } from 'types/user';
import { ModalContext } from 'context/ModalContext';
import Box from 'components/ui/box';
import { useAuth } from 'context/AuthContext';
import { getUserGroup } from 'services/dashboard.service';

import { IoIosClose } from 'react-icons/io';
import Button from 'components/ui/button';

type Props = {
  group: {
    nome: string | undefined;
    total: number | undefined;
  };
};

export function GroupDetailModal({ group }: Props) {
  const { user } = useAuth();
  const { openModal, closeModal } = useContext(ModalContext);

  const modalID = 'group-detail';

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [groupDetail, setGroupDetail] = useState<GroupDetailInterface[]>();

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  function mountGroupDetail(groups: UserGroupInterface[]) {
    const table: any = [];

    if (groups?.length) {
      groups.forEach((group: UserGroupInterface, index) => {
        const prefixoTable = table.find(
          (x: any) => x.prefixo === group.prefixo
        );

        if (prefixoTable) {
          const ro = prefixoTable.ROs.find((x: any) => x.ro === group.ro);

          if (ro) {
            if (
              !ro.funcoes.find((x: any) => x.cod_funcao === group.cod_funcao)
            ) {
              ro.funcoes.push({
                funcao: group.funcao,
                cod_funcao: group.cod_funcao,
              });
            }
          } else {
            prefixoTable.ROs.push({
              ro: group.ro,
              funcoes: [
                {
                  funcao: group.funcao,
                  cod_funcao: group.cod_funcao,
                },
              ],
            });
          }
        } else {
          table.push({
            nome_dependencia: group.nome_dependencia,
            prefixo: group.prefixo,
            ROs: [
              {
                ro: group.ro,
                funcoes: [
                  {
                    funcao: group.funcao,
                    cod_funcao: group.cod_funcao,
                  },
                ],
              },
            ],
          });
        }
      });

      setGroupDetail(table);
    }
  }

  useEffect(() => {
    setIsOpen(openModal === modalID);
  }, [openModal]);

  useEffect(() => {
    async function loadUserGroup() {
      if (user?._id) {
        const data: UserGroupInterface[] = await getUserGroup(user._id);
        mountGroupDetail(data);
      }
    }

    if (!user) return;

    loadUserGroup();
  }, [user]);

  if (!groupDetail?.length) return <></>;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 bg-black bg-opacity-80 flex flex-col items-center   w-screen h-screen p-6 pt-[10vh] overflow-y-auto"
        contentElement={() => (
          <Box className="w-[90%] lg:w-auto lg:max-w-[800px] ">
            <Button
              color="primary"
              className="absolute -right-5 -top-5  rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
              onClick={() => closeModal()}
            >
              <IoIosClose color="white" size={40} />
            </Button>

            <div className="flex justify-center text-xl text-yellow">
              <h1>Grupo {group?.nome}</h1>
            </div>

            <div className="overflow-x-auto">
              <div className="border-electric-blue border rounded-xl w-[700px] lg:w-auto">
                <div className="flex font-bold">
                  <div className="w-36 flex items-center p-2 pl-5">
                    Comissão
                  </div>
                  <div className="w-48 border-electric-blue border-l flex items-center p-2 pl-5">
                    Nome da comissão
                  </div>
                  <div className="w-20 border-electric-blue border-l flex items-center p-2 pl-5">
                    RO
                  </div>
                  <div className="w-24 border-electric-blue border-l flex items-center p-2 pl-5">
                    Prefixo
                  </div>
                  <div className="w-48 border-electric-blue border-l flex items-center p-2 pl-5">
                    Nome da dependência
                  </div>
                </div>
                {groupDetail.map((group, index) => (
                  <div
                    className={`flex flex-row-reverse border-electric-blue border-t font-thin`}
                    key={group.nome_dependencia}
                  >
                    <div className="nome-dependencia border-electric-blue border-l flex items-center justify-center w-48 p-2">
                      {group.nome_dependencia}{' '}
                    </div>
                    <div className="prefixo border-electric-blue border-l flex justify-center items-center w-24 p-2">
                      {group.prefixo}
                    </div>
                    <div className="ros flex flex-col items-start flex-1">
                      {group.ROs.map((ro) => (
                        <div
                          className="ro flex flex-row-reverse h-full"
                          key={ro.ro}
                        >
                          <div className="sigla border-electric-blue border-l flex justify-center items-center w-20 p-2">
                            {ro.ro}
                          </div>
                          <div className="flex flex-col h-full">
                            {ro.funcoes.map((funcao, ind) => (
                              <div
                                className={`flex h-full border-electric-blue  ${
                                  ind > 0 && 'border-t'
                                }`}
                                key={funcao.cod_funcao}
                              >
                                <div className="w-36 flex justify-center items-center p-2">
                                  {funcao.cod_funcao}
                                </div>
                                <div className="border-electric-blue border-l w-48 justify-center items-center p-2">
                                  {funcao.funcao}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <p className="mt-5 font-thin">
              Total de funcionários do grupo:{' '}
              <span className="text-yellow">{group?.total} pessoas.</span>
            </p>
            {/* <div className="text-center mb-3">
              <h1 className="text-yellow text-2xl mb-2">Meus Pontos</h1>
              <p>Este é o saldo total de pontos acumulados como resultado do reconhecimento de seus colegas, pontos das entregas feitas na Realização Destaque, dentre outras atividades.</p>
            </div>
            <Button onClick={() => closeModal()}>FECHAR</Button> */}
          </Box>
        )}
      />
    </>
  );
}
