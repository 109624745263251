import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { AiFillLeftCircle } from 'react-icons/ai';
import Box from 'components/ui/box';
import { useAuth } from 'context/AuthContext';
import { useEffect, useState } from 'react';
import { getResultadosCultura } from 'services/dashboard.service';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import Avatar from 'funifier-nice-avatar';
import Button from 'components/ui/button';
import { SeloColaboracao, SeloFocoNoCliente, SeloInovacao, SeloResolutividade } from 'components/icons';
import { GetBehaviorDescription, GetBehaviorLabel, GetBehaviorText } from 'utils/strings';

const release = true;

const behaviors = [
  {
    id: 'colaboracao',
    icon: <SeloColaboracao size={'100%'} />,
  },
  {
    id: 'inovacao',
    icon: <SeloInovacao size={'100%'} />,
  },
  {
    id: 'foco',
    icon: <SeloFocoNoCliente size={'100%'} />,
  },
  {
    id: 'resolutividade',
    icon: <SeloResolutividade size={'100%'} />,
  },
];
export default function DestaquesCultura() {
  const [list, setList] = useState<unknown[]>();
  const [loadingList, setLoadingList] = useState(true);

  const navigate = useNavigate();

  const params = useParams();

  const { user } = useAuth();

  function handleDetail(item: any) {
    navigate(item);
  }

  function getDestaques(behavior: string) {
    if (!list) return;
    return list.filter((item: any) => item.behavior === behavior);
  }

  useEffect(() => {
    async function loadData() {
      try {
        const data = await getResultadosCultura();
        setList(data);
        setLoadingList(false);
      } catch (e) {}
    }

    if (user) {
      loadData();
    }
  }, [user]);

  return (
    <>
      {!params.id ? (
        <div className="mx-auto container p-3 lg:p-10">
          <div className="flex flex-ro items-center space-x-3 lg:space-x-10 mb-3 lg:mb-10">
            <div>
              <Link to="../">
                <AiFillLeftCircle size={48} className="text-yellow" />
              </Link>
            </div>
            {/* <div className="w-36 hidden md:block">
          <img src={Logo} alt="#JogaJunto BB" />
        </div> */}
            <h1 className="text-xl lg:text-3xl bg-blue-dark py-1 px-6 rounded-full border border-yellow">
              Destaques da Cultura BB
            </h1>
          </div>

          {!release ? (
            <Box>
              <div className="space-y-5 lg:p-3">
                <div className="text-center">
                  <h3 className="text-xl">Em breve</h3>
                </div>
              </div>
            </Box>
          ) : null}

          {release ? (
            <Box>
              <div className="space-y-5 lg:p-3">
                {loadingList && (
                  <div className="space-y-2">
                    <ArticlePlaceholder />
                    <ArticlePlaceholder />
                  </div>
                )}

                {!loadingList ? (
                  <div className="space-y-2">
                    <div className="flex flex-col space-y-5">
                      {behaviors.map((behavior: any) => (
                        <div key={behavior.id} className="space-y-5">
                          <div className="flex flex-row items-center bg-black bg-opacity-60 p-5 space-x-5 rounded-xl">
                            {/* <div className='w-[180px]'>{behavior.icon}</div> */}
                            <div className='flex-1'>
                              <div
                                className="text-2xl text-yellow"
                                dangerouslySetInnerHTML={{ __html: GetBehaviorLabel(behavior.id) }}
                              ></div>

                              <div
                                className="text-lg text-blue-light"
                                dangerouslySetInnerHTML={{ __html: GetBehaviorDescription(behavior.id) }}
                              ></div>

                              <div dangerouslySetInnerHTML={{ __html: GetBehaviorText(behavior.id) }}></div>
                            </div>
                          </div>

                          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
                            {getDestaques(behavior.id)?.map((item: any) => (
                              <div
                                className="bg-black bg-opacity-60 rounded-xl text-gray-300 space-y-2 p-4 cursor-pointer hover:ring-2"
                                key={item?._id}
                                onClick={() => handleDetail(`${behavior.id}/${item.matricula}`)}
                              >
                                <div className="flex flex-col items-center justify-around md:h-52 space-y-2 text-center">
                                  <div className="w-28 h-28 overflow-hidden rounded-full bg-purple text-3xl font-bold flex flex-row items-center justify-center">
                                    {!!item.avatar_type &&
                                      (item.image ? (
                                        <img src={item.image} alt={item.nome} className="w-full h-full object-cover" />
                                      ) : (
                                        item.nome[0]
                                      ))}
                                    {!item.avatar_type &&
                                      (!!item.avatar ? (
                                        <Avatar className="w-full h-full" {...item.avatar} />
                                      ) : (
                                        item.nome[0]
                                      ))}
                                  </div>
                                  <div>
                                    <div className="text-yellow">
                                      <b>{item.matricula}</b>
                                    </div>
                                    <div>{item.nome}</div>
                                  </div>
                                  <Button className="bg-opacity-20">ver detalhes</Button>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : null}
              </div>
            </Box>
          ) : null}
        </div>
      ) : null}
      <Outlet />
    </>
  );
}
