import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import Button from 'components/ui/button';
import { useModal } from 'context/ModalContext';
import { GetBehaviorDescription, GetBehaviorLabel, GetBehaviorText } from 'utils/strings';
import { Colaboracao, Inovacao, FocoNoCliente, Resolutividade } from 'components/icons';

const iconSize = 120;

const Behaviors: any = {
  colaboracao: <Colaboracao size={iconSize} />,
  inovacao: <Inovacao size={iconSize} />,
  foco: <FocoNoCliente size={iconSize} />,
  resolutividade: <Resolutividade size={iconSize} />,
};

export function ComportamentoHelpModal(props: any) {
  // const modalID = "achievement";

  const modalID = 'comportamento-help';

  const [modalIsOpen, setIsOpen] = useState(false);

  const { openModal, modalData, closeModal } = useModal();

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    setIsOpen(openModal === modalID);
  }, [openModal]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-40"
        overlayClassName="fixed z-40 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <>
          {/* {modalData === 'inovacao' && <Tesouro id="mandou" x={80} y={6} />} */}
          <div>
            <div className="max-w-[800px] bg-blue-dark text-white rounded-xl p-10 space-y-2 flex flex-col items-center flex-none mx-5">
              <div className="text-center mb-3">
                <h1 className="text-yellow text-2xl mb-2">Saiba mais sobre {GetBehaviorLabel(modalData)}</h1>
              </div>

              {Behaviors[modalData]}

              <div className="max-h-[32vh] overflow-y-auto space-y-4">
                <div
                  className="text-lg text-blue-light"
                  dangerouslySetInnerHTML={{ __html: GetBehaviorDescription(modalData) }}
                ></div>

                <div dangerouslySetInnerHTML={{ __html: GetBehaviorText(modalData) }}></div>
              </div>
              <div className="pt-3">
                <Button onClick={() => closeModal()}>FECHAR</Button>
              </div>
            </div>
          </div>
          </>
        )}
      />
    </>
  );
}
