import { useEffect, useState } from 'react';

require('./styles.scss');

export function BackToTop() {
  const [showButtonm, setShowButton] = useState<boolean>(false);

  useEffect(() => {
    window.onscroll = function () {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };
  }, []);

  function scrollTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  return (
    <>
      <div id="back-to-top">
        {showButtonm && (
          <button className="flex" onClick={scrollTop}>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11 6.5L6 1.5L1 6.5"
                stroke="#DCE3F8"
                strokeWidth="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            {'  '} VOLTAR PARA O TOPO
          </button>
        )}
      </div>
    </>
  );
}
