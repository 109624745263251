import { AiOutlineUser } from 'react-icons/ai';
import Avatar from 'funifier-nice-avatar';

interface UserAvatarProps {
  data: {
    avatar_type?: number;
    avatar?: any;
    image?: any;
  };
  responsive?: boolean;
}
export function UserAvatar(props: UserAvatarProps) {
  try {
    const { avatar_type, avatar, image } = props.data;
    const { responsive } = props;

    const size = responsive ? 'full' : '[44px]';

    if (!avatar_type && avatar) {
      return (
        <div className={`cursor-pointer hover:animate-zoom hover:drop-shadow-2xl hover:ring-1 hover:ring-yellow rounded-full w-${size} h-${size}`}>
          <Avatar className={`flex-0 w-${size} h-${size}`} {...avatar} />
        </div>
      );
    } else if (image) {
      return <img src={image.small.url} alt="" className={`flex-0 w-${size} h-${size} cursor-pointer hover:animate-zoom hover:drop-shadow-2xl hover:ring-1 hover:ring-yellow rounded-full`} />;
    }

    return (
      <div className={`bg-slate-blue text-alice-blue relative flex items-end justify-center overflow-hidden flex-0 w-${size} h-${size} rounded-full`}>
        <AiOutlineUser className="w-5/6 h-5/6 block" />
      </div>
    );
  } catch (e) {
    return null;
  }
}
