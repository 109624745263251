
export const clamp = (num : number, min : number, max : number) => Math.min(Math.max(num, min), max);

export function FormatNumber(value:any){
  try{
    if(isNaN(value)) return 0;
    return Intl.NumberFormat('pt-BR').format(Math.ceil(value));
  }
  catch(e){
    return isNaN(value) ? 0 : value;
  }
}