import { api } from "./api";

export async function getTutorialById(id: string) {
  const payload = [{ $match: { _id: id } }];

  try {
    const { data } = await api.post("database/tutorial__c/aggregate", payload);
    if (!Array.isArray(data)) {
      return [];
    } else {
      return data[0];
    }
  } catch (error) {
    return error;
  }
}
