import { useEffect, useState } from 'react';
import { getRegulation, IRegulation } from 'services/regulation.service';
import { BackToTop } from '../back-to-top';
import { useLocation } from 'react-router-dom';
import Collapsible from 'react-collapsible';

require('./styles.scss');
interface Titles {
  title: string;
  id: string;
  subtitles?: {
    title: string;
    id: string;
  }[];
}

interface ProcessHtmlResult {
  html: string;
  titles: Titles[];
}

export function RegulamentoContent() {
  const [regulation, setRegulation] = useState<IRegulation>();
  const [topicsRegulation, setTopicsRegulation] = useState<any>();
  const [hash, setHash] = useState<string>('');
  const [openCollapsibles, setOpenCollapsibles] = useState<
    Record<string, boolean>
  >({});

  const location = useLocation();

  const handleTriggerClick = (id: string) => {
    setOpenCollapsibles((prevState) => ({
      [id]: !prevState[id],
    }));
  };

  useEffect(() => {
    async function loadData() {
      const data = await getRegulation();
      if (data) {
        const item = processHtml(data.regulation_text);

        setRegulation({ ...data, regulation_text: item.html });
        setTopicsRegulation(item.titles);
      }
    }

    loadData();
  }, []);

  useEffect(() => {
    setHash(location.hash);
  }, [location]);

  function changeUrl(hashItem: string) {
    window.location.hash = hashItem;
    setHash(hashItem);
  }

  function hadleTopicsMenu(item: Titles) {
    if (!!item?.subtitles?.length && item.subtitles?.length > 0) {
      return (
        <Collapsible
          handleTriggerClick={() => handleTriggerClick(item.id)}
          open={openCollapsibles[item.id]}
          onOpen={() => changeUrl(`#${item.id}`)}
          trigger={item.title}
          key={item.id}
        >
          <ul>
            {item.subtitles.map((subItem: any, index: number) => (
              <li className="mt-2 ml-4" key={subItem.id + '_' + index}>
                <a
                  className={`${
                    '#' + subItem.id === hash ? 'font-bold' : 'font-thin'
                  } hover:no-underline hover:text-white hover:font-bold active:text-white active:no-underline truncate w-full block`}
                  href={`#${subItem.id}`}
                  title={subItem.title}
                >
                  {subItem.title}
                </a>
              </li>
            ))}
          </ul>
        </Collapsible>
      );
    } else if (item.id && item.title && item.subtitles) {
      return (
        <p className="w-full m-0">
          <a
            className={`${
              '#' + item.id === hash && 'active-item'
            } menu-item truncate w-full block hover:no-underline`}
            key={item.id}
            href={`#${item.id}`}
            title={item.title}
            onClick={() => setOpenCollapsibles({})}
          >
            {item.title}
          </a>
        </p>
      );
    }
  }

  function processHtml(html: string): ProcessHtmlResult {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;

    const titleElements = tempDiv.querySelectorAll('h1, h2');

    const titles: { title: string; id: string; subtitles: any[] }[] = [];

    titleElements.forEach((titleElement, index) => {
      const titleText = titleElement.textContent;

      if (!!titleText) {
        let titleId = titleText
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .replace(/[^\w\s]/gi, '')
          .replace(/\d+/g, '')
          .trim()
          .toLowerCase()
          .replace(/\s+/g, ' ')
          .split(' ')
          .map((word, index) =>
            index === 0 ? word : word[0].toUpperCase() + word.slice(1)
          )
          .join('');

        titleElement.setAttribute('id', titleId);

        const subtitles: any = [];

        let nextElement = titleElement.nextElementSibling;

        const nextTitleElement =
          titleElements[index + 1] || tempDiv.lastElementChild;

        while (nextElement && nextElement !== nextTitleElement) {
          if (/^H[3-6]$/.test(nextElement.tagName)) {
            const subtitleText = nextElement.textContent;

            if (!!subtitleText) {
              let subtitleId = subtitleText
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '')
                .replace(/[^\w\s]/gi, '')
                .replace(/\d+/g, '')
                .trim()
                .toLowerCase()
                .replace(/\s+/g, ' ')
                .split(' ')
                .map((word, index) =>
                  index === 0 ? word : word[0].toUpperCase() + word.slice(1)
                )
                .join('');
              nextElement.setAttribute('id', subtitleId);

              subtitles.push({ title: subtitleText, id: subtitleId });
            }
          }

          nextElement = nextElement.nextElementSibling;
        }

        titles.push({ title: titleText, id: titleId, subtitles });
      }
    });

    return { html: tempDiv.innerHTML, titles };
  }

  if (!topicsRegulation && !regulation) return <h1>Carregando...</h1>;

  return (
    <>
      <div id="menu-regulamento" className="container flex">
        <div className="p-8 w-[20%] rounded-l-lg">
          <div className="content-menu">
            <h3 className="text-lg mb-3">Estrutura de tópicos</h3>
            {topicsRegulation.map((item: any) => hadleTopicsMenu(item))}
          </div>
        </div>

        <div
          id="regulamento-content"
          className="html text-lg text-white p-8 space-y-5 rounded-r-lg  w-[80%]"
        >
          {/* {regulation?.regulation.url && (
            <a
              className="text-blue-600"
              href={regulation?.regulation.url}
              target="_blank"
              rel="noreferrer"
            >
              Clique aqui para baixar o PDF
            </a>
          )} */}

          {regulation?.regulation_text && (
            <div
              dangerouslySetInnerHTML={{ __html: regulation.regulation_text }}
            ></div>
          )}
        </div>
      </div>
      <BackToTop />
    </>
  );
}
