import Button from "components/ui/button";
import { useWebsocketNotification } from "context/WebsocketNotificationContext";
import { useEffect, useState } from "react";
import { IoIosClose } from "react-icons/io";

import "./styles.scss";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import { updateUserProfile } from "services/profile.service";
import { useAuth } from "context/AuthContext";
import { getPlayerInfo } from "services/dashboard.service";

export default function Notifications() {
  const { message } = useWebsocketNotification();
  const { user, refreshUser } = useAuth();

  const [modalData, setModalData] = useState<any[]>([]);
  const [userInfo, setUserInfo] = useState<any>();


  async function closeNotification() {
  
    const payload = [...modalData];
    payload.shift();
    setModalData(payload);

    await updateUserProfile({
      _id: user?._id,
      extra: {
        ...user?.extra,
        lastMessage: modalData[0].created,
      },
    });

    refreshUser();
  }

  useEffect(() => {
    if (Array.isArray(message)) {
      const data = [...modalData];

      message.forEach((item) => {
        if (data.find((i) => i._id === item._id)) return;
        if(!!item?.cad_missao && item.cad_missao * 1 !== userInfo.cad_missao) return;

        data.push(item);
      });

      setModalData(data);
    } else if (message) {
      if (modalData.find((i) => i._id === message._id)) return;
      if(message?.cad_missao && message.cad_missao * 1 !== userInfo.cad_missao) return;

      setModalData([...modalData, message]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, userInfo]);

  useEffect(() => {
  
    async function getInfo() {
      const data = await getPlayerInfo(user?._id);

      if(data) {
        setUserInfo(data);
      }
    }

    if(!user) return;

    getInfo();
  }, [user]);

  if (!modalData || modalData?.length < 1) return null;

  const item = modalData[0];

  return (
    <div className="modal-notification absolute top-10 left-[10%] border-2 border-solid border-electric-blue bg-oxford-blue rounded-lg p-7 w-[90vw] lg:w-[471px]">
      <Button
        color="primary"
        className="absolute -right-5 -top-5  rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
        onClick={() => closeNotification()}
      >
        <IoIosClose color="white" size={30} />
      </Button>

      {item?.text && (
        <div
          className="relative w-full"
          dangerouslySetInnerHTML={{ __html: item?.text }}
        ></div>
      )}
    </div>
  );
}
