/* eslint-disable react-hooks/exhaustive-deps */
import { Link } from 'react-router-dom';
import { AiFillLeftCircle } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { getPlayerInfo } from 'services/dashboard.service';
import { useAuth } from 'context/AuthContext';
import { ExtratoDetalheModal } from 'components/extrato-detalhe-modal';
import { useModal } from 'context/ModalContext';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import Button from 'components/ui/button';
import {
  getDestaqueCultura,
  getMissaoEquipe,
  getMissaoEquipeFeedback,
  getMissaoGestor,
  getPointsHistory,
  getPointsSummary,
} from 'services/achievement.service';
import { PointItem } from 'components/point-item';
import { FormatNumber } from 'utils/helpers';
import { BsPlusLg } from 'react-icons/bs';
import ModalComment from 'components/modal-comment';
import Recognition from 'components/recognition';
import RecognitionReceived from 'components/recognitionReceived';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';
import ModalEdit from 'components/modal-edit';

const menu = [
  'Resumo',
  'Reconhecimentos',
  'Ações, Desafios e Campanhas',
  'Realização Destaque',
];

export default function Historico() {
  const [summaryList, setSummaryList] = useState<any>();
  const [list, setList] = useState<any[]>([]);
  const [sentList, setSentList] = useState<any[]>([]);
  const [pointsList, setPointsList] = useState<any[]>();
  const [culturaList, setCulturaList] = useState<unknown[]>();

  const [missionList, setMissionList] = useState<unknown[]>();

  const { user, currentCycle } = useAuth();
  const { showModal } = useModal();

  const [isGestor, setIsGestor] = useState(false);

  const [loadingSummaryList, setLoadingSummaryList] = useState(true);
  const [loadingList, setLoadingList] = useState(true);
  const [loadingSentList, setLoadingSentList] = useState(true);
  const [loadingPointsList, setLoadingPointsList] = useState(true);
  const [loadingMissionList, setLoadingMissionList] = useState(true);
  const [loadingCulturaList, setLoadingCulturaList] = useState(true);
  const [id, setId] = useState('');

  const [activeModal, setActiveModal] = useState<any>({});

  const [activeEditModal, setActiveEditModal] = useState<{
    message: string;
    id: string;
  } | null>();
  const [editMessage, setEditMessage] = useState<{
    message: string;
    id: string;
  } | null>();

  const [activeTab, setActiveTab] = useState(0);
  const [message, setMessage] = useState<any>(false);

  function handleDetail(item: any) {
    showModal('extrato-detalhe', item);
  }

  function getMissaoDestaque() {
    if (isGestor) {
      const valoracao = summaryList?.missao_destaque_gestor || 0;
      const participacao = summaryList?.missao_destaque_equipe || 0;
      return FormatNumber(valoracao + participacao);
    } else {
      return FormatNumber(summaryList?.missao_destaque_equipe);
    }
  }

  // async function sendInteraction(item: any, index: number, type: string) {
  //   const isList = list.find((e: any) => e._id === item._id);
  //   const haveLike = item.interaction.find((i: any) => i.sender === user?._id && i.like);

  //   if (item.interaction?.length > 0 && haveLike) {
  //     await deleteInteraction(item.interaction[0]._id);
  //     if (isList && list.length > 0) {
  //       const newList = [...list];
  //       newList[index].interaction = [];

  //       setList(newList);
  //     } else if (sentList?.length > 0) {
  //       const newList = [...sentList];
  //       newList[index].interaction = [];

  //       setSentList(newList);
  //     }
  //   } else {
  //     const payload = {
  //       _id: `${item._id + '_' + user?._id}`,
  //       item: item._id as string,
  //       player: item.sender as string,
  //       sender: user?._id as string,
  //       senderName: user?.name as string,
  //       like: true,
  //       created: {
  //         $date: new Date().getTime(),
  //       },
  //     };

  //     const data = await registerInteraction(payload);

  //     if (isList && list.length > 0) {
  //       const newList = [...list];
  //       newList[index].interaction = [data];

  //       setList(newList);
  //     } else if (sentList?.length > 0) {
  //       const newList = [...sentList];
  //       newList[index].interaction = [data];

  //       setSentList(newList);
  //     }
  //   }
  // }

  useEffect(() => {
    async function loadData() {
      const playerInfo = await getPlayerInfo(user?._id);

      if (playerInfo) {
        setIsGestor(
          playerInfo.tipo_ro === 'GERENCIAL' || playerInfo.funcao === 'DIRETOR'
        );
      }
    }

    if (user) {
      loadData();
    }
  }, [user]);

  useEffect(() => {
    async function loadData() {
      try {
        if (activeTab === 0) {
          const data = await getPointsSummary(
            user?._id,
            currentCycle?.date_start,
            currentCycle?.date_finish
          );
          setSummaryList(data);

          setLoadingSummaryList(false);
        } else if (activeTab === 1) {
        } else if (activeTab === 3) {
          if (isGestor) {
            const dataGestor = await getMissaoGestor(
              user?._id,
              currentCycle?.date_start,
              currentCycle?.date_finish
            );
            const dataEquipe = await getMissaoEquipe(
              user?._id,
              currentCycle?.date_start,
              currentCycle?.date_finish
            );
            const dataFeedback = await getMissaoEquipeFeedback(
              user?._id,
              currentCycle?.date_start,
              currentCycle?.date_finish
            );
            setMissionList([...dataGestor, ...dataEquipe, ...dataFeedback]);
          } else {
            const data = await getMissaoEquipe(
              user?._id,
              currentCycle?.date_start,
              currentCycle?.date_finish
            );
            setMissionList(data);
          }
          setLoadingMissionList(false);
        } else if (activeTab === 4) {
          const data = await getDestaqueCultura(user?._id);

          setCulturaList(data);
          setLoadingCulturaList(false);
        } else if (activeTab === 2) {
          const data = await getPointsHistory(user?._id);

          setPointsList(data);
          setLoadingPointsList(false);
        }
      } catch (e) {}
    }

    if (user && !!currentCycle) {
      loadData();
    }
  }, [user, activeTab, currentCycle]);

  return (
    <>
      <div className="mx-auto container p-4 lg:p-10">
        <div className="flex flex-ro items-center space-x-3 mb-10">
          <div>
            <Link to="../">
              <AiFillLeftCircle size={48} className="text-purple" />
            </Link>
          </div>
          <h1 className="text-xl lg:text-3xl">Extrato Completo</h1>
        </div>

        <div className="rounded-xl overflow-hidden  items-center justify-between mb-12 hidden lg:flex">
          <button
            className={`${
              activeTab === 0 ? 'bg-slate-blue' : 'bg-maastricht-blue'
            } w-6/12 h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
            onClick={() => setActiveTab(0)}
          >
            Resumo
          </button>
          <button
            className={`${
              activeTab === 1 ? 'bg-slate-blue' : 'bg-maastricht-blue'
            } w-6/12 h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
            onClick={() => setActiveTab(1)}
          >
            Reconhecimentos
          </button>
          <button
            className={`${
              activeTab === 3 ? 'bg-slate-blue' : 'bg-maastricht-blue'
            } w-6/12 h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
            onClick={() => setActiveTab(3)}
          >
            Realização Destaque
          </button>
          {/* <button className={`${activeTab === 2 ? 'bg-slate-blue' : 'bg-maastricht-blue'} w-6/12 h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`} onClick={() => setActiveTab(2)}>
            Realização Destaque
          </button> */}
          {/* <button
            className={`${
              activeTab === 3 ? "bg-slate-blue" : "bg-maastricht-blue"
            } w-6/12 h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
            onClick={() => setActiveTab(3)}
          >
            Destaques da cultura BB
          </button> */}
          <button
            className={`${
              activeTab === 2 ? 'bg-slate-blue' : 'bg-maastricht-blue'
            } w-6/12 h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
            onClick={() => setActiveTab(2)}
          >
            Ações, Desafios e Campanhas
          </button>
        </div>

        <div className="flex justify-between items-center lg:hidden mb-10">
          <Button
            color="clear"
            className="shadow-none pr-0 pl-0"
            disabled={activeTab < 1}
            onClick={() => setActiveTab(activeTab - 1)}
          >
            <IoIosArrowBack
              className={`text-grey-dark ${activeTab === 0 && 'bg-opacity-5'}}`}
              size={24}
            />
          </Button>

          <p className="text-xl font-bold text-yellow">{menu[activeTab]}</p>

          <Button
            color="clear"
            className="shadow-none pr-0 pl-0"
            disabled={activeTab > 2}
            onClick={() => setActiveTab(activeTab + 1)}
          >
            <IoIosArrowForward
              className={`text-grey-dark ${activeTab > 0 && 'opacity-5'}}`}
              size={24}
            />
          </Button>
        </div>

        {/* <Box className="mb-5">
          <div className="w-full flex flex-col md:flex-row md:justify-center md:items-center space-y-5 md:space-y-0 md:space-x-5 ">
            <Button
              color={activeTab === 0 ? "featured" : "outline"}
              className="uppercase py-2"
              onClick={() => setActiveTab(0)}
            >
              Resumo
            </Button>
            <Button
              color={activeTab === 1 ? "featured" : "outline"}
              className="uppercase py-2"
              onClick={() => setActiveTab(1)}
            >
              Reconhecimentos
            </Button>
            <Button
              color={activeTab === 2 ? "featured" : "outline"}
              className="uppercase py-2"
              onClick={() => setActiveTab(2)}
            >
              Realização Destaque
            </Button>
            <Button
              color={activeTab === 3 ? "featured" : "outline"}
              className="uppercase py-2"
              onClick={() => setActiveTab(3)}
            >
              Ações, Desafios e Campanhas
            </Button>
          </div>
        </Box> */}

        {activeTab === 0 && (
          <div className="mb-5">
            <div className="space-y-5 lg:p-3">
              <h3 className="text:lg lg:text-2xl mb-3">
                Seu Extrato{' '}
                <span className="text-md lg:text-2xl font-bold text-yellow">
                  Joga Junto BB
                </span>
              </h3>

              {loadingSummaryList && (
                <div className="space-y-2">
                  <ArticlePlaceholder />
                  <ArticlePlaceholder />
                </div>
              )}

              {!loadingSummaryList && (
                <div className="space-y-2">
                  {/* Substituir a próxima linha quando houverem mais itens */}
                  {/* <div className="grid grid-cols-1 md:grid-cols-2 gap-4"> */}
                  <div className="grid grid-cols-1 gap-4">
                    <div className="bg-maastricht-blue-3 rounded-2xl p-8 flex flex-col lg:flex-row lg:items-center justify-between">
                      <div>
                        <div className="text-yellow mb-2">Reconhecimentos</div>
                        <div className="text-2xl">
                          <b>{FormatNumber(summaryList?.reconhecimentos)}</b>{' '}
                          <small className="text-sm">pontos</small>
                        </div>
                      </div>

                      <button
                        className="mt-4 flex items-center text-grey-light justify-center lg:justify-start"
                        onClick={() => setActiveTab(1)}
                      >
                        <BsPlusLg
                          className="mr-2 inline-block grey-dark"
                          size={17}
                        />
                        <span className="uppercase">Ver mais</span>
                      </button>
                    </div>

                    <div className="bg-maastricht-blue-3 rounded-2xl p-8 flex flex-row items-center justify-between">
                      <div>
                        <div className="text-yellow">Realização Destaque</div>
                        <div className="text-2xl">
                          <b>{getMissaoDestaque()}</b>{' '}
                          <small className="text-sm">pontos</small>
                        </div>
                      </div>

                      <button
                        className="mt-4 flex items-center text-grey-light"
                        onClick={() => setActiveTab(3)}
                      >
                        <BsPlusLg
                          className="mr-2 inline-block grey-dark"
                          size={17}
                        />
                        <span className="uppercase">Ver mais</span>
                      </button>
                    </div>

                    {/* <div className="bg-maastricht-blue-3 rounded-2xl p-8 flex flex-row items-center justify-between">
                      <div>
                        <div className="text-yellow">Comitê Selo BB</div>
                        <div className="text-2xl">
                          <b>{FormatNumber(summaryList?.selobb)}</b> <small className="text-sm">pontos</small>
                        </div>
                      </div>
                      <button className="mt-4 flex items-center text-grey-light" onClick={() => setActiveTab(4)}>
                        <BsPlusLg className="mr-2 inline-block grey-dark" size={17} />
                        <span className="uppercase">Ver mais</span>
                      </button>
                    </div> */}

                    {/* <div className="bg-maastricht-blue-3 rounded-2xl p-8 flex flex-row items-center justify-between">
                      <div>
                        <div className="text-yellow">Destaques da Cultura</div>
                        <div className="text-2xl">
                          <b>{FormatNumber(summaryList?.destaque_cultura)}</b> <small className="text-sm">pontos</small>
                        </div>
                      </div>
                      <button className="mt-4 flex items-center text-grey-light" onClick={() => setActiveTab(3)}>
                        <BsPlusLg className="mr-2 inline-block text-grey-dark" size={17} />
                        <span className="uppercase text-grey-light">Ver mais</span>
                      </button>
                    </div> */}

                    <div className="bg-maastricht-blue-3 rounded-2xl p-8 flex flex-row items-center justify-between">
                      <div>
                        <div className="text-yellow">
                          Ações, Desafios e Campanhas
                        </div>
                        <div className="text-2xl">
                          <b>{FormatNumber(summaryList?.invite)}</b>{' '}
                          <small className="text-sm">pontos</small>
                        </div>
                      </div>
                      <button
                        className="mt-4 flex items-center text-grey-light"
                        onClick={() => setActiveTab(2)}
                      >
                        <BsPlusLg
                          className="mr-2 inline-block text-grey-dark"
                          size={17}
                        />
                        <span className="uppercase text-grey-light">
                          Ver mais
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {activeTab === 1 ? (
          <div className="w-full flex flex-col space-y-3 lg:space-y-0 lg:flex-row lg:space-x-5">
            <div className="lg:w-1/2">
              <RecognitionReceived
                message={message}
                activeModal={(item: any) => {
                  setActiveModal(item);
                  setId('received');
                }}
              />
            </div>

            <div className="lg:w-[50%]">
              <Recognition
                message={editMessage}
                activeModal={(item) => {
                  setActiveEditModal(item);
                }}
              />
            </div>
          </div>
        ) : null}

        {activeTab === 3 ? (
          <div className="mb-5">
            <div className="space-y-5 lg:p-3">
              <h3 className="text-2xl mb-3">
                Realizações Destaque{' '}
                <span className="text-xl lg:text-2xl font-bold text-yellow ">
                  ({missionList?.length || 0})
                </span>
              </h3>

              {loadingMissionList && (
                <div className="space-y-2">
                  <ArticlePlaceholder />
                  <ArticlePlaceholder />
                </div>
              )}

              {missionList ? (
                <div className="space-y-2">
                  {missionList?.map((item: any) => (
                    <PointItem key={item._id} data={item} />
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {activeTab === 4 ? (
          <div className="mb-5">
            <div className="space-y-5 lg:p-3">
              <h3 className="text-2xl mb-3 font-thin">
                Confira abaixo os selos que você ganhou na categoria{' '}
                <span className="text-xl lg:text-2xl font-bold text-yellow">
                  Destaques da Cultura BB:
                </span>
              </h3>

              {loadingCulturaList && (
                <div className="space-y-2">
                  <ArticlePlaceholder />
                  <ArticlePlaceholder />
                </div>
              )}

              {culturaList && culturaList?.length > 0 && (
                <div className="space-y-2">
                  <PointItem
                    data={{ items: [...culturaList], type: 'destaque_cultura' }}
                  />
                </div>
              )}
            </div>
          </div>
        ) : null}

        {activeTab === 2 ? (
          <div className="mb-5">
            <div className="space-y-5 lg:p-3">
              <h3 className="text-2xl mb-3">
                Participação em desafios e campanhas{' '}
                <span className="text-xl lg:text-2xl font-bold text-yellow ">
                  ({pointsList?.length || 0})
                </span>
              </h3>

              {loadingPointsList && (
                <div className="space-y-2">
                  <ArticlePlaceholder />
                  <ArticlePlaceholder />
                </div>
              )}

              {pointsList ? (
                <div className="space-y-2">
                  {pointsList.map((item: any) => (
                    <PointItem key={item._id} data={item} />
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>

      <ExtratoDetalheModal />

      {activeModal?._id && (
        <ModalComment
          close={() => setActiveModal({})}
          sendMessage={(message, id) => {
            setMessage({ message, id, item: activeModal });
            setActiveModal({});
          }}
          id={id}
        />
      )}

      {activeEditModal && (
        <ModalEdit
          close={() => setActiveEditModal(null)}
          data={activeEditModal}
          sendMessage={(message) => {
            setEditMessage(message);
            setActiveEditModal(null);
          }}
        />
      )}
    </>
  );
}