export const BehaviorDescription: any = {
  inovacao: 'Inovar é a arte de pensar fora da caixa, de fazer diferente.',
  colaboracao:
    'A palavra “colaboração” significa trabalho em conjunto e é a soft skill do momento!',
  foco: 'Ser próximo e proativo para encantar o cliente.',
  resolutividade:
    'Protagonismo é ser líder de si mesmo, propor mudanças, mobilizar quem está em volta e gerar transformação.',
};

export const BehaviorText: any = {
  inovacao: `Inovar é introduzir novidades ou realizar algo que nunca havia sido feito antes. É achar soluções simples para os temas cotidianos. A inovação pode ser para a empresa, para a equipe, para o mercado ou para o mundo.
  <br/><br/>
  Sabe aquele colega que teve uma ideia genial para descomplicar o dia a dia ou para resolver um problema complexo? Reconhecê-lo no #JogaJuntoBB é uma atitude inovadora também. Bora fazer?
  <br/><br/>
  <span class="text-yellow text-light">#Buscamos o novo todos os dias.</span>
  `,
  colaboracao: `A colaboração é essencial para que todos se relacionem de forma <a class="text-yellow hover:text-yellow" href="https://blog.betrybe.com/carreira/pensamento-sistemico/" target="_blank">harmônica</a>. E faz com que todo o time cresça. Com colaboração, os resultados são alcançados muito mais rapidamente e com maior efetividade.
  <br/><br/>
  Você está com uma semana intensa de trabalho e o colega que está ao seu lado se dispôs a auxiliá-lo? Esse é o espírito da colaboração! Bora acessar o #JogaJuntoBB para reconhecer esse comportamento?
  <br/><br/>
  <span class="text-yellow text-light">#Juntos fazemos muito mais.</span>
  `,
  foco: `Foco no cliente é prestar atendimento humanizado, sentir empatia, oferecer soluções de acordo com as necessidades, responder com celeridade e resolutividade. Só é preciso lembrar que cliente pode ser um colega da mesma ou de outra área, um cliente correntista ou investidor, um acionista, ou seja, toda pessoa envolvida de alguma forma com o BB.
  <br/><br/>
  Seu colega encantou um cliente com um atendimento exemplar? Ele merece pontos no #JogaJuntoBB por isso!
  <br/><br/>
  <span class="text-yellow text-light">#Resultado é gerar valor para o cliente.</span>
  `,
  resolutividade: `Protagonismo é ser responsável por sua evolução pessoal e profissional. É ter atitude e avaliar de forma crítica os acontecimentos, gerando soluções para os problemas e pensando em alternativas para agir.
  <br/><br/>
  E você, tem algum colega protagonista que inspira? Que tal reconhecer esse comportamento?
  <br/><br/>
  <span class="text-yellow text-light">#Juntos fazemos muito mais.</span>
  `,
};

export const MoedaDescription: any = {
  uau: 'Comportamento ou entrega extraordinária, ou seja, para uma referência ou inspiração',
  mandou:
    'Comportamento ou entrega que superou significativamente as expectativas',
  valeu: 'Comportamento ou entrega de nível excelente',
  uau_task:
    'Comportamento ou entrega extraordinária, ou seja, para uma referência ou inspiração',
  mandou_task:
    'Comportamento ou entrega que superou significativamente as expectativas',
  valeu_task: 'Comportamento ou entrega de nível excelente',
};

export const BehaviorLabel: any = {
  inovacao: 'Inovação',
  colaboracao: 'Colaboração',
  foco: 'Foco no Cliente',
  resolutividade: 'Protagonismo',
};

export const MoedaLabel: any = {
  uau: '#UAU',
  mandou: '#MANDOUBEM',
  valeu: '#VALEU',
};

export const MoedaTaskLabel: any = {
  uau_task: '#UAU',
  mandou_task: '#MANDOUBEM',
  valeu_task: '#VALEU',
};

export function GetBehaviorDescription(key: string) {
  if (BehaviorDescription && BehaviorDescription[key]) {
    return BehaviorDescription[key];
  }
  return '';
}

export function GetBehaviorText(key: string) {
  if (BehaviorText && BehaviorText[key]) {
    return BehaviorText[key];
  }
  return '';
}

export function GetBehaviorLabel(key: string) {
  if (BehaviorLabel && BehaviorLabel[key]) {
    return BehaviorLabel[key];
  }
  return '';
}

export function GetMoedaDescription(key: string) {
  if (MoedaDescription && MoedaDescription[key]) {
    return MoedaDescription[key];
  }
  return '';
}

export function GetMoedaLabel(key: string) {
  if (MoedaLabel && MoedaLabel[key]) {
    return MoedaLabel[key];
  }
  return '';
}

export const TaskStatusLabel: any = {
  waiting: 'Aguardando início',
  open: 'Envio de realizações',
  waiting_evaluation: 'Aguardando avaliação',
  evaluation: 'Em avaliação',
  closed: 'Encerrado',
};

export function GetTaskStatusLabel(key: string) {
  if (TaskStatusLabel && TaskStatusLabel[key]) {
    return TaskStatusLabel[key];
  }
  return key;
}

export const TaskLevelLabel: any = {
  aguardando: 'Aguardando validação',
  rejeitado: 'Entrega devolvida',
  aprovado: 'Entrega validada',
  concluido: 'Entrega concluída',
  promovido: 'Indicada ao próximo nível',
  dobrado: 'Bonificada',
  selo: 'Indicado para Selo Realização Destaque',
};

export function GetTaskLevelLabel(key: string) {
  if (TaskLevelLabel && TaskLevelLabel[key]) {
    return TaskLevelLabel[key];
  }
  return key;
}

type ManagerTypeProps =
  | 'equipe'
  | 'solucoes'
  | 'pitstop'
  | 'executivo'
  | 'diretoria';
interface CheckManagerTypeProps {
  user: any;
  item: any;
  prefixo?: number;
  managerType?: ManagerTypeProps;
}

export function CheckManagerType({
  user,
  item,
  prefixo,
}: CheckManagerTypeProps) {
  if (user.cod_etapa <= 0 || user.cod_etapa > 5) return undefined;

  if (item && item.prosseguir_gst) {
    const lastEvaluator =
      Array.isArray(item.evaluator) && item.evaluator.length
        ? item.evaluator[item.evaluator.length - 1]
        : undefined;

    if (
      (item.prosseguir_gst === 'next' || item.prosseguir_gst === 'stopped') &&
      lastEvaluator &&
      user._id === lastEvaluator
    ) {
      return 'pitstop';
    }

    if (
      item.prosseguir_who_approved &&
      item.prosseguir_who_approved === user._id
    ) {
      return 'pitstop';
    }

    if (user.cod_etapa === 2) {
      return 'equipe';
    } else if (user.cod_etapa === 1 || user.cod_etapa === 3) {
      return 'solucoes';
    } else if (user.cod_etapa === 4) {
      return 'executivo';
    } else if (user.cod_etapa === 5) {
      return 'diretoria';
    }
  }

  if (user.cod_etapa === 2) {
    return 'equipe';
  } else if (user.cod_etapa === 1 || user.cod_etapa === 3) {
    return 'solucoes';
  } else if (user.cod_etapa === 4) {
    return 'executivo';
  } else if (user.cod_etapa === 5) {
    return 'diretoria';
  }

  // const equipe = [12083, 12060, 12080, 4725, 12070, 12071, 12423, 16280];
  // const solucoes = [
  //   16261, 16124, 16122, 16121, 16123, 16120, 16153, 16163, 16167, 12050, 12051,
  // ];
  // const executivo = [
  //   16145, 16065, 16051, 16025, 16050, 16041, 16034, 16035, 12010, 12020, 12011,
  // ];
  // const diretoria = [4007, 3003, 16010, 16022];

  // let funcao = user.cod_funcao;
  // let matricula = user.chave;

  // if (funcao === 16261 && prefixo === 8928) {
  //   return 'equipe';
  // } else if (funcao === 16163 && prefixo === 9210) {
  //   return 'equipe';
  // } else if (funcao === 16022 && prefixo === 9210) {
  //   return 'solucoes';
  // } else if (funcao === 12051 && matricula === 'F6002322' && prefixo !== 8559) {
  //   return 'diretoria';
  // } else if (equipe.includes(funcao)) {
  //   return 'equipe';
  // } else if (solucoes.includes(funcao)) {
  //   return 'solucoes';
  // } else if (executivo.includes(funcao)) {
  //   return 'executivo';
  // } else if (diretoria.includes(funcao)) {
  //   return 'diretoria';
  // }

  return undefined;
}

export const ManagerTypeLabel: any = {
  equipe: 'Gerente de Equipe',
  solucoes: 'Gerente de Soluções',
  pitstop: 'Gerente',
  executivo: 'Gerente Executivo',
  diretoria: 'Diretoria',
  selo: 'Selo Realização Destaque',
};

export function GetManagerTypeLabel(key: string) {
  if (ManagerTypeLabel && ManagerTypeLabel[key]) {
    return ManagerTypeLabel[key];
  }
  return key;
}

export const ManagerStepLabel: any = {
  step2: 'Etapa 2',
  step3: 'Etapa 3',
  step4: 'Etapa 4',
  step5: 'Etapa 5',
};

export function GetManagerStepLabel(cod_etapa: number, status: string) {
  if (cod_etapa < 1 || cod_etapa > 5) {
    return '---';
  }

  if (cod_etapa === 3 || cod_etapa === 1) {
    if (
      typeof status === 'string' &&
      (status === 'rejeitado' || status === 'aguardando')
    ) {
      return ManagerStepLabel.step2;
    } else {
      return ManagerStepLabel.step3;
    }
  }

  return ManagerStepLabel[`step${cod_etapa}`];
}
