import {
  Key,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { AiFillLeftCircle } from 'react-icons/ai';

import { AuthContext } from 'context/AuthContext';

import ArticlePlaceholder from 'components/ui/article-placeholder';

import Box from 'components/ui/box';
import {
  getPlayerInfo,
  getServerTime,
  getTaskCycles,
  getTasks,
} from 'services/dashboard.service';
import { TaskItem } from 'components/task-item';
import { GetTaskStatusLabel } from 'utils/strings';

import ImgFeedback from 'assets/img/comportamento/bg-missoes-not-found.png';
import Button from 'components/ui/button';
import { IoIosArrowBack, IoIosArrowForward } from 'react-icons/io';

// const cycles = [new Date('2022-11-18'), new Date('2022-12-18')];
// const cyclesStatus = ['PERÍODO DE AVALIAÇÃO', 'ENVIAR ENTREGAS'];
// const currentCycle = 1;

export default function MissaoDestaque() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [realDatetime, setRealDatetime] = useState<number>(0);

  const [playerInfo, setPlayerInfo] = useState<any>();
  const [isGestor, setIsGestor] = useState<boolean | undefined>();
  const [loading, setLoading] = useState(true);
  const [myTasks, setMyTasks] = useState<any[]>();
  const [otherTasks, setOtherTasks] = useState<any[]>();

  const [taskCycles, setTaskCycles] = useState<any>();
  const [tab, setTab] = useState<'cadastrada' | 'participando'>('cadastrada');
  const [cycleActive, setCycleActive] = useState<string>();

  function handleClickCreate() {
    navigate('./cadastrar');
  }

  useEffect(() => {
    let interv: any = undefined;

    getServerTime().then((timestamp) => {
      setRealDatetime(timestamp);

      interv = window.setInterval(() => {
        setRealDatetime((prevstate) => prevstate + 1000);
      }, 1000);
    });

    return () => clearInterval(interv);
  }, []);

  const activeCycleObj = useMemo(
    () => taskCycles?.find((item: any) => item._id === cycleActive),
    [cycleActive, taskCycles]
  );

  const tasksPerCycle: any = useMemo(() => {
    if (
      !Array.isArray(myTasks) ||
      !Array.isArray(otherTasks) ||
      !taskCycles?.length ||
      !cycleActive
    )
      return null;

    const cycle = taskCycles.find((cycle: any) => cycle._id === cycleActive);
    const tasks = [...(tab === 'cadastrada' ? myTasks : otherTasks)]?.filter(
      (item) =>
        item.created >= cycle.date_start && item.created <= cycle.date_finish
    );

    return tasks?.length ? tasks : [];
  }, [cycleActive, myTasks, otherTasks, tab, taskCycles]);

  const resumePerCycle: {
    registered: number;
    validated: number;
    waiting: number;
  } | null = useMemo(() => {
    if (
      !Array.isArray(myTasks) ||
      !Array.isArray(otherTasks) ||
      !taskCycles?.length ||
      !cycleActive
    )
      return null;

    const cycle = taskCycles.find((cycle: any) => cycle._id === cycleActive);
    const tasks = [...(tab === 'cadastrada' ? myTasks : otherTasks)]?.filter(
      (item) =>
        item.created >= cycle.date_start && item.created <= cycle.date_finish
    );

    return {
      registered: tasks.length || 0,
      validated:
        tasks?.filter(
          (task) =>
            !!task.status?.match(
              /(aprovado|dobrado|promovido|selo|concluido)/gi
            )
        )?.length || 0,
      waiting:
        tasks?.filter((task) => !!task.status?.match(/(aguardando)/gi))
          ?.length || 0,
    };
  }, [cycleActive, myTasks, otherTasks, tab, taskCycles]);

  const getActiveEvaluationStep: 0 | 2 | 3 | 4 | 4.1 | 5 | null = useMemo(():
    | 0
    | 2
    | 3
    | 4
    | 4.1
    | 5
    | null => {
    if (
      !activeCycleObj ||
      activeCycleObj.status !== 'evaluation' ||
      !realDatetime
    )
      return null;

    if (
      realDatetime > activeCycleObj.date_evaluation_step2_start &&
      realDatetime < activeCycleObj.date_evaluation_step2_finish
    ) {
      // Etapa 2
      return 2;
    } else if (
      realDatetime > activeCycleObj.date_evaluation_step3_start &&
      realDatetime < activeCycleObj.date_evaluation_step3_finish
    ) {
      // Etapa 3
      return 3;
    } else if (
      realDatetime > activeCycleObj.date_evaluation_step4_start &&
      realDatetime < activeCycleObj.date_evaluation_step4_finish
    ) {
      // Etapa 4
      return 4;
    } else if (
      realDatetime > activeCycleObj.date_evaluation_pitStop_start &&
      realDatetime < activeCycleObj.date_evaluation_pitStop_finish
    ) {
      // Pit Stop
      return 4.1;
    } else if (
      realDatetime > activeCycleObj.date_evaluation_step5_start &&
      realDatetime < activeCycleObj.date_evaluation_step5_finish
    ) {
      // Etapa 5
      return 5;
    } else {
      return 0;
    }
  }, [activeCycleObj, realDatetime]);

  const taskEditable = useCallback(
    (task: any): boolean => {
      if (!activeCycleObj || !user) return false;

      if (tab === 'cadastrada' && task.owner === user._id) {
        if (
          task.status === 'aguardando' &&
          activeCycleObj.status === 'open' &&
          task.created >= activeCycleObj.date_start &&
          task.created <= activeCycleObj.date_finish
        ) {
          return true;
        }

        if (
          task.status === 'rejeitado' &&
          getActiveEvaluationStep &&
          getActiveEvaluationStep === 2 &&
          task.created >= activeCycleObj.date_start &&
          task.created <= activeCycleObj.date_evaluation
        ) {
          return true;
        }

        return false;
      }

      return false;
    },
    [activeCycleObj, getActiveEvaluationStep, tab, user]
  );

  function renderTasksPerCycle() {
    if (!resumePerCycle) return null;

    try {
      if (tasksPerCycle?.length) {
        return tasksPerCycle.map(
          (task: {
            _id: Key | null | undefined;
            owner: string | undefined;
            status: string;
          }) => (
            <div key={task._id}>
              <TaskItem
                data={task}
                editable={taskEditable(task)}
                onDelete={() => loadData()}
                cycle={activeCycleObj}
                playerInfo={playerInfo}
              />
            </div>
          )
        );
      } else {
        return (
          <div className="flex justify-center items-center flex-col space-y-2 lg:space-y-8 bg-maastricht-blue-3 rounded-[20px] p-8 text-center">
            <img src={ImgFeedback} className="max-w-[150px]" alt=" " />
            {tab === 'cadastrada' && (
              <p className="text-lg block text-center font-thin text-white leading-loose tracking-tight">
                <strong className="block text-center text-yellow font-semibold text-xl">
                  Você
                  {activeCycleObj?.status === 'open'
                    ? ' ainda não possui uma'
                    : ' não possui'}{' '}
                  Realização Destaque nesta fase
                </strong>
                {activeCycleObj?.status === 'open' ? (
                  <>
                    Cadastre sua Realização Destaque, seja reconhecido pelos
                    seus superiores e ganhe recompensas! <br />
                    Lembre-se: você só pode cadastrar uma realização por fase.{' '}
                    <br />
                    <b>Atenção</b>: só devem ser cadastradas realizações{' '}
                    <b>concluídas</b>.
                  </>
                ) : null}
              </p>
            )}
            {tab !== 'cadastrada' && (
              <p className="text-lg block text-center font-thin text-white leading-loose tracking-tight">
                <strong className="block text-center text-yellow font-semibold text-xl">
                  Você{activeCycleObj?.status === 'open' ? ' ainda' : ''} não
                  foi incluído em Realizações Destaque de outros jogadores!
                </strong>
                {activeCycleObj?.status === 'open'
                  ? 'Verifique com seus colegas se há Realizações Destaque que você pode ser adicionado.'
                  : ''}
              </p>
            )}
            {activeCycleObj?.status === 'open' && tab === 'cadastrada' ? (
              <button
                className="text-alice-blue bg-slate-blue text-sm flex items-center justify-center font-semibold uppercase ring-2 ring-slate-blue rounded py-3 px-10"
                onClick={() => handleClickCreate()}
              >
                Cadastrar Realização Destaque
              </button>
            ) : null}
          </div>
        );
      }
    } catch (e) {
      return null;
    }
  }

  useEffect(() => {
    if (taskCycles?.length) {
      const currentCycle = taskCycles?.find(
        (item: any) => item.status === 'open'
      );
      setCycleActive(currentCycle?._id || taskCycles[0]._id);
    }
  }, [tab, taskCycles]);

  // useEffect(() => {
  //   console.log('tasksPerCycle', tasksPerCycle);
  //   console.log('resumePerCycle', resumePerCycle);
  // }, [tasksPerCycle, resumePerCycle]);

  async function loadData() {
    const playerInfoResult = await getPlayerInfo(user?._id);

    setPlayerInfo(playerInfoResult);

    setIsGestor(
      playerInfoResult?.cad_missao && playerInfoResult?.cad_missao === 0
    );

    const data = await getTasks(user?._id);

    const my_tasks = data.filter((item: any) => item.owner === user?._id);
    const other_tasks = data.filter((item: any) => item.owner !== user?._id);

    setMyTasks(my_tasks);
    setOtherTasks(other_tasks);

    const taskCycleResults = await getTaskCycles();
    setTaskCycles(taskCycleResults);

    const currentCycle = taskCycleResults.find(
      (item: any) => item.status === 'open'
    );

    setCycleActive(currentCycle?._id || taskCycleResults[0]._id);

    setLoading(false);
  }

  useEffect(() => {
    if (!user) return;

    setLoading(true);

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (!user || typeof isGestor !== 'boolean') return null;

  if (isGestor && !loading) {
    return (
      <div className="mx-auto container p-4 lg:p-10">
        <div className="flex flex-row items-center space-x-4 mb-16">
          <div>
            <Link to="../">
              <AiFillLeftCircle size={36} className="text-slate-blue" />
            </Link>
          </div>
          <h1 className="text-xl lg:text-3xl text-white font-semibold">
            Realização Destaque
          </h1>
        </div>

        <div className="border-2 border-electric-blue rounded-3xl p-8 bg-oxford-blue">
          <p className="text-dandelion text-lg font-semibold block text-center block">
            Você não possui acesso à esta página.
          </p>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="mx-auto container p-4 lg:p-10">
        <div className="flex flex-row items-center space-x-4 mb-16">
          <div>
            <Link to="../">
              <AiFillLeftCircle size={36} className="text-slate-blue" />
            </Link>
          </div>
          <h1 className="text-xl lg:text-3xl text-white font-semibold">
            Realização Destaque
          </h1>
        </div>

        <div className="border-2 border-electric-blue rounded-3xl p-8 mb-12 bg-oxford-blue">
          <p className="text-dandelion text-lg font-semibold block mb-3">
            Como funciona a validação da Realização Destaque?
          </p>
          <p className="text-white text-base font-light m-0">
            Cadastre e descreva detalhadamente sua realização mais relevante do
            período. Ela será avaliada pelos seus superiores e você poderá
            receber pontos, <em className="italic">feedbacks</em> e{' '}
            <strong className="font-semibold">Selo Realização Destaque</strong>.
          </p>
        </div>

        <div className="rounded-xl overflow-hidden hidden lg:flex items-center justify-between mb-12">
          <button
            className={`${
              tab === 'cadastrada' ? 'bg-slate-blue' : 'bg-maastricht-blue'
            } w-6/12 h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
            onClick={() => (!loading ? setTab('cadastrada') : null)}
          >
            Realização Destaque cadastrada
          </button>
          <button
            className={`${
              tab === 'participando' ? 'bg-slate-blue' : 'bg-maastricht-blue'
            } w-6/12 h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
            onClick={() => (!loading ? setTab('participando') : null)}
          >
            Participação em Realizações Destaque
          </button>
        </div>

        <div className="flex justify-between items-center lg:hidden mb-10">
          <Button
            color="clear"
            className="shadow-none pr-0 pl-0"
            disabled={tab === 'cadastrada'}
            onClick={() => setTab('cadastrada')}
          >
            <IoIosArrowBack
              className={`text-grey-dark ${
                tab === 'cadastrada' && 'opacity-5'
              }}`}
              size={24}
            />
          </Button>

          <p className="text-xl font-bold text-yellow">
            {tab === 'cadastrada'
              ? 'Realização Destaque cadastrada'
              : 'Participação em Realizações Destaque'}
          </p>

          <Button
            color="clear"
            className="shadow-none pr-0 pl-0"
            disabled={tab === 'participando'}
            onClick={() => setTab('participando')}
          >
            <IoIosArrowForward
              className={`text-grey-dark ${
                tab === 'participando' && 'opacity-5'
              }}`}
              size={24}
            />
          </Button>
        </div>

        <div className="w-full flex flex-col lg:flex-row lg:justify-between">
          <div className="lg:w-[32.5%]">
            <h2 className="text-xl text-white block mb-6 font-semibold">
              Histórico de Realizações Destaque
            </h2>

            <Box className="relative bg-prussian-blue border-electric-blue p-4 lg:p-8 border-opacity-100 bg-opacity-100 w-full rounded-[20px]">
              {loading ? (
                <div className="grid grid-cols-1 gap-3">
                  <ArticlePlaceholder />
                </div>
              ) : (
                <>
                  <div className="px-8 mb-8">
                    <p className="text-white mb-8 text-base font-normal block">
                      Escolha a fase
                    </p>

                    <ul className="space-y-4 flex flex-col">
                      {taskCycles &&
                        taskCycles.map((cycle: any, index: number) => (
                          <li key={cycle._id}>
                            <button
                              className={`${
                                cycleActive === cycle._id
                                  ? 'text-alice-blue bg-slate-blue'
                                  : 'text-white'
                              } text-sm flex items-center justify-center font-semibold uppercase ring-2 ring-slate-blue rounded w-full h-10`}
                              onClick={() => setCycleActive(cycle._id)}
                            >
                              {cycle.title} - {GetTaskStatusLabel(cycle.status)}
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>

                  <div className="bg-maastricht-blue rounded-3xl p-8">
                    <p className="block text-white leading-none font-normal text-base mb-4">
                      Realizações Destaque de {activeCycleObj?.title}
                    </p>

                    <ul className="flex flex-col w-full">
                      <li className="flex items-center justify-between text-white font-light text-xs leading-none mb-4 pb-4 border-b border-prussian-blue-2">
                        {tab === 'cadastrada'
                          ? 'Total de realizações cadastradas'
                          : 'Total de realizações cadastradas que você participa'}{' '}
                        <span className="text-base text-yellow leading-none font-semibold flex-1 text-right pl-2">
                          {resumePerCycle?.registered || '0'}
                        </span>
                      </li>
                      <li className="flex items-center justify-between text-white font-light text-xs leading-none mb-4 pb-4 border-b border-prussian-blue-2">
                        {tab === 'cadastrada'
                          ? 'Total de realizações validadas'
                          : 'Total de realizações validadas que você participa'}{' '}
                        <span className="text-base text-yellow leading-none font-semibold flex-1 text-right pl-2">
                          {resumePerCycle?.validated || '0'}
                        </span>
                      </li>
                      <li className="flex items-center justify-between text-white font-light text-xs leading-none">
                        {tab === 'cadastrada'
                          ? 'Total de realizações aguardando validação'
                          : 'Total de realizações aguardando validação que você participa'}{' '}
                        <span className="text-base text-yellow leading-none font-semibold flex-1 text-right pl-2">
                          {resumePerCycle?.waiting || '0'}
                        </span>
                      </li>
                    </ul>
                  </div>
                </>
              )}
            </Box>
          </div>

          <div className="lg:w-[64.8%]">
            <h2 className="text-xl text-white block mb-6 font-semibold">
              Realizações Destaque da{' '}
              {activeCycleObj?.status === 'open'
                ? 'fase atual'
                : activeCycleObj?.title}
            </h2>

            <Box className="relative bg-prussian-blue border-electric-blue p-4 lg:p-8 border-opacity-100 bg-opacity-100 w-full rounded-[20px] space-y-6">
              {loading ? (
                <div className="grid grid-cols-1 gap-3">
                  <ArticlePlaceholder />
                </div>
              ) : (
                renderTasksPerCycle()
              )}
            </Box>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  );
}
