import React from 'react';
import { graphConfig } from '../authConfig';

export async function callMsGraph(accessToken: string) {
  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append('Authorization', bearer);

  const options = {
    method: 'GET',
    headers: headers,
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then((response) => response.json())
    .catch((error) => console.log(error));
}

export type GraphData = {
  displayName: string;
  jobTitle: string;
  mail: string;
  businessPhones: string[];
  officeLocation: string;
};

export const ProfileData = (props: any) => {
  return (
    <div className="w-full border text-blue-dark bg-yellow p-3 mt-5 rounded-xl text-center text-gray font-bold text-xl">
      Olá, <strong>{props.graphData?.displayName}! Aguarde...</strong>
    </div>
  );
};
