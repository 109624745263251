import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AiFillLeftCircle } from 'react-icons/ai';

import Box from 'components/ui/box';
import { getFaq } from 'services/content.service';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import FaqItem from './FaqItem';

const qrcode = require('assets/img/qrcode-bbfunci.png');

export default function Faq() {
  const [faqList, setFaqList] = useState<any>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadData() {
      setLoading(true);
      const data = await getFaq();
      if (data) {
        setFaqList(data);
      }
      setLoading(false);
    }
    loadData();
  }, []);

  return (
    <div className="mx-auto container p-3 lg:p-10">
      <div className="flex flex-ro items-center space-x-3 lg:space-x-6 mb-3 lg:mb-10">
        <div>
          <Link to="../">
            <AiFillLeftCircle size={48} className="text-purple" />
          </Link>
        </div>
        <h1 className="text-xl lg:text-3xl">Ajuda</h1>
      </div>

      <div className="space-y-5">
        <p className="p-2 lg:p-0 rounded-lg bg-blue-dark lg:bg-transparent lg:text-2xl font-bold text-center relative">
          Encontre respostas para suas dúvidas rapidamente. Acesse as principais
          dúvidas dos nossos clientes ou clique na dúvida específica do jogo
          #JOGAJUNTOBB.
        </p>

        <Box>
          <div className="space-y-3">
            {!!loading && (
              <>
                <ArticlePlaceholder />
                <ArticlePlaceholder />
              </>
            )}

            {!loading &&
              !!faqList &&
              faqList.map((item: any) => (
                <FaqItem key={item._id} _id={item._id} title={item.title} />
              ))}
          </div>
        </Box>

        <Box className="relative px-3 py-3">
          <div className="flex-1 flex flex-row-reverse lg:flex-row items-center space-x-4">
            <div className="w-1/3 lg:w-[128px]">
              <img src={qrcode} alt="BB Funci" />
            </div>
            <div className="flex-1 lg:text-lg">
              <b className="text-yellow">Dúvidas? Digite #JogaJuntoBB</b>
              <br />e converse com um especialista em dias úteis de 9 às 18h
              (horário de Brasília)
            </div>
          </div>
        </Box>
      </div>
    </div>
  );
}
