import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import InputMask from 'react-input-mask';
import { TbSearch } from 'react-icons/tb';
import { RiCloseLine } from 'react-icons/ri';
import { IoClose } from 'react-icons/io5';

import { useAuth } from 'context/AuthContext';
import Box from 'components/ui/box';
import { UserAvatar } from 'components/user-avatar';
import { findGestor, updateUserByIdProfile } from 'services/profile.service';

import { User, UserInfo } from 'types/user';

import FeedbackImg from 'assets/img/comportamento/bg-feedback-moedas.old.png';
import { getPlayerInfo } from 'services/dashboard.service';
import Button from 'components/ui/button';
import { IoIosClose } from 'react-icons/io';

const maskTemplate = 'F9999999';

interface IProps {
  closeModal: () => void;
  onSubmit: () => void;
  player: User;
}

export default function ChangeGestor({ closeModal, player, onSubmit }: IProps) {
  const navigate = useNavigate();
  const { user } = useAuth();

  const searchRef = useRef<any>();

  const [isOpen, setIsOpen] = useState(true);
  const [confirm, setConfirm] = useState(false);
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [userEdit, setUserEdit] = useState<UserInfo>();
  const [target, setTarget] = useState<User[]>();
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const [mask, setMask] = useState('');

  ReactModal.setAppElement('#modal');

  function handleChange() {
    if (searchRef.current) {
      const value = searchRef.current.value.toUpperCase();
      if (value.length > 1) {
        if (value[0].toUpperCase() === 'F' && !isNaN(value[1])) {
          setMask(maskTemplate);
        } else {
          setMask('');
          if (value === '_______') {
            searchRef.current.value = '';
          }
        }
      } else {
        setMask('');
      }
    }
  }

  async function handleSearch(e?: any) {
    if (e) {
      e.preventDefault();
    }

    try {
      if (!user?._id) return;

      const matricula = searchRef.current.value.replace('_', '');

      const targetOnList = target?.find((item) => item._id === matricula);

      if (targetOnList) {
        return;
      }

      setSearching(true);

      const result = await findGestor(user?._id, searchRef.current.value);

      if (result.length === 1) {
        addTarget(result[0]);
      } else {
        setSearchResults(result);

        if (result.length === 0) {
          setTarget([]);
        }
      }

      setSearching(false);
    } catch (e) {
      setSearching(false);
    }
  }

  async function handleSubmit() {
    try {
      setConfirm(false);
      setLoading(true);

      if (target?.[0]?.extra?.id) {
        await updateUserByIdProfile({
          _id: player._id,
          extra: {
            gestor:
              !!userEdit?.oldGestor &&
              target[0].extra.id === userEdit?.oldGestor
                ? null
                : target[0].extra.id,
          },
        });

        setLoading(false);
        setSubmitted(true);
      }
    } finally {
      setLoading(false);
    }
  }

  function removeTarget(index: number) {
    if (target && target.length) {
      let newTargetList = [...target];
      newTargetList.splice(index, 1);
      setTarget(newTargetList);
    }
  }

  function addTarget(item: User) {
    setTarget([item]);
    setSearchResults([]);
    setSearching(false);

    if (searchRef && searchRef.current) {
      searchRef.current.value = '';
    }
  }

  function reset() {
    setTarget(undefined);
    setSubmitted(false);
    setLoading(false);
    setSearchResults([]);
    setSearching(false);
    setStep(0);
    setUserEdit(undefined);
  }

  function handleCloseModal() {
    reset();
    closeModal();
  }

  function afterOpenModal() {}

  useEffect(() => {
    async function getUser() {
      const playerInfo = await getPlayerInfo(player?._id);
      setUserEdit(playerInfo);
    }

    if (player) {
      getUser();
    }
  }, [player]);

  function isSwitchable() {
    if (
      !target?.length ||
      typeof target[0]?.cod_etapa !== 'number' ||
      typeof userEdit?.cod_etapa !== 'number'
    )
      return false;

    if (target[0]?.cod_etapa > userEdit?.cod_etapa) return true;

    return false;
  }

  return (
    <ReactModal
      key={'modal-player-get-player'}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={handleCloseModal}
      contentLabel="Modal Convidar Amigos"
      portalClassName="modal-player-get-player"
      overlayClassName="fixed z-20 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
      contentElement={() => (
        <>
          {!submitted && !confirm && (
            <Box className="w-[90%] lg:max-w-screen-md">
              <button
                className="absolute w-[33px] h-[33px] flex items-center justify-center -top-4 -right-4 button-close bg-slate-blue rounded-full"
                onClick={() => {
                  if (loading) return null;

                  if (searchResults.length > 0) {
                    reset();
                  } else {
                    setIsOpen(false);
                  }
                }}
              >
                <IoClose
                  size={23}
                  className="text-white leading-none block m-0 p-0"
                />
              </button>

              <div className="mx-auto relative container overflow-y-auto overflow-x-hidden max-h-[85vh]">
                <div className="block mb-8">
                  <h1 className="text-lg uppercase block text-center text-yellow">
                    Vincular outro gestor ao funcionário
                  </h1>
                </div>

                {!loading && !submitted && (
                  <div className="space-y-6">
                    {/* <div className="relative flex flex-row items-center justify-around">
                      <div className="absolute z-0 w-full h-[1px] top-0 bottom-0 m-auto border-b border-white opacity-20"></div>
                      <div className={`${step === 0 ? 'bg-slate-blue' : 'bg-prussian-blue text-white'} text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}>1</div>
                      <div className={`${step === 1 ? 'bg-slate-blue' : 'bg-prussian-blue text-white'} text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}>2</div>
                    </div> */}

                    {step === 0 && (
                      <div className="space-y-6">
                        <div className="space-x-4 flex flex-row items-center justify-between w-full">
                          <div className="relative flex-1">
                            <span className="text-alice-blue mb-4 block text-base font-thin">
                              Adicione o gestor que deseja vincular ao
                              funcionário
                            </span>

                            {(!target?.length || target?.length < 5) && (
                              <>
                                <div className="space-x-4 flex flex-row items-center justify-between">
                                  <form
                                    onSubmit={handleSearch}
                                    className="pl-1 flex-1"
                                  >
                                    <InputMask
                                      placeholder="Digite o nome ou matrícula do gestor"
                                      alwaysShowMask={false}
                                      mask={mask}
                                      ref={(ref: any) => {
                                        searchRef.current = ref;
                                      }}
                                      onChange={(e) => handleChange()}
                                      inputMode="search"
                                      style={{ fontSize: 16 }}
                                      className="w-full bg-maastricht-blue text-white text-sm font-light flex-1 rounded-md py-2 px-4"
                                    />
                                  </form>

                                  <TbSearch
                                    size={28}
                                    className="cursor-pointer hover:ring-2 mr-1 rounded-full"
                                    onClick={() => handleSearch()}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>

                        {target && target?.length > 0 && !searching && (
                          <div className="flex-1 bg-rich-black bg-opacity-80 py-4 px-2 lg:px-8 rounded-3xl">
                            {target.map((item: User, index: number) => (
                              <div
                                key={item._id}
                                className={`w-full flex flex-col lg:flex-row lg:items-center justify-between ${
                                  index + 1 < target.length
                                    ? 'border-b border-prussian-blue pb-4 mb-4 '
                                    : ''
                                }`}
                              >
                                <div className="flex flex-1 relative">
                                  <div className="w-[44px] h-[44px] ml-2">
                                    <UserAvatar
                                      data={{
                                        image: item.image,
                                        ...item.extra,
                                      }}
                                    />
                                  </div>

                                  <p className="flex-1 pl-3 pr-2">
                                    <span className="text-xs font-thin w-full">
                                      <strong className="text-base font-semibold lg:truncate w-[35vw] lg:w-auto">
                                        {item.name}
                                      </strong>{' '}
                                      ({item._id})
                                    </span>
                                    {!!item.extra?.diretoria ||
                                    !!item.extra?.funcao ? (
                                      <span className="block text-xs font-thin">
                                        {!!item.extra?.diretoria && (
                                          <span className="text-electric-blue uppercase">
                                            {item.extra?.diretoria}
                                          </span>
                                        )}
                                        {!!item.extra?.diretoria &&
                                          !!item.extra?.funcao &&
                                          ' - '}
                                        {item.extra?.funcao
                                          ? `${item.extra?.funcao}`
                                          : ''}
                                      </span>
                                    ) : null}
                                  </p>
                                </div>

                                <RiCloseLine
                                  size={44}
                                  className="cursor-pointer hover:ring-2 p-2 rounded-full text-slate-blue"
                                  onClick={() => removeTarget(index)}
                                />
                              </div>
                            ))}
                          </div>
                        )}

                        {searching && (
                          <div className="flex-1 bg-rich-black bg-opacity-80 p-8 rounded-3xl">
                            <p className="animate-pulse text-yellow">
                              Recuperando informações...
                            </p>
                          </div>
                        )}

                        {!searching && !!searchResults?.length && (
                          <>
                            <div className="flex-1 bg-rich-black bg-opacity-80 py-4 px-8 rounded-3xl h-[30vh] lg:h-auto overflow-y-auto lg:overflow-y-visible">
                              {searchResults.map((item, index) => (
                                <div
                                  key={item._id}
                                  onClick={() => addTarget(item)}
                                  className={`cursor-pointer hover:opacity-75 w-full flex flex-row items-center justify-between border-b border-prussian-blue pb-4 mb-4`}
                                >
                                  <div className="w-[44px] h-[44px] ml-2">
                                    <UserAvatar
                                      data={{
                                        image: item.image,
                                        ...item.extra,
                                      }}
                                    />
                                  </div>
                                  <p className="flex-1 pl-3 pr-2">
                                    <span className="text-xs font-thin">
                                      <strong className="text-base font-semibold">
                                        {item.name}
                                      </strong>{' '}
                                      ({item._id})
                                    </span>
                                    {!!item.extra?.diretoria ||
                                    !!item.extra?.funcao ? (
                                      <span className="block text-xs font-thin">
                                        {!!item.extra?.diretoria && (
                                          <span className="text-electric-blue uppercase">
                                            {item.extra?.diretoria}
                                          </span>
                                        )}
                                        {!!item.extra?.diretoria &&
                                          !!item.extra?.funcao &&
                                          ' - '}
                                        {item.extra?.funcao
                                          ? `${item.extra?.funcao}`
                                          : ''}
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                              ))}

                              <div className="text-center pt-2 text-white font-base font-light">
                                Não encontrou quem procura? Experimente buscar
                                por nome e sobrenome ou matrícula.
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    )}

                    <div className="flex lg:block justify-center">
                      <div className="px-2 px-45 flex flex-row space-x-8 items-center justify-center">
                        {step === 0 && (
                          <div className="space-y-6">
                            {!isSwitchable() && !!target?.length && (
                              <p className="block font-light text-yellow text-center">
                                O novo gestor a ser indicado deve pertencer a
                                nível hierárquico superior ao do funcionário em
                                questão.
                              </p>
                            )}
                            <button
                              disabled={!isSwitchable()}
                              onClick={() =>
                                target?.length ? setConfirm(true) : null
                              }
                              className={`mx-auto mb-2 leading-none disabled:opacity-50 ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white rounded py-3 px-4`}
                            >
                              <p className="font-bold text-sm leading-none uppercase">
                                Confirmar alteração
                              </p>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {loading && !submitted && (
                  <div className="space-y-4 mt-12">
                    <div className="flex flex-row items-center justify-center text-xl text-yellow animate-pulse">
                      <div></div>
                      Mudando gestor...
                    </div>
                  </div>
                )}
              </div>
            </Box>
          )}

          {confirm && (
            <Box className={`w-[90%] lg:w-auto lg:max-w-[600px]`}>
              <Button
                color="primary"
                className="absolute -right-5 -top-5 rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center"
                onClick={() => {
                  setConfirm(false);
                }}
              >
                <IoIosClose color="white" size={40} />
              </Button>

              <>
                <div className="w-full lg:w-[536px] flex flex-col items-center justify-center space-y-8">
                  <h4 className="block text-center text-yellow text-lg font-semibold leading-none">
                    Vincular outro gestor ao funcionário?
                  </h4>
                  <p className="text-alice-blue text-base font-thin block">
                    Após a confirmação, os dados para cálculo de pontuação de
                    reconhecimento e apreciação das missões serão ajustados, sem
                    impacto na avaliação do sistema GDP.
                  </p>
                  <button
                    className={`text-white bg-slate-blue text-sm inline-flex items-center justify-center font-semibold uppercase rounded py-3 px-4 leading-none`}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Confirma alteração
                  </button>
                </div>
              </>
            </Box>
          )}

          {submitted && (
            <div className="border-2 rounded-3xl p-3 relative bg-oxford-blue border-electric-blue p-8 flex flex-col w-[324px] lg:max-w-screen-md justify-center items-center">
              <button
                className="absolute w-[33px] h-[33px] flex items-center justify-center -top-4 -right-4 button-close bg-slate-blue rounded-full"
                onClick={() => {
                  if (loading) return null;
                  onSubmit();
                }}
              >
                <IoClose
                  size={23}
                  className="text-white leading-none block m-0 p-0"
                />
              </button>

              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center mb-6">
                  <img
                    src={FeedbackImg}
                    alt="Enviado!"
                    className="w-[173px] block"
                  />
                </div>

                <h1 className="block text-2xl font-semibold text-yellow mb-4 text-center">
                  Alteração concluída!
                </h1>

                {target?.length && (
                  <>
                    <p className="text-sm font-white font-light text-center block">
                      Agora esse funcionário está sobre a gestão de{' '}
                      <b className="font-bold">{target[0].name}.</b>
                    </p>
                  </>
                )}
              </div>
            </div>
          )}
        </>
      )}
    />
  );
}
