import { useAuth } from 'context/AuthContext';
import { BiLogOut } from 'react-icons/bi';
import Logo from '../../assets/img/logo.svg';

export function NotAllowed() {
  const { signOut } = useAuth();

  return (
    <div className="absolute left-0 right-0 min-h-full m-auto px-5 md:w-1/2 xl:w-1/3 2xl:w-1/3 flex flex-col items-center justify-center">
      <div>
        <img src={Logo} className="pointer-events-none h-36 lg:h-48" alt="Joga Junto BB" />
      </div>

      <div className="w-full mt-5 text-center text-yellow font-bold text-2xl">
      Oi, colega vi que você demonstrou interesse no #JogaJuntoBB.
      </div>

      <div className="my-4 w-full border-t border-white"></div>

      <p className="text-center">
      Essa é uma ação piloto para Reconhecimento.<br />No momento sua Unidade não faz parte do público alvo da ação,<br className="hidden lg:block" />{' '}favor aguardar.
      </p>

      <div className="mt-8">
        <button
          onClick={() => signOut()}
          className="uppercase flex flex-row items-center space-x-1 bg-yellow text-blue-dark p-3 rounded-full"
        >
          <span>ENCERRAR SESSÃO</span>
          <BiLogOut />
        </button>
      </div>
    </div>
  );
}
