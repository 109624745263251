import { useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import Button from 'components/ui/button';
import { useModal } from 'context/ModalContext';

import { Colaboracao, FocoNoCliente, Inovacao, MandouBem, Resolutividade, Uau, Valeu } from 'components/icons';
import { FaTimes, FaPlus, FaEquals } from 'react-icons/fa';
import { IoIosClose } from 'react-icons/io';

const iconSize = 44;
const ComportamentoIcon: any = {
  colaboracao: <Colaboracao size={iconSize} />,
  inovacao: <Inovacao size={iconSize} />,
  foco: <FocoNoCliente size={iconSize} />,
  resolutividade: <Resolutividade size={iconSize} />,
};

const Comportamento: any = {
  colaboracao: 50,
  inovacao: 50,
  foco: 50,
  resolutividade: 50,
};

const ComportamentoLabel: any = {
  colaboracao: 'Colaboração',
  inovacao: 'Inovação',
  foco: 'Foco no Cliente',
  resolutividade: 'Protagonismo',
};

const Multiplicador: any = {
  '3': 'Meu Gestor',
  '2': 'Diretor',
  '1.5': 'Executivo',
  '1.3': 'Gerente de Soluções',
  '1.2': 'Gerente de Equipe',
  '1': 'Demais funções',
};

const Moeda: any = {
  uau: 50,
  mandou: 30,
  valeu: 10,
};

const MoedaLabel: any = {
  uau: '#UAU',
  mandou: '#MANDOUBEM',
  valeu: '#VALEU',
};

const MoedaIcon: any = {
  uau: <Uau yellow size={iconSize} />,
  mandou: <MandouBem yellow size={iconSize} />,
  valeu: <Valeu yellow size={iconSize} />,
};

const Proximidade: any = {
  '40': 'Mesma equipe',
  '25': 'Mesma Diretoria',
  '15': 'Diretoria Diferente',
};

export function ExtratoDetalheModal(props: any) {
  // const modalID = "achievement";

  const modalID = 'extrato-detalhe';

  const [modalIsOpen, setIsOpen] = useState(false);

  const { openModal, modalData, closeModal } = useModal();

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    setIsOpen(openModal === modalID);
  }, [openModal]);

  useEffect(() => {
    if (!modalIsOpen) return;
  }, [modalIsOpen, modalData]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            {modalIsOpen && modalData && (
              <div className="relative bg-blue-dark text-white rounded-xl px-3 py-10 lg:p-10 flex flex-col items-center space-y-2 flex-none mx-5 border border-electric-blue">
                <Button color="primary" className="absolute -right-5 -top-5  rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center" onClick={() => closeModal()}>
                  <IoIosClose color="white" size={30} />
                </Button>
                <div className="text-center mb-3">
                  <h1 className="text-yellow text-2xl mb-2">Reconhecimento enviado por:</h1>
                  <h2 className="text-xl">{modalData.sender_name}</h2>
                  <p className="text-blue-light">{new Date(modalData.time).toLocaleString()}</p>
                  {modalData && (
                    <div className="flex flex-row flex-wrap justify-center py-4 pt-4 space-x-2 lg:space-x-2 lg:px-2 overflow-y-auto max-h-[50vh]">
                      <div className="w-28 lg:w-36 rounded-xl p-3 flex flex-col items-center space-y-2 bg-maastricht-blue-2">
                        <div className="flex-1 ring-yellow ring-2 p-2 rounded-full">{ComportamentoIcon[modalData.behavior]}</div>
                        <div className="flex-1 text-md font-bold">{ComportamentoLabel[modalData.behavior]}</div>
                        <div className="rounded-full flex-1 w-full px-2 py-1 bg-oxford-blue text-yellow font-bold">{Comportamento[modalData.behavior]}</div>
                      </div>
                      <div className="self-center px-2 lg:px-0">
                        <FaPlus size={24} />
                      </div>
                      <div className="w-28 lg:w-36 rounded-xl p-3 flex flex-col items-center space-y-2 bg-maastricht-blue-2">
                        <div className="flex-1 p-2 rounded-full">{MoedaIcon[modalData.coin]}</div>
                        <div className="flex-1 text-md font-bold">{MoedaLabel[modalData.coin]}</div>
                        <div className="rounded-full w-full px-2 py-1 bg-oxford-blue text-yellow font-bold">{Moeda[modalData.coin]}</div>
                      </div>
                      <div className="w-1/2 py-3 min-w-max lg:min-w-0 lg:py-0 lg:w-auto self-center ">
                        <FaPlus size={24} className="mx-auto" />
                      </div>
                      <div className="min-w-max lg:min-w-0 lg:w-36 rounded-xl p-3 flex flex-col items-center space-y-2 bg-maastricht-blue-2">
                        <div className="text-electric-blue font-thin">Proximidade</div>
                        <div className="flex-1 flex flex-row items-center text-md font-bold">{Proximidade[`${modalData.proximidade}`]}</div>
                        <div className="bg-oxford-blue rounded-full w-full px-2 py-1 text-yellow">{modalData.proximidade}</div>
                      </div>
                      <div className="w-1/2 py-3 min-w-max lg:min-w-0 lg:py-0 lg:w-auto self-center">
                        <FaTimes size={24} className="mx-auto" />
                      </div>
                      <div className="min-w-max lg:min-w-0 lg:w-36 rounded-xl p-3 flex flex-col items-center justify space-y-2 bg-maastricht-blue-2">
                        <div className="text-electric-blue font-thin">Função</div>
                        <div className="flex-1 flex flex-row items-center text-md font-bold">{modalData.sender_funcao}</div>
                        <div className="flex-0 bg-oxford-blue rounded-full w-full px-2 py-1 text-yellow">{modalData.hierarquia}</div>
                      </div>
                      <div className="w-1/2 min-w-max self-center  py-3 lg:min-w-0 lg:py-0 lg:w-auto">
                        <FaEquals size={24} className="text-yellow mx-auto" />
                      </div>
                      <div className="min-w-max  mt-2 lg:w-36 rounded-xl ring-2 ring-electric-blue p-3 flex flex-col items-center justify space-y-2 bg-maastricht-blue-2">
                        <div className="flex-1 flex flex-row items-center text-md font-bold">Total de pontos</div>
                        <div className="flex-0 text-lg bg-oxford-blue text-yellow rounded-full w-full px-2 py-1">{modalData.total}</div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      />
    </>
  );
}
