/* eslint-disable react-hooks/exhaustive-deps */
import { differenceInSeconds } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

// let tickTimeout: any = {};

// type CountdownProps = {
// 	deadline: unknown;
// }

export function Countdown({ ...props }) {
  const { deadline } = props;

  // const [result, setResult] = useState("00:00:00");

  const ONE_DAY = 60 * 60 * 24;
  const ONE_HOUR = 60 * 60;
  const ONE_MINUTE = 60;
  const [currentTime, setCurrentTime] = useState(new Date().getTime());

  const diffInSeconds = differenceInSeconds(deadline, currentTime);

  const getCoundown = () => {
    if (diffInSeconds <= 1) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }
    const days = Math.floor(diffInSeconds / ONE_DAY);
    const hours = Math.floor((diffInSeconds - days * ONE_DAY) / ONE_HOUR);
    const minutes = Math.floor((diffInSeconds - days * ONE_DAY - hours * ONE_HOUR) / ONE_MINUTE);
    const seconds = diffInSeconds - days * ONE_DAY - hours * ONE_HOUR - minutes * ONE_MINUTE;
    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const countdown = useMemo(getCoundown, [currentTime]);

  useEffect(() => {
    function tick() {
      const now = new Date().getTime();
      setCurrentTime(now);
    }
    const tickTimeout = setInterval(() => {
      tick();
    }, 1000);

    return () => {
      clearInterval(tickTimeout);
    };
  }, []);

  return (
    <>
      {countdown.days > 1 && <span>{countdown.days} dias, </span>}
      {countdown.days === 1 && <span>{countdown.days} dia, </span>}
      <span>{String(countdown.hours).padStart(2, '0')}:</span>
      <span>{String(countdown.minutes).padStart(2, '0')}:</span>
      <span>{String(countdown.seconds).padStart(2, '0')}</span>
    </>
  );
}
