import { UIElementProps } from 'types/prop-types';

import IconUau from 'assets/img/moedas/uau.svg';
import IconMandou from 'assets/img/moedas/mandou.svg';
import IconValeu from 'assets/img/moedas/valeu.svg';
import IconUauYellow from 'assets/img/moedas/uau-yellow.svg';
import IconMandouYellow from 'assets/img/moedas/mandou-yellow.svg';
import IconValeuYellow from 'assets/img/moedas/valeu-yellow.svg';

import IconColaboracao from 'assets/img/comportamento/colaboracao.svg';
import IconInovacao from 'assets/img/comportamento/inovacao.svg';
import IconFoco from 'assets/img/comportamento/foco.svg';
import IconResolutividade from 'assets/img/comportamento/resolutividade.svg';

const IconSeloColaboracao = require('assets/img/comportamento/selo-colaboracao.png');
const IconSeloInovacao = require('assets/img/comportamento/selo-inovacao.png');
const IconSeloFoco = require('assets/img/comportamento/selo-foco.png');
const IconSeloResolutividade = require('assets/img/comportamento/selo-resolutividade.png');

type IconProps = UIElementProps & {
  size?: any;
};

export function Uau(props: IconProps) {
  return (
    <div>
      <img src={props?.yellow ? IconUauYellow : IconUau} alt="" style={{ height: props?.size || 32 }} />
    </div>
  );
}

export function MandouBem(props: IconProps) {
  return (
    <div>
      <img src={props?.yellow ? IconMandouYellow : IconMandou} alt="" style={{ height: props?.size || 32 }} />
    </div>
  );
}

export function Valeu(props: IconProps) {
  return (
    <div>
      <img src={props?.yellow ? IconValeuYellow : IconValeu} alt="" style={{ height: props?.size || 32 }} />
    </div>
  );
}

export function Colaboracao(props: IconProps) {
  return (
    <div>
      <img src={IconColaboracao} alt="" style={{ height: props?.size || 32 }} />
    </div>
  );
}

export function Inovacao(props: IconProps) {
  return (
    <div>
      <img src={IconInovacao} alt="" style={{ height: props?.size || 32 }} />
    </div>
  );
}

export function FocoNoCliente(props: IconProps) {
  return (
    <div>
      <img src={IconFoco} alt="" style={{ height: props?.size || 32 }} />
    </div>
  );
}

export function Resolutividade(props: IconProps) {
  return (
    <div>
      <img src={IconResolutividade} alt="" className={`w-auto h-full object-contain`} style={{ height: props?.size || 32 }} />
    </div>
  );
}

export function SeloColaboracao(props: IconProps) {
  return (
    <div>
      <img src={IconSeloColaboracao} alt="Destaque em Colaboração" style={{ height: props?.size || 32 }} />
    </div>
  );
}

export function SeloInovacao(props: IconProps) {
  return (
    <div>
      <img src={IconSeloInovacao} alt="Destaque em Inovação" style={{ height: props?.size || 32 }} />
    </div>
  );
}

export function SeloFocoNoCliente(props: IconProps) {
  return (
    <div>
      <img src={IconSeloFoco} alt="Destaque em Foco no Cliente" style={{ height: props?.size || 32 }} />
    </div>
  );
}

export function SeloResolutividade(props: IconProps) {
  return (
    <div>
      <img src={IconSeloResolutividade} alt="Destaque em Protagonismo" style={{ height: props?.size || 32 }} />
    </div>
  );
}
