/* eslint-disable react-hooks/exhaustive-deps */
import { PointReviewItem } from 'components/point-review-item';
import { RewardReviewItem } from 'components/reward-review-item';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import Box from 'components/ui/box';
import { useEffect, useState } from 'react';
import { AiFillLeftCircle } from 'react-icons/ai';
import { getMissaoGestor, getMissaoEquipe } from 'services/achievement.service';
import { getKPIHistory, getPlayerInfo } from 'services/dashboard.service';
import { getUserProfile } from 'services/profile.service';
import Avatar from 'funifier-nice-avatar';
import MissaoDestaquePorUsuario from 'components/MissaoDestaque';
import Button from 'components/ui/button';
import ChangeGestor from './ChangeGestor';
import { User } from 'types/user';

interface QuadroEquipeDetailProps {
  id?: string;
  isFromTeam: boolean;
  onClose: () => void;
}
export function QuadroEquipeDetail({
  id,
  onClose,
  isFromTeam,
}: QuadroEquipeDetailProps) {
  const [user, setUser] = useState<User>();
  const [userInfo, setUserInfo] = useState<any>();
  const [activeTab, setActiveTab] = useState<number>(0);
  const [showModal, setShowModal] = useState(false);

  const [list, setList] = useState<unknown[]>();
  const [missionList, setMissionList] = useState<unknown[]>();

  const [loadingList, setLoadingList] = useState(true);
  const [loadingMissionList, setLoadingMissionList] = useState(true);

  useEffect(() => {
    if (!id) {
      return;
    }

    async function loadPlayerInfo() {
      if (!id) return;

      const userProfile = await getUserProfile(id);
      const playerInfo = await getPlayerInfo(id);
      setUserInfo(playerInfo);
      setUser(userProfile);

      const isGestor =
        playerInfo.tipo_ro === 'GERENCIAL' || playerInfo.funcao === 'DIRETOR';

      if (isGestor) {
        const dataGestor = await getMissaoGestor(id);
        const dataEquipe = await getMissaoEquipe(id);
        setMissionList([...dataGestor, ...dataEquipe]);
      } else {
        const data = await getMissaoEquipe(id);
        setMissionList(data);
      }

      setLoadingMissionList(false);
    }

    async function loadReconhecimentos() {
      const result = await getKPIHistory(id);
      setList(result);
      setLoadingList(false);
    }

    loadPlayerInfo();
    loadReconhecimentos();
  }, [id]);

  return (
    <div className="mx-auto container p-3 lg:p-10">
      <div className="flex justify-between items-center space-x-3 lg:space-x-2 mb-3 lg:mb-10">
        <div className="flex items-center">
          <div className="cursor-pointer" onClick={() => onClose()}>
            <AiFillLeftCircle size={48} className="text-purple" />
          </div>
          <h1 className="text-lg lg:text-3xl py-1 px-6">
            Perfil de {user?.name}
          </h1>
        </div>

        {!!isFromTeam && (
          <div>
            <Button
              className="px-6 py-2 uppercase"
              color="outlinePurple"
              onClick={() => setShowModal(true)}
            >
              Alterar Gestor
            </Button>
          </div>
        )}
      </div>

      {userInfo && (
        <div className="flex flex-row items-center space-x-3 mb-5">
          <div className="w-32 h-32 md:w-44 md:h-44 bg-purple flex items-center justify-center rounded-full overflow-hidden text-3xl md:text-6xl">
            {!!user?.extra.avatar_type &&
              (user.image ? (
                <img
                  src={user.image.medium.url}
                  alt={user.name}
                  className="w-full h-full"
                />
              ) : (
                user.name[0]
              ))}
            {!user?.extra.avatar_type &&
              (!!user?.extra.avatar ? (
                <Avatar className="w-full h-full" {...user.extra.avatar} />
              ) : (
                user?.name[0]
              ))}
          </div>
          <div className="space-y-3">
            <div className="text-lg md:text-2xl text-white text-center font-bold">
              {user?.name} <span className="font-thin">({user?._id})</span>
            </div>
            {userInfo && (
              <div className="md:text-xl font-thin">
                <span>{userInfo?.grupo?.nome}</span>{' '}
                <span>({userInfo?.grupo?.total} funci)</span>{' '}
                <span className="font-bold text-md p-1 px-4 rounded-2xl bg-maastricht-blue text-electric-blue">
                  {userInfo?.ro}
                </span>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="w-full flex flex-col space-y-5 mt-10">
        <div className="rounded-xl overflow-hidden  items-center justify-between mb-12 flex">
          <button
            className={`${
              activeTab === 0 ? 'bg-slate-blue' : 'bg-maastricht-blue'
            } w-6/12 h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
            onClick={() => setActiveTab(0)}
          >
            Reconhecimentos recebidos
          </button>
          <button
            className={`${
              activeTab === 1 ? 'bg-slate-blue' : 'bg-maastricht-blue'
            } w-6/12 h-[86px] text-2xl text-white border-0 font-semibold flex items-center justify-center`}
            onClick={() => setActiveTab(1)}
          >
            Participação em missões
          </button>
        </div>

        {activeTab === 0 && (
          <Box>
            <div className="space-y-5 lg:p-3">
              <h3 className="text-2xl mb-3">
                Reconhecimentos recebidos{' '}
                <b className="text-yellow">
                  {list ? `(${list?.length})` : null}
                </b>
              </h3>

              {loadingList ? (
                <div className="space-y-2">
                  <ArticlePlaceholder />
                  <ArticlePlaceholder />
                </div>
              ) : null}

              {!loadingList ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {list?.map((item: any) => (
                    <RewardReviewItem data={item} key={item._id} />
                  ))}
                </div>
              ) : null}
            </div>
          </Box>
        )}

        {activeTab === 1 && !!id && <MissaoDestaquePorUsuario id={id} />}
      </div>

      {showModal && !!user && (
        <ChangeGestor
          player={user}
          closeModal={() => setShowModal(false)}
          onSubmit={() => {
            setShowModal(false);
            onClose();
          }}
        />
      )}
    </div>
  );
}
