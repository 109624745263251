import { api } from './api';

const tokenPublicStaging =
  'Basic NjM1YWUwNmY4Y2Y1OGU0MTFlZWIwYjhlOjYzNGQyN2QwOTI3MWYzMzdiMzlmMTQ1OQ==';
const tokenPublicProd =
  'Basic NjMyYjU2YzE1NDVkZDA3Njc2NjIxNzA5OjYzNGQyN2QwOTI3MWYzMzdiMzlmMTQ1OQ==';

export async function getFaq(): Promise<any[]> {
  try {
    const { data } = await api.post(
      `database/faq__c/aggregate`,
      [
        {
          $project: {
            _id: 1,
            order: 1,
            title: 1,
          },
        },
        {
          $sort: {
            order: 1,
          },
        },
      ],
      {
        headers: {
          Authorization: !!window.location.href.match(
            /(funifier\.app|localhost)/gi
          )
            ? tokenPublicStaging
            : tokenPublicProd,
        },
      }
    );

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

export async function getFaqContent(_id: string): Promise<string> {
  try {
    const { data } = await api.post(
      `database/faq__c/aggregate`,
      [
        {
          $match: {
            _id,
          },
        },
        {
          $project: {
            _id: 0,
            content: 1,
          },
        },
      ],
      {
        headers: {
          Authorization: !!window.location.href.match(
            /(funifier\.app|localhost)/gi
          )
            ? tokenPublicStaging
            : tokenPublicProd,
        },
      }
    );

    if (!Array.isArray(data)) {
      return '';
    } else {
      return data.length ? data[0].content : '';
    }
  } catch (e) {
    throw e;
  }
}
