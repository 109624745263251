import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';

import Button from 'components/ui/button';
import { ModalContext } from 'context/ModalContext';
import { getChallenges } from 'services/challenges.service';
import { useAuth } from 'context/AuthContext';

export function SeloHelpModal(props: any) {
  // const modalID = "achievement";

  const modalID = 'help-selo-bb';

  const [modalIsOpen, setIsOpen] = useState(false);

  const { openModal, closeModal } = useContext(ModalContext);

  const { user } = useAuth();

  const [selosBB, setSelosBB] = useState<any>();

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    async function loadChallenges() {
      try {
        const data = await getChallenges(`'extra.type':'selobb'`);
        const currentSelosBB = data.filter((item: any) => {
          return user?.challenges[item._id] >= 1;
        });
        setSelosBB(currentSelosBB);
      } catch (e) {}
    }

    if (!user) return;

    loadChallenges();
  }, [user]);

  useEffect(() => {
    setIsOpen(openModal === modalID);
  }, [openModal]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="max-w-[800px] max-h-[80vh] md:md-h-auto overflow-y-auto bg-blue-dark text-white rounded-xl py-10 px-5 md:px-10 flex flex-col items-center space-y-2 flex-none mx-5">
              <div className="text-center mb-3">
                <h1 className="text-yellow text-2xl mb-2">Selo BB</h1>
                <p>
                O selo é o reconhecimento a quem é destaque na Unidade nos pilares Eficiência, Resultado ou Protagonismo.
                 </p><p>
                  É concedido pelo Comitê da Unidade e <b className="text-yellow">vale 1 bônus de 500 pontos</b>.
                </p>

                {selosBB && selosBB.length ? (
                  <div className="flex flex-col md:flex-row items-center justify-center py-3 space-y-5 md:space-y-0 md:space-x-5">
                    {selosBB.map((item: any) => (
                      <div key={item._id} className="flex flex-col items-center justify-around text-center space-y-4">
                        <img src={item.badgeUrl} className="w-64" alt={item.challenge} />
                        <p className='md:text-lg font-bold text-yellow border border-yellow rounded-full p-2 px-4'>{item.challenge}</p>
                        <div className='text-yellow'>{item.description}</div>
                      </div>
                    ))}
                  </div>
                ) : null}

                {!selosBB || !selosBB.length ? (
                  <>
                    <p className="border border-yellow rounded-full p-1">
                      Atenção: o participante pode ser indicado para mais de 1 pilar, mas só poderá receber 1 bônus.
                    </p>
                  </>
                ) : null}
              </div>
              <Button onClick={() => closeModal()}>FECHAR</Button>
            </div>
          </div>
        )}
      />
    </>
  );
}
