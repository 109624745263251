import { api } from './api';

export async function getAchievements(filters?: any[], limit = 100) {
  let payload: any[] = [];

  if (filters) {
    payload.concat(filters);
  }

  payload.push({
    $sort: {
      time: -1,
    },
  });

  if (limit) {
    payload.push({
      $limit: limit,
    });
  }

  const { data } = await api.post(`database/achievement/aggregate`, payload);
  if (!Array.isArray(data)) {
    return [];
  } else {
    return data;
  }
}

export async function getPointsHistory(
  player?: string,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/extrato_pontos', payload, {
      headers: { Range: 'items=0-1000' },
    });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getPointsSummary(
  player?: string,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/extrato_resumo', payload, {
      headers: { Range: 'items=0-1000' },
    });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data[0];
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getReconhecimentos(
  player?: string,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/resultado_reconhecimento', payload, {
      headers: { Range: 'items=0-1000' },
    });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getDestaqueCultura(
  player?: string,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };
    const { data } = await api.post('find/resultado_cultura', payload, {
      headers: { Range: 'items=0-1000' },
    });
    // console.log('getKPIHistorico', data);

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    // console.log('ERROR: getKPIHistorico ', e);
    throw e;
  }
}

export async function getMissaoGestor(player?: string, start?: any, end?: any) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };

    const { data } = await api.post('find/extrato_missao_gestor', payload, {
      headers: { Range: 'items=0-1000' },
    });

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

export async function getMissaoGestorByPeriod(
  player?: string,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };

    const { data } = await api.post(
      'find/extrato_missao_gestor_period',
      payload,
      { headers: { Range: 'items=0-1000' } }
    );

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

export async function getMissaoEquipe(player?: string, start?: any, end?: any) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };

    const { data } = await api.post('find/extrato_missao_equipe', payload, {
      headers: { Range: 'items=0-1000' },
    });

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

export async function getMissaoEquipeFeedback(
  player?: string,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };

    const { data } = await api.post(
      'find/extrato_missao_gestor_feedback',
      payload,
      { headers: { Range: 'items=0-1000' } }
    );

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}

export async function getMissaoEquipeByTime(
  player?: string,
  start?: any,
  end?: any
) {
  try {
    const payload = {
      player,
      start: start ? start : new Date(2023, 0, 1),
      end: end ? end : new Date(),
    };

    const { data } = await api.post(
      'find/extrato_missao_equipe_period',
      payload,
      { headers: { Range: 'items=0-1000' } }
    );

    if (!Array.isArray(data)) {
      return [];
    } else {
      return data;
    }
  } catch (e) {
    throw e;
  }
}
