import Box from 'components/ui/box';
import { useEffect, useState } from 'react';
import { AiFillLeftCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { getManifest, IManifest } from 'services/manifest.service';


import "./styles.scss";

export function Manifesto() {
  const [manifest, setManifest] = useState<IManifest>();
  const [load, setLoad] = useState<boolean>(true);

  useEffect(() => {
    async function loadData() {
      const data = await getManifest();
      if (data) {
        setManifest(data);
      }
      setLoad(false);
    }

    loadData();
  }, []);

  return (
    <div className=" mx-auto container p-3 lg:p-10">
      <div className="flex flex-ro items-center space-x-3 lg:space-x-6 mb-3 lg:mb-10">
        <div>
          <Link to="../">
            <AiFillLeftCircle size={48} className="text-purple" />
          </Link>
        </div>

        <h1 className="text-xl lg:text-3xl ">Manifesto</h1>
      </div>

      <Box>
        {load && <div>Carregando...</div>}
        {!load && (
          <div className="manifesto space-y-5 lg:p-3">
            <div className="text-lg text-white p-3 space-y-5">
              {manifest?.text && (
                <div dangerouslySetInnerHTML={{ __html: manifest.text }}></div>
              )}
            </div>
          </div>
        )}
      </Box>
    </div>
  );
}
