/* eslint-disable react-hooks/exhaustive-deps */
import { RewardReviewItem } from 'components/reward-review-item';
import ArticlePlaceholder from 'components/ui/article-placeholder';
import Box from 'components/ui/box';
import { useEffect, useState } from 'react';
import { AiFillLeftCircle } from 'react-icons/ai';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getKPIHistory, getPlayerInfo } from 'services/dashboard.service';
import { getUserProfile } from 'services/profile.service';
// import Avatar from 'funifier-nice-avatar';
import { GetBehaviorLabel } from 'utils/strings';
import {
  SeloColaboracao,
  SeloFocoNoCliente,
  SeloInovacao,
  SeloResolutividade,
} from 'components/icons';

const behaviors: any = {
  colaboracao: <SeloColaboracao size={'100%'} />,
  inovacao: <SeloInovacao size={'100%'} />,
  foco: <SeloFocoNoCliente size={'100%'} />,
  resolutividade: <SeloResolutividade size={'100%'} />,
};
export function DestaquesCulturaDetail() {
  const params = useParams();
  const navigate = useNavigate();
  // const { user } = useAuth();

  const [user, setUser] = useState<any>();
  const [userInfo, setUserInfo] = useState<any>();

  const [list, setList] = useState<unknown[]>();

  // const [loading, setLoading] = useState();
  const [loadingList, setLoadingList] = useState(true);

  useEffect(() => {
    if (
      !params.id ||
      params.id === undefined ||
      !params.behavior ||
      params.behavior === undefined
    ) {
      navigate('../');
      return;
    }

    async function loadPlayerInfo() {
      if (!params.id) return;

      const userProfile = await getUserProfile(params.id);
      const playerInfo = await getPlayerInfo(params.id);
      setUserInfo(playerInfo);
      setUser(userProfile);

      // const isGestor = playerInfo.tipo_ro === 'GERENCIAL' || playerInfo.funcao === 'DIRETOR';

      // if (isGestor) {
      //   const dataGestor = await getMissaoGestor(params.id);
      //   const dataEquipe = await getMissaoEquipe(params.id);
      //   setMissionList([...dataGestor, ...dataEquipe]);
      // } else {
      //   const data = await getMissaoEquipe(params.id);
      //   setMissionList(data);
      // }

      // setLoadingMissionList(false);

      loadReconhecimentos();
    }

    async function loadReconhecimentos() {
      const result = await getKPIHistory(params.id);
      const filteredData = result.filter((item: any) => {
        return item.behavior === params.behavior;
      });
      setList(filteredData);
      setLoadingList(false);
    }

    loadPlayerInfo();
    // loadReconhecimentos();

    window.scrollTo(0, 0);
  }, [params]);

  return (
    <div className="mx-auto container p-3 lg:p-10">
      <div className="flex flex-row items-center space-x-3 lg:space-x-10 mb-3 lg:mb-10">
        <div>
          <Link to="../">
            <AiFillLeftCircle size={48} className="text-yellow" />
          </Link>
        </div>
        <h1 className="text-lg lg:text-3xl bg-blue-dark py-1 px-6 rounded-full border border-yellow">
          {params.behavior ? (
            <span>{GetBehaviorLabel(params.behavior)}</span>
          ) : (
            <span>Destaques da Cultura BB</span>
          )}
        </h1>
      </div>

      {userInfo ? (
        <div className="flex flex-col items-center space-y-3 mb-5">
          <div className="w-32 h-32 md:w-64 md:h-64 bg-purple flex items-center justify-center rounded-full overflow-hidden text-3xl md:text-6xl">
            {/* {!!user.extra.avatar_type &&
              (user.image ? <img src={user.image.medium.url} alt={user.name} className="w-full h-full" /> : user.name[0])}
            {!user.extra.avatar_type &&
              (!!user.extra.avatar ? <Avatar className="w-full h-full" {...user.extra.avatar} /> : user.name[0])} */}
            {behaviors[`${params?.behavior}`]}
          </div>
          <div className="text-lg md:text-2xl text-yellow text-center">
            {user?.name}
          </div>
          {userInfo ? (
            <div className="md:text-lg border py-1 px-3 rounded-full">
              <span>{user?._id}</span> • <span>{userInfo?.prefixo}</span> •{' '}
              <span>{userInfo?.ro}</span>
            </div>
          ) : null}
        </div>
      ) : null}

      <div className="w-full flex flex-col space-y-5">
        {/* <Box>
          <div className="space-y-5 lg:p-3">
            <h3 className="text-2xl mb-3">
              Realização
              <br />
              <span className="text-xl lg:text-3xl font-bold text-yellow ">Destaque</span>
            </h3>

            {loadingMissionList ? (
              <div className="space-y-2">
                <ArticlePlaceholder />
                <ArticlePlaceholder />
              </div>
            ) : null}

            {!loadingMissionList ? (
              <div className="space-y-2">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                  {missionList?.map((item: any) => (
                    <PointReviewItem data={item} key={item._id} />
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </Box> */}

        <Box>
          <div className="space-y-5 lg:p-3">
            <h3 className="text-2xl mb-3">
              Reconhecimentos
              <br />
              <span className="text-xl lg:text-3xl font-bold text-yellow ">
                recebidos {list ? `(${list?.length})` : null}
              </span>
            </h3>

            {loadingList ? (
              <div className="space-y-2">
                <ArticlePlaceholder />
                <ArticlePlaceholder />
              </div>
            ) : null}

            {!loadingList ? (
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                {list?.map((item: any) => (
                  <RewardReviewItem data={item} key={item._id} />
                ))}
              </div>
            ) : null}
          </div>
        </Box>
      </div>
    </div>
  );
}
