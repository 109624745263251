import Tippy from '@tippyjs/react';
import { UserAvatar } from 'components/user-avatar';
import { useAuth } from 'context/AuthContext';
import { format, utcToZonedTime } from 'date-fns-tz';
import { ptBR } from 'date-fns/locale';
import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BiCoinStack,
  BiInfoCircle,
  BiReset,
  BiStopCircle,
  BiUpvote,
} from 'react-icons/bi';
import { FiEdit3 } from 'react-icons/fi';
import { HiOutlineHandThumbUp, HiOutlineHandThumbDown } from 'react-icons/hi2';
import { useNavigate } from 'react-router';
import {
  GetManagerStepLabel,
  GetManagerTypeLabel,
  GetTaskLevelLabel,
} from 'utils/strings';
import { useModal } from 'context/ModalContext';
import { RiMessage2Line } from 'react-icons/ri';
import {
  deleteTask,
  getPlayerInfo,
  playerHasFeedback,
  submitFeedback,
} from 'services/dashboard.service';
import { TfiMedallAlt } from 'react-icons/tfi';
import { BsTrash } from 'react-icons/bs';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { CyclesInterface } from 'types/user';
import { Countdown } from 'components/countdown';
// import { updateTask } from 'services/dashboard.service';

interface TaskItemProps {
  data: any;
  playerInfo?: any;
  editable?: boolean;
  canEvaluate?: boolean;
  manager?: boolean;
  managerType?: string;
  promote?: boolean;
  double?: boolean;
  reject?: boolean;
  reset?: boolean;
  cycle?: CyclesInterface;
  activeEvaluationStep?: number | null;
  taskStep?: number | undefined;
  playerStep?: number | undefined;
  onPromote?: (task: any) => void;
  onDouble?: (task: any) => void;
  onAccept?: (task: any) => void;
  onReject?: (task: any) => void;
  onClearStatus?: (task: any, status: any) => void;
  onDelete?: (task: any) => void;
  onFeedback?: (task: any) => void;
  onStop?: (task: any) => void;
  onReset?: (task: any) => void;
}

export function TaskItemView(props: TaskItemProps) {
  const { showModal, closeModal } = useModal();
  const { user } = useAuth();
  const navigate = useNavigate();
  const {
    data,
    editable,
    canEvaluate,
    manager,
    managerType,
    promote,
    double,
    reset,
    playerInfo,
    cycle,
    activeEvaluationStep,
    taskStep,
    playerStep,
    onPromote,
    onDouble,
    onAccept,
    onReject,
    onDelete,
    onFeedback,
    onStop,
    onReset,
  } = props;

  const [status, setStatus] = useState<any>();
  const [points, setPoints] = useState(0);
  const [showFeedbacks, setShowFeedbacks] = useState(false);
  // const [hasFeedback, setHasFeedback] = useState<boolean | undefined>();
  const [realPlayerInfo, setRealPlayerInfo] = useState<any>();

  const isVacationManager = useMemo(
    () => !!playerInfo && !!user && playerInfo._id !== user.extra.id,
    [playerInfo, user]
  );

  function getUserDistribution(_id: string) {
    try {
      return data.team.find((item: any) => item._id === _id).percent;
    } catch (e) {
      return 0;
    }
  }

  function verifyFeedback() {
    if (
      !Array.isArray(data.feedback) ||
      !data.feedback.find(
        (x: { evaluatorId: any }) => x.evaluatorId === realPlayerInfo.chave
      )
    ) {
      closeModal();
      setTimeout(() => {
        showModal(`validation-feedback-${data._id}`);
      }, 150);
    }
  }

  async function handleReset(task: any) {
    if (onReset && !!task && task._id) {
      onReset(task);
    }
  }

  async function handleAccept() {
    // if (status) return;

    // if (!confirm('Você confirma que deseja validar esta entrega?')) return;

    // const updatedData = {...data, status : "aprovado", team: [...data.team,{_id:user._id}]}
    // await updateTask(updatedData);
    if (onAccept) {
      onAccept(data);
      setStatus('aprovado');
    }
  }

  async function handlePromote() {
    // if (status) return;

    // if (!confirm('Você confirma que deseja promover esta entrega?')) return;

    if (onPromote) {
      onPromote(data);
      setStatus('próximo nível');
    }
  }

  async function handleDouble() {
    // if (status) return;

    // if (!confirm('Você confirma que deseja dobrar esta entrega?')) return;

    if (onDouble) {
      onDouble(data);
      setStatus('dobrado');
    }
  }

  async function handleReject() {
    // if (status) return;

    // if (!confirm('Você confirma que deseja devolver esta entrega?')) return;

    // const updatedData = {...data, status : "rejeitado"}
    // await updateTask(updatedData);
    if (onReject) {
      onReject(data);
      setStatus('rejeitado');
    }
  }

  function getFormattedDate(value: any) {
    try {
      return format(utcToZonedTime(value, 'GMT'), 'dd/MM/yy');
    } catch (e) {
      return '---';
    }
  }

  const pitStopCanEvaluate = useMemo(() => {
    if (manager && canEvaluate && reset) {
      if (
        !data.prosseguir_idGestor ||
        !data.prosseguir_gst ||
        data.prosseguir_idGestor !== playerInfo._id
      )
        return false;

      if (data.prosseguir_gst === 'next') {
        return true;
      }

      return false;
    } else {
      return false;
    }
  }, [manager, canEvaluate, reset, data, playerInfo]);

  function isCurrentEvaluator() {
    if (!playerInfo || !data.evaluator || !data.evaluator.length) return false;

    const matriculaEvaluator = parseInt(
      data.evaluator[data.evaluator.length - 1].replace(/F(0+)?/g, '')
    );

    const currentId = parseInt(playerInfo._id);

    return matriculaEvaluator === currentId && data.status === 'promovido';
  }

  const canViewBoxActions = (): boolean => {
    if (manager && canEvaluate) {
      if (!reset) return false;

      if (!promote && !double && status === 'aprovado') {
        return true;
      }

      if (status === 'rejeitado') {
        return true;
      }

      if (!data.level && (!status || status === 'aguardando')) {
        return true;
      }

      if (managerType !== 'equipe') {
        if (double) {
          return true;
        }

        if (promote) {
          return true;
        }

        return false;
      }

      return false;
    }

    return false;
  };

  const getEvaluatorData = (item: any) => {
    if (item.evaluator_detail?.length) {
      const evaluator = item.evaluator_detail.find(
        (x: { _id: any }) => x._id === item.evaluator[item.evaluator.length - 1]
      );

      return !!evaluator ? evaluator : undefined;
    }

    return undefined;
  };

  const evaluationData = useMemo(() => {
    if (
      !cycle ||
      !activeEvaluationStep ||
      !taskStep ||
      !playerStep ||
      cycle.status !== 'evaluation'
    )
      return <></>;

    const date_finish =
      activeEvaluationStep === 2
        ? cycle.date_evaluation_step2_finish
        : activeEvaluationStep === 3
        ? cycle.date_evaluation_step3_finish
        : activeEvaluationStep === 4
        ? cycle.date_evaluation_step4_finish
        : activeEvaluationStep === 4.1
        ? cycle.date_evaluation_pitStop_finish
        : cycle.date_evaluation_step5_finish;

    // Se já terminou o prazo e concluiu a validação
    if (
      !reset &&
      (playerStep as number) < activeEvaluationStep &&
      (taskStep as number) > (playerStep as number)
    ) {
      return (
        <div className="msg-countdown">
          <div>
            <p>Você concluiu a apreciação dessa realização.</p>
          </div>
        </div>
      );
    }

    // Se já terminou o prazo e não concluiu a validação
    else if (
      !reset &&
      (playerStep as number) < activeEvaluationStep &&
      (taskStep as number) <= (playerStep as number)
    ) {
      return (
        <div className="msg-countdown">
          <div>
            <p>O período para apreciação dessa realização se encerrou.</p>
          </div>
        </div>
      );
    }

    // Se está na etapa atual
    else if (!!reset && (playerStep as number) === activeEvaluationStep) {
      return (
        <div className="msg-countdown actual">
          <div>
            <p>
              O período para apreciação dessa realização termina em:{' '}
              <strong className="whitespace-nowrap">
                <Countdown deadline={new Date(date_finish)} />
              </strong>
            </p>
          </div>
        </div>
      );
    }

    // Ele é etapa posterior e o período vai chegar
    else if (
      (playerStep as number) > activeEvaluationStep &&
      (taskStep as number) > activeEvaluationStep
    ) {
      const date_start_evaluation =
        playerStep === 2
          ? cycle.date_evaluation_step2_start
          : playerStep === 3
          ? cycle.date_evaluation_step3_start
          : playerStep === 4
          ? cycle.date_evaluation_step4_start
          : playerStep === 4.1
          ? cycle.date_evaluation_pitStop_start
          : playerStep === 5
          ? cycle.date_evaluation_step5_start
          : null;

      return (
        <div className="msg-countdown">
          <div>
            <p>
              O período para apreciação dessa realização inicia em
              {!!date_start_evaluation ? (
                <>
                  :{' '}
                  <strong className="whitespace-nowrap">
                    <Countdown deadline={new Date(date_start_evaluation)} />
                  </strong>
                </>
              ) : (
                ' breve.'
              )}
            </p>
          </div>
        </div>
      );
    }
  }, [activeEvaluationStep, cycle, playerStep, reset, taskStep]);

  const stepLabel = useMemo(() => {
    if (
      !playerInfo ||
      !taskStep ||
      typeof playerInfo.cod_etapa !== 'number' ||
      playerInfo.cod_etapa < 1 ||
      playerInfo.cod_etapa > 5
    ) {
      return '---';
    }

    if (taskStep === 4.1) {
      return 'Etapa 4';
    } else {
      return `Etapa ${taskStep}`;
    }
  }, [playerInfo, taskStep]);

  useEffect(() => {
    async function loadRealUser() {
      const playerInfoResult = await getPlayerInfo(user?._id);

      if (playerInfoResult) {
        setRealPlayerInfo(playerInfoResult);
      }
    }

    if (!realPlayerInfo && !!playerInfo && playerInfo.cod_etapa > 0) {
      loadRealUser();
    }

    if (data && user) {
      setStatus(data.status);

      if (data.multiplicador) {
        setPoints(data.multiplicador * 300);
      }
    }
  }, [data, user, playerInfo, realPlayerInfo]);

  return (
    <>
      <div
        className="task-item bg-maastricht-blue-3 rounded-[20px] text-white py-6 px-3 lg:p-8"
        key={data._id + '_task'}
      >
        <div className="relative flex flex-col lg:flex-row items-center justify-between space-y-2 lg:space-y-0 lg:space-x-3 pb-5 mb-5 border-b-2 border-prussian-blue">
          <div className="flex justify-between w-full flex-col-reverse lg:flex-row">
            <div className="flex-1 flex items-center space-x-3">
              <div>
                <UserAvatar data={data.owner_avatar} />
              </div>
              <Tippy content={data.owner_name}>
                <div>
                  <p className="text-white text-base font-thin leading-none flex items-center space-x-1">
                    <strong className="font-semibold">
                      {data.owner_name.split(' ')[0]}
                    </strong>{' '}
                    <span>({data.owner})</span>{' '}
                    <span className="hidden lg:block alice-blue">
                      cadastrou
                    </span>
                  </p>
                  <span className="font-thin lg:hidden">cadastrou:</span>
                  <p className="text-police-blue text-xs font-light leading-none mt-2">
                    {`Data do cadastro: ${format(
                      new Date(data.created),
                      'd MMM. yy',
                      {
                        locale: ptBR,
                      }
                    )}`}
                    &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
                    {!!data.updated
                      ? `Última atualização: ${format(
                          new Date(data.updated),
                          'd MMM. yy',
                          {
                            locale: ptBR,
                          }
                        )}`
                      : ''}
                  </p>
                </div>
              </Tippy>
            </div>
          </div>
        </div>

        <div className="space-y-3 lg:space-y-6">
          {!!managerType && !!playerInfo && (
            <div className="space-y-3 lg:space-y-0 flex flex-col lg:flex-row justify-between">
              <div>
                <p className="text-sm font-thin text-electric-blue">
                  Fluxo de Apreciação:
                </p>
                <p className="text-base font-normal text-white m-0">
                  {stepLabel}
                </p>
              </div>
              <div>
                <p className="text-sm font-thin text-electric-blue">
                  Prefixo desta entrega:
                </p>
                <p className="text-base font-normal text-white m-0">
                  {data.prefixo}
                </p>
              </div>
            </div>
          )}

          <div>
            <p className="text-sm font-thin text-electric-blue">
              Nome da realização
            </p>
            <p className="text-base font-normal text-white m-0">
              {data.title || '---'}
            </p>
          </div>

          <div>
            <p className="text-sm font-thin text-electric-blue">
              Detalhes da realização
            </p>
            <p
              className="text-base font-normal text-white m-0"
              dangerouslySetInnerHTML={{
                __html:
                  typeof data.description === 'string'
                    ? data.description
                        .replace(/\n/gi, '<br>')
                        .replace(/\t/gi, '&nbsp;&nbsp;&nbsp;&nbsp;')
                    : '',
              }}
            />
          </div>

          <div className="space-y-3 lg:space-y-0 flex flex-col lg:flex-row justify-between">
            <div className="lg:w-1/3">
              <p className="text-sm font-thin text-electric-blue">
                Período da entrega
              </p>
              <p className="text-base font-normal text-white m-0">
                {getFormattedDate(data.start)} a {getFormattedDate(data.end)}
              </p>
            </div>
            {!!data.nec?.length && (
              <div className="lg:w-1/3">
                <p className="text-sm font-thin text-electric-blue">
                  NEC - Necessidade de TI
                </p>
                <p className="text-base font-normal text-white m-0">
                  {data.nec}
                </p>
              </div>
            )}
          </div>

          <div className="space-y-3 lg:space-y-0 flex flex-col-reverse lg:flex-row justify-between">
            <div className="lg:w-1/3">
              <p className="text-sm font-thin text-electric-blue">
                Status da entrega
              </p>
              <p className="text-base font-normal text-white m-0">
                {GetTaskLevelLabel(
                  isCurrentEvaluator() ? 'aguardando' : data.status
                )}
              </p>
            </div>

            {data.evaluator &&
            data.evaluator.length > 0 &&
            !isNaN(Number(data.evaluator[data.evaluator.length - 1])) &&
            !!getEvaluatorData(data) &&
            data.status !== 'concluido' &&
            data.status !== 'selo' ? (
              <div className="lg:w-1/3">
                <p className="text-sm font-thin text-electric-blue">
                  Sendo avaliado por
                </p>
                <p className="text-base font-normal text-white m-0">
                  {getEvaluatorData(data).chave} -{' '}
                  {getEvaluatorData(data).nome_guerra}
                </p>
              </div>
            ) : null}

            <div className="lg:w-1/3">
              <p className="text-sm font-thin text-electric-blue">
                Pontos a receber
              </p>
              <p className="text-base font-normal text-white m-0">{points}</p>
            </div>
          </div>

          {!!data.feedback?.length &&
            data.feedback
              .sort((a: any, b: any) => b.etapa - a.etapa)
              .map((feedback: any, index: number) => (
                <div key={feedback._id + '_feedback'}>
                  {index === 0 && (
                    <button
                      onClick={() => setShowFeedbacks(!showFeedbacks)}
                      className="text-electric-blue flex items-center space-x-2 cursor-pointer"
                    >
                      <p className="text-sm font-thin text-electric-blue">
                        Feedback do gestor
                      </p>

                      {showFeedbacks ? (
                        <IoIosArrowDown size={20} />
                      ) : (
                        <IoIosArrowUp size={20} />
                      )}
                    </button>
                  )}

                  {showFeedbacks && (
                    <>
                      <Tippy
                        content={`${feedback.evaluator_nome} (${feedback.evaluatorId})`}
                      >
                        <p className="text-base font-light text-white m-0 w-fit">
                          Etapa {feedback.etapa}: Avaliado por{' '}
                          {feedback.evaluator_nome.split(' ')[0]}
                        </p>
                      </Tippy>
                      <p className="text-base font-light text-white m-0 italic">
                        {feedback.message}
                      </p>
                    </>
                  )}
                </div>
              ))}

          {!!data.target?.length && (
            <div className="space-y-3 lg:space-y-4 flex flex-col w-full">
              <p className="text-base font-semibold leading-none m-0 text-yellow">
                Participantes da realização
              </p>

              {data.target.map((player: any) => (
                <div
                  key={player._id + '_target'}
                  className="flex flex-col lg:flex-row lg:items-center bg-oxford-blue py-3 px-8 space-y-2 lg:space-y-0 lg:space-x-4 rounded-lg"
                >
                  <div className="flex-1 flex flex-row items-center text-white space-x-3">
                    <UserAvatar data={{ ...player, ...player.extra }} />
                    <Tippy content={player.name}>
                      <span className="text-base font-thin text-white m-0">
                        <span className="font-light">
                          {player.name.split(' ')[0]}
                        </span>{' '}
                        ({player._id})
                      </span>
                    </Tippy>
                  </div>
                  <div className="bg-maastricht-blue px-4 py-2 text-xs font-thin leading-none text-yellow rounded-full flex items-center">
                    {getUserDistribution(player._id) || '0'}% de atuação
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {canViewBoxActions() || pitStopCanEvaluate ? (
          <>
            <div className="w-full border-t-2 border-prussian-blue mt-4 pt-4 lg:mt-6 lg:pt-6 block">
              {pitStopCanEvaluate ? (
                <h3 className="text-electric-blue text-base leading-none mb-7 font-light block text-center">
                  Essa Realização Destaque foi promovida pela sua Rede.
                </h3>
              ) : null}

              <h3 className="text-alice-blue text-base leading-none mb-7 font-normal block text-center">
                O que deseja fazer com essa Realização Destaque?
              </h3>

              <div className="flex flex-row space-x-16 justify-center items-center">
                {!promote && !double && status === 'aprovado' ? (
                  <>
                    <button
                      className={`cursor-default text-opacity-60 text-[#04D475] space-x-2 inline-flex justify-center items-center uppercase leading-none text-sm`}
                    >
                      <HiOutlineHandThumbUp className="text-xl" />{' '}
                      <span className="uppercase leading-none text-sm font-normal">
                        Validada
                      </span>
                    </button>
                  </>
                ) : null}

                {status === 'rejeitado' ? (
                  <>
                    <button
                      className={`cursor-default text-opacity-60 text-[#ED2B48] space-x-2 inline-flex justify-center items-center uppercase leading-none text-sm`}
                    >
                      <HiOutlineHandThumbDown className="text-xl" />{' '}
                      <span className="uppercase leading-none text-sm font-normal">
                        Devolvida
                      </span>
                    </button>
                  </>
                ) : null}

                {!data.level && (!status || status === 'aguardando') && (
                  <>
                    <button
                      onClick={() => showModal(`validation-accept-${data._id}`)}
                      className={`text-[#04D475] space-x-2 inline-flex justify-center items-center uppercase leading-none text-sm`}
                    >
                      <HiOutlineHandThumbUp className="text-xl" />{' '}
                      <span className="uppercase leading-none text-sm font-normal">
                        Validar
                      </span>
                    </button>
                    <button
                      onClick={() => showModal(`validation-reject-${data._id}`)}
                      className={`text-[#ED2B48] space-x-2 inline-flex justify-center items-center uppercase leading-none text-sm`}
                    >
                      <HiOutlineHandThumbDown className="text-xl" />{' '}
                      <span className="uppercase leading-none text-sm font-normal">
                        Devolver
                      </span>
                    </button>
                  </>
                )}

                {managerType !== 'equipe' ? (
                  <>
                    {promote || double || pitStopCanEvaluate ? (
                      <>
                        {double ? (
                          <Tippy
                            arrow={false}
                            className="bg-blue-light drop-shadow-lg text-blue-dark"
                            allowHTML={true}
                            content={
                              'Podem ser bonificadas até 40% do total de entregas validadas (com arredondamento para cima)'
                            }
                          >
                            <button
                              onClick={() =>
                                showModal(`validation-double-${data._id}`)
                              }
                              className={`text-[#04D475] space-x-2 inline-flex justify-center items-center uppercase leading-none text-sm`}
                            >
                              <BiCoinStack className="text-xl" />{' '}
                              <span className="uppercase leading-none text-sm font-normal">
                                Bonificar
                              </span>
                            </button>
                          </Tippy>
                        ) : null}

                        {promote || pitStopCanEvaluate ? (
                          <>
                            {pitStopCanEvaluate ? (
                              <Tippy
                                arrow={false}
                                className="bg-yellow drop-shadow-lg text-blue-dark"
                                allowHTML={true}
                                content={
                                  'Você deve avaliar se essa entrega será encerrada nesta etapa.'
                                }
                              >
                                <button
                                  onClick={() =>
                                    showModal(`validation-stop-${data._id}`)
                                  }
                                  className={`text-yellow space-x-2 inline-flex justify-center items-center uppercase leading-none text-sm`}
                                >
                                  <BiStopCircle className={`text-xl`} />{' '}
                                  <span className="uppercase leading-none text-sm font-normal">
                                    Encerrar
                                  </span>
                                </button>
                              </Tippy>
                            ) : null}

                            <Tippy
                              arrow={false}
                              className="bg-yellow drop-shadow-lg text-blue-dark"
                              allowHTML={true}
                              content={
                                pitStopCanEvaluate
                                  ? 'Você deve avaliar se essa entrega irá prosseguir à Etapa 5'
                                  : 'Podem ser promovidas até 10% do total de entregas validadas (com arredondamento para cima)'
                              }
                            >
                              <button
                                onClick={() =>
                                  managerType && managerType === 'diretoria'
                                    ? showModal(`validation-selo-${data._id}`)
                                    : showModal(
                                        `validation-promote-${data._id}`
                                      )
                                }
                                className={`text-[#04D475] space-x-2 inline-flex justify-center items-center uppercase leading-none text-sm`}
                              >
                                {managerType && managerType === 'diretoria' ? (
                                  <TfiMedallAlt className="text-xl" />
                                ) : (
                                  <BiUpvote
                                    className={`text-xl ${
                                      pitStopCanEvaluate
                                        ? 'rotate-90 transform'
                                        : ''
                                    }`}
                                  />
                                )}{' '}
                                <span className="uppercase leading-none text-sm font-normal">
                                  {managerType && managerType === 'diretoria'
                                    ? 'Indicar Selo'
                                    : pitStopCanEvaluate
                                    ? 'Prosseguir'
                                    : 'Promover'}
                                </span>
                              </button>
                            </Tippy>
                          </>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>
          </>
        ) : null}
      </div>
    </>
  );
}
