import { UIElementProps } from "types/prop-types";

type ButtonProps = UIElementProps & {
	children: React.ReactNode;
	onClick?: (e:any) => void;
	href?: string;
	className?: string;
	disabled?: boolean;
	type?: "button" | "submit" | "reset";
	tag?: "button" | "a" | "input";
	style?: React.CSSProperties;
	color?:
		| "clear"
		| "primary"
		| "secondary"
		| "success"
		| "danger"
		| "warning"
		| "info"
		| "light"
		| "dark"
		| "outline"
		| "outlinePurple"
		| "featured";
	[key: string]: any;
};

const buttonColor: any = {
	clear: '',
	primary: "bg-purple text-white",
	secondary: "bg-blue-light text-blue-dark",
	danger: "bg-red-600 text-white ring-2 ring-white",
	success: "bg-green-600 text-white ring-2 ring-white",
	outline: "bg-transparent text-yellow ring-2 ring-yellow",
	outlinePurple: "bg-transparent text-white ring-2 ring-purple",
	featured: "bg-yellow text-blue-dark",
};

export default function Button(props: ButtonProps) {
	const { children, className, color } = props;

	return (
		<button
			{...props}
			className={`${
				buttonColor[color || "primary"]
			} hover:ring hover:ring-white rounded-md shadow-xl font-bold px-4 ${className}`}
		>
			<div className="p-1 leading-tight">{children}</div>
		</button>
	);
}
