// type Style = {
//   [key: string]: string | number | boolean
// }

import { defaultOptions, EarSize, EyeBrowStyle, EyeStyle, GlassesStyle, HairStyle, HatStyle, MouthStyle, NoseStyle, Sex, ShirtStyle } from "funifier-nice-avatar";

export interface AvatarConfig {
  sex?: Sex;
  faceColor?: string;
  earSize?: EarSize;
  hairColor?: string;
  hairStyle?: HairStyle;
  hairColorRandom?: boolean;
  hatColor?: string;
  hatStyle?: HatStyle;
  eyeStyle?: EyeStyle;
  glassesStyle?: GlassesStyle;
  noseStyle?: NoseStyle;
  mouthStyle?: MouthStyle;
  shirtStyle?: ShirtStyle;
  shirtColor?: string;
  bgColor?: string;
  isGradient?: boolean;
}

interface DefaultOptions {
  sex: Sex[],
  faceColor: string[],
  earSize: EarSize[],
  hairColor: string[],
  hairStyleMan: string[],
  hairStyleWoman: string[],
  hatColor: string[],
  hatStyle: HatStyle[],
  eyeBrowWoman: EyeBrowStyle[],
  eyeStyle: EyeStyle[],
  glassesStyle: GlassesStyle[],
  noseStyle: NoseStyle[],
  mouthStyle: MouthStyle[],
  shirtStyle: ShirtStyle[],
  shirtColor: string[],
  bgColor: string[],
  gradientBgColor: string[]
}

export const AvatarDefaultOptions: DefaultOptions = {
  ...defaultOptions,
  hairColor: ["#000", "#fff", "#fae16b", "#77311D", "#FC909F", "#D2EFF3", "#506AF4", "#F48150"],
  hatColor: ["#000", "#fff", "#77311D", "#FC909F", "#D2EFF3", "#506AF4", "#F48150"],
  shirtColor: ["#9287FF", "#6BD9E9", "#FC909F", "#F4D150", "#77311D"],
  bgColor: ["#001b2f","#002b4b",'#fbfd00','#7358c8','#dca09b',"#9287FF", "#6BD9E9", "#FC909F", "#F4D150", "#E0DDFF", "#D2EFF3", "#FFEDEF", "#FFEBA4", "#506AF4", "#F48150", "#74D153"],
  gradientBgColor: [
    "linear-gradient(45deg, #178bff 0%, #ff6868 100%)",
    "linear-gradient(45deg, #176fff 0%, #68ffef 100%)",
    "linear-gradient(45deg, #ff1717 0%, #ffd368 100%)",
    "linear-gradient(90deg, #36cd1c 0%, #68deff 100%)",
    "linear-gradient(45deg, #3e1ccd 0%, #ff6871 100%)",
    "linear-gradient(45deg, #1729ff 0%, #ff56f7 100%)",
    "linear-gradient(45deg, #56b5f0 0%, #45ccb5 100%)"
  ]
};

export function GetAvatarDefaultOptions () {
  const hairSet = new Set(AvatarDefaultOptions.hairStyleMan.concat(AvatarDefaultOptions.hairStyleWoman))
  const bgSet = new Set(AvatarDefaultOptions.bgColor.concat(AvatarDefaultOptions.gradientBgColor))
  return {
    ...AvatarDefaultOptions,
    hairStyle: Array.from(hairSet),
    bgColor: Array.from(bgSet),
  }
}