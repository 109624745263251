import { Editor } from "@tinymce/tinymce-react";
import Button from "components/ui/button";
import { useEffect, useRef, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { toast } from "react-toastify";

interface Props {
  sendMessage: (data: { message: string; id: string }) => void;
  close: () => void;
  data: { message: string; id: string };
}

export default function ModalEdit(data: Props) {
  const { sendMessage, close, data: itemData } = data;

  const [message, setMessage] = useState<string>();

  const messageRef = useRef<any>();

  function filterMessage() {
    if (message) {
      sendMessage({ message, id: itemData.id });
    }
  }

  useEffect(() => {
    if (!!itemData?.message) {
      setMessage(itemData.message);
    }
  }, [itemData]);

  return (
    <div className="user-tutorial fixed bg-black bg-opacity-40 top-0 left-0 w-screen h-screen flex justify-center items-center">
      <div className="relative border-2 border-solid border-electric-blue bg-oxford-blue rounded-lg p-4 lg:w-[60vh] w-[90vw] h-[70vh] lg:h-[400px]">
        <Button
          color="primary"
          className="absolute -right-5 -top-5  rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center z-10"
          onClick={() => close()}
        >
          <IoIosClose color="white" size={30} />
        </Button>

        <div className="h-full w-full">
          <p className="block text-xl text-white  leading-none mb-3">
            Edite o seu reconhecimento
          </p>

          <div className="h-[75%] w-full bg-transparent">
            <Editor
              apiKey="jwzkyeee3alqgkm2a3zyf9e4to1lkzlejkyp9fkcfgnyhvg3"
              value={message}
              init={{
                plugins: "emoticons",
                toolbar: "emoticons",
                toolbar_location: "bottom",
                menubar: false,
                resize: false,
                min_height: 0,
                height: "100%",
                skin: "oxide-dark",
                content_css: "/assets/editor/darkmode.css",
              }}
              onEditorChange={(newValue) => {
                setMessage(newValue);
              }}
              ref={(ref) => (messageRef.current = ref)}
            />

            <div className="flex justify-center mt-4">
              <button
                disabled={!message}
                onClick={() => filterMessage()}
                className={`leading-none disabled:opacity-50 ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white rounded py-3 px-4`}
              >
                <p className="font-bold text-sm leading-none uppercase">
                  Enviar
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
