import { useEffect, useRef, useState } from 'react';
import ReactModal from 'react-modal';
import Avatar, { genConfig } from 'funifier-nice-avatar';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import Button from 'components/ui/button';
import { useModal } from 'context/ModalContext';
import { uploadPicture, updateUserProfile } from 'services/profile.service';
import { useAuth } from 'context/AuthContext';
import { toast } from 'react-toastify';
import { BiTrash } from 'react-icons/bi';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { AvatarConfig, GetAvatarDefaultOptions } from './config';

const config = genConfig();

const avatarDefaultOptions = GetAvatarDefaultOptions() as any;

let imagePreviewTimeout: any = null;

export function AvatarModal(props: any) {
  // const modalID = "achievement";

  const { user, refreshUser } = useAuth();

  const modalID = 'avatar';

  const [loading, setLoading] = useState(false);

  const [modalIsOpen, setIsOpen] = useState(false);
  const [avatarConfig, setAvatarConfig] = useState<AvatarConfig>(config);

  const { openModal, modalData, closeModal } = useModal();

  const [avatarType, setAvatarType] = useState(0);

  const [image, setImage] = useState('');
  const editor = useRef<any>(null);

  ReactModal.setAppElement('#modal');

  function handleCloseModal() {
    setImage('');
    setLoading(false);
    setAvatarType(0);
    closeModal();
  }

  function afterOpenModal() {}

  async function handleUpload() {
    let profileImage: any = {};

    setLoading(true);

    if (image) {
      const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
      if (dataUrl) {
        const uploadResult = await uploadPicture(dataUrl);
        if (uploadResult) {
          const url = uploadResult.url;
          profileImage = {
            image: {
              small: { url },
              medium: { url },
              original: { url },
            },
          };
        }
      }
    }

    profileImage['extra'] = {
      ...user?.extra,
      avatar_type: avatarType,
      avatar: avatarConfig,
    };

    if (profileImage) {
      // console.log(profileImage);
      if (!user) return;
      await updateUserProfile({ _id: user._id, ...profileImage });
      refreshUser();
      handleCloseModal();
      toast(`👍 Seu perfil foi atualizado com sucesso!`, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  function handleImageChange() {
    if (imagePreviewTimeout) {
      clearTimeout(imagePreviewTimeout);
    }

    imagePreviewTimeout = setTimeout(() => {
      getImageUrl();
    }, 1000);
  }

  function handleClearImage() {
    const profileImage = document.getElementById('profile-image');
    if (profileImage) {
    }
    setImage('');
  }

  const getImageUrl = async () => {
    if (!editor.current) return;
    const dataUrl = editor.current.getImageScaledToCanvas().toDataURL();
    const result = await fetch(dataUrl);
    const blob = await result.blob();

    const profileImage = document.getElementById('profile-image');
    if (profileImage) {
      profileImage.style.backgroundImage = `url(${window.URL.createObjectURL(
        blob
      )})`;
    }

    // return window.URL.createObjectURL(blob)
  };

  function getImagePlaceHolder() {
    if (!image && user && user.image && user.image.medium) {
      return { backgroundImage: `url(${user.image.medium.url})` };
    }

    return {};
  }

  // function switchAvatarPart(part: string) {
  //   const newAvatarConfig = { ...avatarConfig };
  //   console.log(newAvatarConfig);
  //   setAvatarConfig(newAvatarConfig);
  // }

  function switchAvatarPart(type: string, currentOpt?: any) {
    const opts: any = avatarDefaultOptions[type];
    const currentIdx = opts.findIndex((item: any) => item === currentOpt);
    const newIdx = (currentIdx + 1) % opts.length;
    const newAvatarConfig = { ...avatarConfig, [type]: opts[newIdx] };
    if (type === 'hairStyle') {
      newAvatarConfig['hatStyle'] = 'none';
    }
    setAvatarConfig(newAvatarConfig);
  }

  useEffect(() => {
    if (!user) return;
    setIsOpen(openModal === modalID);
    if (modalData && modalData.config) {
      setAvatarConfig(modalData.config);
      if (user.extra.avatar_type) {
        setAvatarType(user.extra.avatar_type);
      }
    }
  }, [user, openModal, modalData]);

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        contentLabel="Example Modal"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
        contentElement={() => (
          <div>
            <div className="w-[320px] bg-blue-dark text-white rounded-xl px-5 py-10 flex flex-col items-center space-y-5 flex-none mx-5">
              <div className="flex flex-row space-x-3">
                <Button
                  color="clear"
                  className={`${
                    avatarType === 0
                      ? 'bg-yellow text-blue-dark border-0'
                      : 'bg-transparent ring-2'
                  }`}
                  onClick={() => setAvatarType(0)}
                >
                  AVATAR
                </Button>
                <Button
                  color="clear"
                  className={`${
                    avatarType === 1
                      ? 'bg-yellow text-blue-dark border-0'
                      : 'bg-transparent ring-2'
                  }`}
                  onClick={() => setAvatarType(1)}
                >
                  UPLOAD
                </Button>
              </div>

              {avatarType === 0 && (
                <div className="flex flex-col items-center">
                  <Avatar className="w-36 h-36" {...avatarConfig} />

                  <div className="flex-1 mt-3 grid grid-cols-3 flex-wrap gap-1">
                    <Button
                      onClick={() =>
                        switchAvatarPart('faceColor', avatarConfig.faceColor)
                      }
                    >
                      Pele
                    </Button>
                    <Button
                      onClick={() =>
                        switchAvatarPart('eyeStyle', avatarConfig.eyeStyle)
                      }
                    >
                      Olhos
                    </Button>
                    <Button
                      onClick={() =>
                        switchAvatarPart(
                          'glassesStyle',
                          avatarConfig.glassesStyle
                        )
                      }
                    >
                      Óculos
                    </Button>
                    <Button
                      onClick={() =>
                        switchAvatarPart('earSize', avatarConfig.earSize)
                      }
                    >
                      Orelhas
                    </Button>
                    <Button
                      onClick={() =>
                        switchAvatarPart('mouthStyle', avatarConfig.mouthStyle)
                      }
                    >
                      Boca
                    </Button>
                    <Button
                      onClick={() =>
                        switchAvatarPart('noseStyle', avatarConfig.noseStyle)
                      }
                    >
                      Nariz
                    </Button>
                  </div>

                  <div className="flex-1 mt-3 grid grid-cols-2 flex-wrap gap-1">
                    <Button
                      onClick={() =>
                        switchAvatarPart('hairStyle', avatarConfig.hairStyle)
                      }
                    >
                      Cabelo
                    </Button>
                    <Button
                      onClick={() =>
                        switchAvatarPart('hairColor', avatarConfig.hairColor)
                      }
                    >
                      Cor do Cabelo
                    </Button>
                  </div>

                  <div className="flex-1 w-full mt-3 grid grid-cols-2 flex-wrap gap-1">
                    <Button
                      onClick={() =>
                        switchAvatarPart('hatStyle', avatarConfig.hatStyle)
                      }
                    >
                      Acessório
                    </Button>
                    <Button
                      className="px-2"
                      onClick={() =>
                        switchAvatarPart('hatColor', avatarConfig.hatColor)
                      }
                    >
                      Cor do Acc.
                    </Button>
                  </div>

                  <div className="flex-1 mt-3 grid grid-cols-3 flex-wrap gap-1">
                    <Button
                      onClick={() =>
                        switchAvatarPart('shirtStyle', avatarConfig.shirtStyle)
                      }
                    >
                      Camisa
                    </Button>
                    <Button
                      onClick={() =>
                        switchAvatarPart('shirtColor', avatarConfig.shirtColor)
                      }
                    >
                      Cor
                    </Button>
                    <Button
                      onClick={() =>
                        switchAvatarPart('bgColor', avatarConfig.bgColor)
                      }
                    >
                      Fundo
                    </Button>
                  </div>
                </div>
              )}
              {avatarType === 1 && (
                <div>
                  <div
                    className="rounded-full relative z-2 mt-2 rounded-lg bg-black w-64 h-64"
                    style={getImagePlaceHolder()}
                  >
                    <Dropzone
                      onDrop={(dropped: any) => setImage(dropped[0])}
                      multiple={false}
                      maxFiles={1}
                      noClick={!!image}
                      accept={{
                        'image/png': ['.png', '.jpeg', '.jpg', '.gif'],
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div {...getRootProps()}>
                          <AvatarEditor
                            ref={(e) => (editor.current = e)}
                            onImageChange={handleImageChange}
                            width={256}
                            height={256}
                            image={image}
                            borderRadius={256}
                            border={0}
                            className="overflow-hidden rounded-full"
                          />
                          <input {...getInputProps()} />
                        </div>
                      )}
                    </Dropzone>
                    {!!image && (
                      <button
                        type="button"
                        onClick={handleClearImage}
                        className="absolute bottom-0 right-0 rounded-full flex flex-row items-center justify-center text-red-500 bg-white w-16 h-16"
                      >
                        <BiTrash size={32} />
                      </button>
                    )}
                  </div>
                </div>
              )}

              {loading && (
                <div className="px-4 py-2 font-bold flex flex-row items-center space-x-4 border border-white border-opacity-50 rounded-full">
                  SALVANDO ALTERAÇÕES...
                </div>
              )}

              {!loading && (
                <div className="flex flex-row items-center space-x-4">
                  <Button
                    color="clear"
                    className="rounded-full bg-red-600 min-w-auto p-0 px-0 w-auto"
                    onClick={() => handleCloseModal()}
                  >
                    <FaTimesCircle size={36} />
                  </Button>{' '}
                  <Button
                    color="clear"
                    className="bg-green-600 w-auto p-0 px-0"
                    onClick={() => handleUpload()}
                  >
                    <div className="flex flex-row items-center">
                      <FaCheckCircle size={36} />{' '}
                      <span className="px-3">SALVAR</span>
                    </div>
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      />
    </>
  );
}
