import ModalAccept from './accept';
import ModalDouble from './double';
import ModalFeedback from './feedback';
import ModalPromote from './promote';
import ModalReject from './reject';
import ModalSelo from './selo';
import ModalStop from './stop';
import ModalReset from './reset';

interface Props {
  callback: (obj: { [key: string]: any }) => void;
  id: string;
  status?: string;
  data?: any;
  playerInfo?: any;
}

export default function ModalActions(props: Props) {
  const { callback, status, id, data, playerInfo } = props;

  return (
    <>
      <ModalAccept callback={callback} status={status} id={id} data={data} />
      <ModalDouble callback={callback} status={status} id={id} data={data} />
      <ModalFeedback callback={callback} status={status} id={id} data={data} />
      <ModalPromote
        callback={callback}
        status={status}
        id={id}
        data={data}
        playerInfo={playerInfo}
      />
      <ModalReject callback={callback} status={status} id={id} data={data} />
      <ModalSelo callback={callback} status={status} id={id} data={data} />
      <ModalStop callback={callback} status={status} id={id} data={data} />
      <ModalReset callback={callback} data={data} />
    </>
  );
}
