import {
  Uau,
  MandouBem,
  Valeu,
  Colaboracao,
  Inovacao,
  FocoNoCliente,
  Resolutividade,
  SeloColaboracao,
  SeloInovacao,
  SeloFocoNoCliente,
  SeloResolutividade,
} from 'components/icons';
import { useEffect, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { BiGift, BiTrophy } from 'react-icons/bi';
import { FaThumbsUp } from 'react-icons/fa';
import { TbMailFast } from 'react-icons/tb';
import { GetTaskLevelLabel } from 'utils/strings';

const iconSize = 20;
const Coins: any = {
  uau: <Uau yellow size={iconSize} />,
  mandou: <MandouBem yellow size={iconSize} />,
  valeu: <Valeu yellow size={iconSize} />,
};

const CoinLabel: any = {
  uau: '#UAU',
  mandou: '#MANDOUBEM',
  valeu: '#VALEU',
};

const Behaviors: any = {
  colaboracao: <Colaboracao size={iconSize} />,
  inovacao: <Inovacao size={iconSize} />,
  foco: <FocoNoCliente size={iconSize} />,
  resolutividade: <Resolutividade size={iconSize} />,
};

const BehaviorLabel: any = {
  colaboracao: 'Colaboração',
  inovacao: 'Inovação',
  foco: 'Foco no Cliente',
  resolutividade: 'Protagonismo',
};

const behaviors = [
  {
    id: 'colaboracao',
    icon: <SeloColaboracao size={'114px'} />,
  },
  {
    id: 'foco',
    icon: <SeloFocoNoCliente size={'114px'} />,
  },
  {
    id: 'inovacao',
    icon: <SeloInovacao size={'114px'} />,
  },
  {
    id: 'resolutividade',
    icon: <SeloResolutividade size={'114px'} />,
  },
];

interface PointItemProps {
  data: any;
}

export function PointItem(props: PointItemProps) {
  const { data } = props;

  const [origin, setOrigin] = useState<any>();
  const [taskOpened, setTaskOpened] = useState<boolean>(false);

  useEffect(() => {
    let defaultPoint = {
      type: 'default',
      title: 'Crédito de Pontos',
      total: data.total,
      time: data.time,
    };

    try {
      if (data?.log && data?.log.length) {
        const log = data?.log[0];
        setOrigin({
          type: data.extra.type,
          extra: data.extra,
          log,
          total: data.total,
          time: data.time,
        });
      } else if (data.type) {
        setOrigin(data);
      } else {
        setOrigin(defaultPoint);
      }
    } catch (e) {
      setOrigin(defaultPoint);
    }
  }, [data]);

  function translateTitle(item: any) {
    if (item?.log?.attributes?.campanha) return item.log.attributes.campanha;

    if (item?.log?.action === 'Entrou por convite') {
      return (
        <p>
          {`Convide seus amigos - Convite aceito por`}{' '}
          <span className="text-electric-blue capitalize">
            {item.extra.target_nome_guerra}
          </span>{' '}
          {`em ${format(new Date(item.time), 'dd MMM. yyyy')}.`}
        </p>
      );
    }
  }

  return useMemo(() => {
    function renderSeloBB() {
      try {
        return (
          <div className="flex flex-col md:flex-row md:items-center md:justify-between bg-maastricht-blue-3 p-3 md:p-7 space-y-5 md:space-y-0 md:space-x-3 rounded-xl">
            <div className="flex flex-row space-x-3 items-center">
              <div>
                <BiTrophy size={30} className="text-yellow" />
              </div>
              <div className="flex-1">
                Recebeu o Selo BB em{' '}
                <b className="electric-blue">{origin.log.attributes.selo}</b>
              </div>
            </div>
            <div className="flex flex-row space-x-3 items-center justify-center">
              <div className="flex flex-row items-center space-x-2 px-2 text-yellow py-1 bg-oxford-blue rounded-full">
                <b>+{origin.total} pontos</b>
              </div>
            </div>
          </div>
        );
      } catch (e) {
        return null;
      }
    }

    function renderMoedasBonus() {
      try {
        return (
          <div className="flex flex-col md:flex-row md:items-center md:justify-between bg-maastricht-blue-3 p-3 md:p-7 space-y-5 md:space-y-0 md:space-x-3 rounded-xl">
            <div className="flex flex-row space-x-3 items-center">
              <div>
                <BiGift size={30} className="text-yellow" />
              </div>

              <div className="flex-1 text-lg leading-none">
                <p className="font-thin text-sm mb-2">Campanha</p>
                <p className="text-sm">
                  Voce participou do{' '}
                  <b className="text-electric-blue">
                    {origin.log.attributes.campanha}
                  </b>
                </p>
              </div>
            </div>

            <div className="flex flex-row space-x-3 items-center justify-center">
              <div className="flex flex-row items-center space-x-2 px-2 bg-oxford-blue py-1 text-yellow rounded-full">
                {Behaviors[origin.extra.behavior]}{' '}
                <span className="text-xs">
                  {BehaviorLabel[origin.extra.behavior]}
                </span>
              </div>

              <div className="flex flex-row items-center space-x-2 px-2 text-yellow py-1 bg-oxford-blue rounded-full">
                <b>+1</b> {Coins[origin.extra.coin]}{' '}
                <span className="text-xs">{CoinLabel[origin.extra.coin]}</span>
              </div>
            </div>
          </div>
        );
      } catch (e) {
        return null;
      }
    }

    function renderValoracaoGestor() {
      try {
        return (
          <div className="flex flex-col bg-maastricht-blue-3 p-3 md:py-8 md:px-10 space-y-5 md:space-y-3 rounded-xl">
            <div className="flex-1 flex flex-row space-x-3 items-center">
              <div className="flex-1 text-lg leading-none">
                <p className="font-normal text-sm">
                  Validação da Realização Destaque - {origin.cicloTitle} - Fase{' '}
                  {origin.fase}
                </p>
                {origin.task ? (
                  <p className="font-thin text-sm mt-1">
                    {origin.task.title}{' '}
                    {!taskOpened ? (
                      <button
                        className="font-thin underline hover:no-underline text-electric-blue"
                        onClick={() => setTaskOpened(true)}
                      >
                        mais
                      </button>
                    ) : null}
                  </p>
                ) : null}
              </div>

              <div className="text-base leading-none">
                {/* <p className="font-thin text-xs mb-2 uppercase"></p> */}
                <b className="flex flex-col items-center justify-center text-base bg-oxford-blue px-5 py-2 text-yellow rounded-full leading-none">
                  {origin.total
                    ? Intl.NumberFormat('pt-BR').format(Math.ceil(origin.total))
                    : '0'}
                  <span className="block font-thin text-[10px] mt-1 uppercase leading-none">
                    Pontos
                  </span>
                </b>
              </div>
            </div>

            {taskOpened && typeof origin.task.description === 'string' ? (
              <div>
                <p className="font-normal text-sm text-white">
                  Detalhes da realização:
                </p>
                <p
                  className="font-thin text-sm text-white m-1"
                  dangerouslySetInnerHTML={{
                    __html:
                      typeof origin.task.description === 'string'
                        ? origin.task.description
                            .replace(/\n/gi, '<br>')
                            .replace(/\t/gi, '&nbsp;&nbsp;&nbsp;&nbsp;')
                        : '',
                  }}
                />
              </div>
            ) : null}
          </div>
        );
      } catch (e) {
        return null;
      }
    }

    function renderValoracaoGestorFeedback() {
      try {
        return (
          <div className="flex flex-col md:flex-row md:items-center md:justify-between bg-maastricht-blue-3 p-3 md:py-8 md:px-10 space-y-5 md:space-y-0 md:space-x-3 rounded-xl">
            <div className="flex-1 flex flex-row space-x-3 items-center">
              <div className="flex-1 text-lg leading-none">
                <p className="font-normal text-sm">
                  Feedback enviado nas Realizações Destaque avaliadas -{' '}
                  {origin.cicloTitle} - Fase {origin.fase}
                </p>
              </div>

              <div className="text-base leading-none">
                {/* <p className="font-thin text-xs mb-2 uppercase"></p> */}
                <b className="flex flex-col items-center justify-center text-base bg-oxford-blue px-5 py-2 text-yellow rounded-full leading-none">
                  {origin.total
                    ? Intl.NumberFormat('pt-BR').format(Math.ceil(origin.total))
                    : '0'}
                  <span className="block font-thin text-[10px] mt-1 uppercase leading-none">
                    Pontos
                  </span>
                </b>
              </div>
            </div>
          </div>
        );
      } catch (e) {
        return null;
      }
    }

    function renderEntregaEquipe() {
      try {
        return (
          <div className="flex flex-col bg-maastricht-blue-3 p-3 md:py-8 md:px-10 space-y-5 md:space-y-3 rounded-xl">
            <div className="flex-1 flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 md:items-center">
              {/* <div>
                {origin.status === 'aprovado' ? <RiThumbUpLine size={24} className="text-yellow" /> : null}
                {origin.status === 'dobrado' ? <BiCoinStack size={24} className="text-yellow" /> : null}
                {origin.status === 'promovido' ? <BiUpvote size={24} className="text-yellow" /> : null}
                {origin.status === 'selo' ? <BiTrophy size={24} className="text-yellow" /> : null}
              </div> */}
              {/* <div className="flex-1">
                <b>{origin.titulo}</b>
              </div> */}

              <div className="flex-1 text-lg leading-none">
                <p className="font-thin text-sm">Nome da realização</p>
                <p className="font-normal text-sm mt-1">
                  {origin.titulo}{' '}
                  {!taskOpened ? (
                    <button
                      className="font-thin underline hover:no-underline text-electric-blue"
                      onClick={() => setTaskOpened(true)}
                    >
                      mais
                    </button>
                  ) : null}
                </p>
              </div>

              <div className="flex-1 text-lg leading-none">
                <p className="font-thin text-sm">Status</p>
                <p className="font-normal text-sm mt-1">
                  {GetTaskLevelLabel(origin.status)}
                </p>
              </div>

              <div className="text-base leading-none">
                <p className="font-thin text-sm mb-3">% de atuação</p>

                {origin?.participacao ? (
                  <b className="inline-flex flex-col items-center justify-center text-base bg-oxford-blue px-5 py-2 text-yellow rounded-full leading-none mr-5">
                    {origin.participacao}%
                    <span className="block font-thin text-[10px] mt-1 uppercase leading-none">
                      Participação
                    </span>
                  </b>
                ) : null}

                {origin?.total ? (
                  <b className="inline-flex flex-col items-center justify-center text-base bg-oxford-blue px-5 py-2 text-yellow rounded-full leading-none">
                    {origin.total
                      ? Intl.NumberFormat('pt-BR').format(
                          Math.ceil(origin.total)
                        )
                      : '0'}
                    <span className="block font-thin text-[10px] mt-1 uppercase leading-none">
                      Pontos
                    </span>
                  </b>
                ) : null}
              </div>
            </div>

            {taskOpened && typeof origin.descricao === 'string' ? (
              <div>
                <p className="font-normal text-sm text-white">
                  Detalhes da realização:
                </p>
                <p
                  className="font-thin text-sm text-white m-1"
                  dangerouslySetInnerHTML={{
                    __html:
                      typeof origin.descricao === 'string'
                        ? origin.descricao
                            .replace(/\n/gi, '<br>')
                            .replace(/\t/gi, '&nbsp;&nbsp;&nbsp;&nbsp;')
                        : '',
                  }}
                />
              </div>
            ) : null}
            {/* <div className="flex flex-row space-x-3 items-center justify-center">
              <div className="flex flex-row items-center space-x-2 px-2 text-white py-1 bg-purple rounded-full">
                <b>Participação: {origin.participacao}%</b>
              </div>
              <div className="flex flex-row items-center space-x-2 px-2 text-blue-dark py-1 bg-yellow rounded-full">
                <b>+{origin.total} pontos</b>
              </div>
            </div> */}
          </div>
        );
      } catch (e) {
        return null;
      }
    }

    function renderDestaqueCultura() {
      return (
        <div className="flex items-center space-x-8 p-6 ring-2 ring-electric-blue w-fit rounded-2xl">
          {behaviors.map((behavior) => (
            <div
              className={`${
                data?.items?.find((item: any) => item.behavior === behavior.id)
                  ? ''
                  : 'opacity-20'
              }`}
              key={behavior.id}
            >
              <div>{behavior.icon}</div>
            </div>
          ))}
        </div>
      );
    }

    function renderDefault() {
      return (
        <div className="flex flex-col md:flex-row md:items-center md:justify-between bg-maastricht-blue-3 p-3 md:p-5 space-y-5 md:space-y-0 md:space-x-3 rounded-xl">
          <div className="flex flex-row space-x-3 items-center">
            <div>
              <TbMailFast className="text-[2.5rem] text-yellow" />
            </div>
            <div className="flex-1">{translateTitle(origin)}</div>
          </div>
          <div className="flex flex-row space-x-3 items-center justify-center">
            <div className="flex flex-row items-center space-x-2 px-3 bg-oxford-blue py-1 text-yellow rounded-full">
              <b>+{origin.total} pontos</b>
            </div>
          </div>
        </div>
      );
    }

    if (!origin) return null;

    switch (origin.type) {
      case 'moedas_bonus':
        return renderMoedasBonus();
      case 'selobb':
        return renderSeloBB();
      case 'destaque_cultura':
        return renderDestaqueCultura();
      case 'entrega_equipe':
        return renderEntregaEquipe();
      case 'entrega_gestor':
        return renderValoracaoGestor();
      case 'feedback_gestor':
        return renderValoracaoGestorFeedback();
      default:
        return renderDefault();
    }
  }, [data?.items, taskOpened, origin]);
}
