import { TbHelp } from 'react-icons/tb';
import { useLocation, useNavigate } from 'react-router';

export default function HelpButton() {
  const navigate = useNavigate();
  const location = useLocation();

  if(location.pathname === '/ajuda') return null;

  return (
    <button
      onClick={() => navigate('/ajuda')}
      className="fixed right-8 bottom-8 w-12 h-12 rounded-full bg-white text-blue-500 shadow-lg"
    >
      <TbHelp size={48} />
    </button>
  );
}
