import { useContext, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { IoIosClose } from 'react-icons/io';

import Button from 'components/ui/button';
import { useModal } from 'context/ModalContext';
import Box from 'components/ui/box';

interface Props {
  callback: (obj: { id: string }) => void;
}

export default function ModalConfirm(props: Props) {
  ReactModal.setAppElement('#modal');

  const modalID = 'confirm-delete';

  const { openModal, modalData, closeModal } = useModal();
  const { callback } = props;

  const [modalIsOpen, setIsOpen] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);

  function handleCloseModal() {
    //
  }

  function afterOpenModal() {
    //
  }

  useEffect(() => {
    if (typeof openModal === 'string' && openModal === modalID) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [openModal]);

  if (!modalIsOpen) return <></>;

  return (
    <>
      <ReactModal
        shouldCloseOnOverlayClick={false}
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={handleCloseModal}
        contentLabel="Avaliação"
        portalClassName="relative z-50"
        overlayClassName="fixed z-100 top-0 left-0 bg-black bg-opacity-60 flex flex-col items-center justify-center w-screen h-screen p-6 overflow-y-auto"
        contentElement={() => (
          <Box className={`w-[90%] lg:w-auto lg:max-w-[600px]`}>
            <Button
              color="primary"
              className="absolute -right-5 -top-5 rounded-r-full rounded-l-full w-10 h-10 p-0 flex justify-center items-center "
              onClick={() => closeModal()}
            >
              <IoIosClose color="white" size={40} />
            </Button>

            <div className="w-full lg:w-[536px] flex flex-col items-center justify-center space-y-8">
              <h4 className="block text-center text-yellow text-lg font-semibold leading-none">
                Deseja mesmo excluir essa Realização Destaque?
              </h4>
              {/* <p className="text-alice-blue text-base font-thin block">
                O indicado é realizá-la somente após efetuar todas as validações. Após
                concluí-la, essa ação não poderá se desfeita.
              </p> */}
              <button
                className={`text-white bg-slate-blue text-sm inline-flex items-center justify-center font-semibold uppercase rounded py-3 px-4 leading-none`}
                onClick={() => {
                  callback({ id: modalData.id });
                  closeModal();
                }}
              >
                CONFIRMAR
              </button>
            </div>
          </Box>
        )}
      />
    </>
  );
}
