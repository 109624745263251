/* eslint-disable react-hooks/exhaustive-deps */
import { useAuth } from 'context/AuthContext';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getToken } from 'utils/tokenCookies';

interface WebsocketContextData {
  // connect: () => void;
  // disconnect: () => void;
  sendMessage: (message: any) => void;
}

interface WebsocketContextProps {
  children: ReactNode;
}

export const WebsocketContext = createContext({} as WebsocketContextData);

function WebsocketProvider({ children }: WebsocketContextProps) {
  const [client, setClient] = useState<any>();
  const { refreshUser } = useAuth();

  function connect() {
    try {
      if (client) {
        client.close();
      }
      const token = getToken();
      if (token) {
        const socketClient = new WebSocket(`wss://service2.funifier.com/ws/reward?authorization=${encodeURIComponent(token)}`);
        setClient(socketClient);
      }
    } catch (e) {
      console.log(e);
      disconnect();
    }
  }

  function disconnect() {
    if (client) {
      client.close();
    }
    setClient(undefined);
  }

  function onMessage(e: MessageEvent) {
    const data = JSON.parse(e?.data);

    let message = `💎 Você acaba de receber um reconhecimento.`;

    if (typeof data?.item !== 'undefined' && typeof data?.extra?.type !== 'undefined' && data?.item === 'point' && data?.extra?.type === 'accept_invite') {
      message = `🚀 ${data.extra.target_nome_guerra} aceitou seu convite. Você recebeu ${data.total} pontos.`;
    }

    toast(message, {
      position: 'top-right',
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    refreshUser();
    // dispatchEvent(new CustomEvent("websocket-message", { detail: JSON.parse(e.data) }));
  }

  function sendMessage(message: any) {
    if (!client) return;
    client.send(JSON.stringify(message));
  }

  useEffect(() => {
    connect();
  }, []);

  useEffect(() => {
    if (client) {
      client.onmessage = onMessage;
    }
    return () => {
      if (client) {
        client.onmessage = null;
      }
    };
  }, [client]);

  return (
    <WebsocketContext.Provider
      value={{
        sendMessage,
      }}>
      {children}
    </WebsocketContext.Provider>
  );
}

function useWebsocket() {
  const context = useContext(WebsocketContext);
  if (context === undefined) {
    throw new Error('useWebsocket must be used within a WebsocketProvider');
  }
  return context;
}

export { WebsocketProvider, useWebsocket };
