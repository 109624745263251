import ArticlePlaceholder from 'components/ui/article-placeholder';
import { useCallback, useEffect, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import { getFaqContent } from 'services/content.service';

type Props = {
  _id: string;
  title: string;
};

export default function FaqItem({ _id, title }: Props) {
  const [contentData, setContentData] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [opened, setOpened] = useState<boolean>(false);

  const loadData = useCallback(async () => {
    try {
      setLoading(true);
      const data = await getFaqContent(_id);
      setContentData(data);
      setLoading(false);
    } catch (error) {}
  }, [_id]);

  useEffect(() => {
    if (opened && !loading && typeof contentData !== 'string') {
      loadData();
    }
  }, [contentData, loadData, loading, opened]);

  return (
    <div className="space-y-3">
      <div
        onClick={() => setOpened((prevstate) => !prevstate)}
        className="cursor-pointer hover:ring-1 ring-white border border-electric-blue bg-maastricht-blue rounded-xl p-3"
      >
        <div className="flex flex-row items-start justify-between">
          <h3 className="text-white font-bold leading-tight lg:text-xl">
            {title}
          </h3>
          <div>
            {!opened && <BiChevronDown size={28} />}
            {!!opened && <BiChevronUp size={28} />}
          </div>
        </div>
        {!!opened &&
          (!!loading ? (
            <div className="mt-6">
              <ArticlePlaceholder />
            </div>
          ) : (
            <div
              className="p-3"
              dangerouslySetInnerHTML={{ __html: contentData || '' }}
            ></div>
          ))}
      </div>
    </div>
  );
}
