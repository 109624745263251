import { Link } from 'react-router-dom';
import { AiFillLeftCircle } from 'react-icons/ai';
import { useEffect, useState } from 'react';
import { getKPIHistory } from 'services/dashboard.service';
import { useAuth } from 'context/AuthContext';
import Box from 'components/ui/box';

export default function Historico() {
  const [list, setList] = useState<unknown[]>();
  const { user } = useAuth();

  useEffect(() => {
    async function loadData() {
      try {
        const data = await getKPIHistory(user?._id);
        setList(data);
      } catch (e) {}
    }

    if (user) {
      loadData();
    }
  }, [user]);

  return (
    <div className="mx-auto container p-10">
      <div className="flex flex-ro items-center space-x-10 mb-10">
        <div>
          <Link to="../">
            <AiFillLeftCircle size={48} className="text-yellow" />
          </Link>
        </div>
        {/* <div className="w-36 hidden md:block">
          <img src={Logo} alt="#JogaJunto BB" />
        </div> */}
        <h1 className="text-xl lg:text-3xl bg-blue-dark py-1 px-6 rounded-full border border-yellow">Historico de Emissões</h1>
      </div>

      <div className="space-y-5">
        {list?.map((item: any) => (
          <Box key={item._id}>
            <div>{JSON.stringify(item)}</div>
            <div>asdasd</div>
          </Box>
        ))}
      </div>
    </div>
  );
}
