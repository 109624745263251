import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactModal from 'react-modal';
import InputMask from 'react-input-mask';
import { TbSearch } from 'react-icons/tb';
import { RiCloseLine } from 'react-icons/ri';
import { IoMdClose } from 'react-icons/io';
import { IoClose } from 'react-icons/io5';

import { useAuth } from 'context/AuthContext';
import Box from 'components/ui/box';
import { UserAvatar } from 'components/user-avatar';
import { findNewPlayer } from 'services/profile.service';
import { getPlayerInfo, getServerTime, sendInvite, submitTask } from 'services/dashboard.service';
import { InviteInterface, User, UserInfo } from 'types/user';

import FeedbackImg from 'assets/img/comportamento/bg-feedback-moedas.png';

const maskTemplate = 'F9999999';

export default function Convidar() {
  const navigate = useNavigate();
  const { user } = useAuth();

  const searchRef = useRef<any>();
  const messageRef = useRef<any>();

  const [isOpen, setIsOpen] = useState(true);
  const [searchResults, setSearchResults] = useState<User[]>([]);
  const [myUser, setMyUser] = useState<UserInfo>();
  const [target, setTarget] = useState<User[]>();
  const [message, setMessage] = useState<string>();
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [step, setStep] = useState(0);
  const [mask, setMask] = useState('');

  ReactModal.setAppElement('#modal');

  function handleChange() {
    if (searchRef.current) {
      const value = searchRef.current.value.toUpperCase();
      if (value.length > 1) {
        if (value[0].toUpperCase() === 'F' && !isNaN(value[1])) {
          setMask(maskTemplate);
        } else {
          setMask('');
          if (value === '_______') {
            searchRef.current.value = '';
          }
        }
      } else {
        setMask('');
      }
    }
  }

  async function handleSearch(e?: any) {
    if (e) {
      e.preventDefault();
    }

    try {
      const matricula = searchRef.current.value.replace('_', '');

      const targetOnList = target?.find((item) => item._id === matricula);

      if (targetOnList) {
        return;
      }

      setSearching(true);

      const result = await findNewPlayer(searchRef.current.value, target?.length ? target?.map((item) => item._id) : undefined);

      if (result.length === 1) {
        addTarget(result[0]);
      } else {
        setSearchResults(result);
      }

      setSearching(false);
    } catch (e) {
      setSearching(false);
    }
  }

  async function handleSubmit() {
    try {
      if (loading || !target?.length) return;

      setLoading(true);

      const created = await getServerTime();

      const eachItems: any = [];

      target.forEach((item) => {
        const payload: InviteInterface = {
          created,
          sender: {
            matricula: user?._id as string,
            nome: user?.name as string,
            nome_guerra: myUser?.nome_guerra as string,
            dependencia: myUser?.nome_dependencia as string,
            diretoria: myUser?.diretoria as string,
            funcao: myUser?.funcao as string,
            email: myUser?.email as string,
          },
          target: {
            matricula: item._id,
            nome: item.name,
            nome_guerra: item.extra.nome_guerra,
            dependencia: item.extra.dependencia,
            diretoria: item.extra.diretoria,
            funcao: item.extra.funcao,
            email: item.email as string,
          },
        };

        if (message?.trim().length) {
          payload.message = message.trim().slice(0, 150).trim();
        }

        eachItems.push(sendInvite(payload));
      });

      await Promise.all(eachItems);

      setSubmitted(true);
    } finally {
      setLoading(false);
    }
  }

  function removeTarget(index: number) {
    if (target && target.length) {
      let newTargetList = [...target];
      newTargetList.splice(index, 1);
      setTarget(newTargetList);
    }
  }

  function addTarget(item: User) {
    const newTargetList = target || [];

    newTargetList.push(item);
    setTarget(newTargetList);
    setSearchResults([]);
    setSearching(false);

    if (searchRef && searchRef.current) {
      searchRef.current.value = '';
    }
  }

  function reset() {
    setTarget(undefined);
    setSubmitted(false);
    setLoading(false);
    setSearchResults([]);
    setSearching(false);
    setMessage(undefined);
    setStep(0);
  }

  function handleCloseModal() {
    reset();
    navigate('../', { state: { direction: 'back' } });
  }

  function afterOpenModal() {}

  useEffect(() => {
    async function getUser() {
      const playerInfo = await getPlayerInfo(user?._id);
      setMyUser(playerInfo);
    }

    if (user) {
      getUser();
    }
  }, [user]);

  return (
    <ReactModal
      key={'modal-player-get-player'}
      shouldCloseOnEsc={true}
      shouldCloseOnOverlayClick={true}
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onAfterClose={handleCloseModal}
      contentLabel="Modal Convidar Amigos"
      portalClassName="modal-player-get-player"
      overlayClassName="fixed z-20 top-0 left-0 w-full h-full bg-black bg-opacity-80 flex flex-col items-center justify-center"
      contentElement={() => (
        <>
          {!submitted && (
            <Box className="w-[90%] lg:max-w-screen-md">
              <button
                className="absolute w-[33px] h-[33px] flex items-center justify-center -top-4 -right-4 button-close bg-slate-blue rounded-full"
                onClick={() => {
                  if (loading) return null;

                  if (searchResults.length > 0) {
                    reset();
                  } else {
                    setIsOpen(false);
                  }
                }}>
                <IoClose size={23} className="text-white leading-none block m-0 p-0" />
              </button>

              <div className="mx-auto relative container overflow-y-auto overflow-x-hidden max-h-[85vh]">
                <div className="block mb-8">
                  <h1 className="text-lg uppercase block text-center text-yellow">Convide seus amigos</h1>
                </div>

                {!loading && !submitted && (
                  <div className="space-y-8">
                    {/* <div className="relative flex flex-row items-center justify-around">
                      <div className="absolute z-0 w-full h-[1px] top-0 bottom-0 m-auto border-b border-white opacity-20"></div>
                      <div className={`${step === 0 ? 'bg-slate-blue' : 'bg-prussian-blue text-white'} text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}>1</div>
                      <div className={`${step === 1 ? 'bg-slate-blue' : 'bg-prussian-blue text-white'} text-xs relative ring-1 ring-white rounded-full w-5 h-5 flex flex-row items-center justify-center`}>2</div>
                    </div> */}

                    {step === 0 && (
                      <div className="space-y-6">
                        <div className="space-x-4 flex flex-row items-center justify-between w-full">
                          <div className="relative flex-1">
                            <span className="text-alice-blue mb-4 block text-base font-thin">
                              O que é bom a gente compartilha! <br />A cada amigo que entrar no Joga Junto com seu convite, você recebe <strong className="text-electric-blue font-normal">5 pontos</strong>.
                            </span>

                            {(!target?.length || target?.length < 5) && (
                              <>
                                <div className="space-x-4 flex flex-row items-center justify-between">
                                  <form onSubmit={handleSearch} className="pl-1 flex-1">
                                    <InputMask
                                      placeholder="Digite o nome ou chave do funcionário"
                                      alwaysShowMask={false}
                                      mask={mask}
                                      ref={(ref: any) => {
                                        searchRef.current = ref;
                                      }}
                                      onChange={(e) => handleChange()}
                                      inputMode="search"
                                      style={{ fontSize: 16 }}
                                      className="w-full bg-maastricht-blue text-white text-sm font-light flex-1 rounded-md py-2 px-4"
                                    />
                                  </form>

                                  <TbSearch size={28} className="cursor-pointer hover:ring-2 mr-1 rounded-full" onClick={() => handleSearch()} />
                                </div>

                                <p className="block mt-2 text-xs text-white font-thin">*Você pode adicionar até 5 pessoas neste convite. Apenas pessoas que não estão utilizando o Joga Junto podem ser convidadas.</p>
                              </>
                            )}
                          </div>
                        </div>

                        {target && target?.length > 0 && !searching && (
                          <div className="flex-1 bg-rich-black bg-opacity-80 py-4 px-2 lg:px-8 rounded-3xl">
                            {target.map((item: User, index: number) => (
                              <div key={item._id} className={`w-full flex flex-col lg:flex-row lg:items-center justify-between ${index + 1 < target.length ? 'border-b border-prussian-blue pb-4 mb-4 ' : ''}`}>
                                <div className="flex flex-1 relative">
                                  <div className="w-[44px] h-[44px] ml-2">
                                    <UserAvatar data={{ image: item.image, ...item.extra }} />
                                  </div>

                                  <p className="flex-1 pl-3 pr-2">
                                    <span className="text-xs font-thin w-full">
                                      <strong className="text-base font-semibold lg:truncate w-[35vw] lg:w-auto">{item.name}</strong> ({item._id})
                                    </span>
                                    {!!item.extra?.diretoria || !!item.extra?.funcao ? (
                                      <span className="block text-xs font-thin">
                                        {!!item.extra?.diretoria && <span className="text-electric-blue uppercase">{item.extra?.diretoria}</span>}
                                        {!!item.extra?.diretoria && !!item.extra?.funcao && ' - '}
                                        {item.extra?.funcao ? `${item.extra?.funcao}` : ''}
                                      </span>
                                    ) : null}
                                  </p>
                                </div>

                                <RiCloseLine size={44} className="cursor-pointer hover:ring-2 p-2 rounded-full text-slate-blue" onClick={() => removeTarget(index)} />
                              </div>
                            ))}
                          </div>
                        )}

                        {searching && (
                          <div className="flex-1 bg-rich-black bg-opacity-80 p-8 rounded-3xl">
                            <p className="animate-pulse text-yellow">Recuperando informações...</p>
                          </div>
                        )}

                        {!searching && !!searchResults?.length && (
                          <>
                            <div className="flex-1 bg-rich-black bg-opacity-80 py-4 px-8 rounded-3xl h-[30vh] lg:h-auto overflow-y-auto lg:overflow-y-visible">
                              {searchResults.map((item, index) => (
                                <div key={item._id} onClick={() => addTarget(item)} className={`cursor-pointer hover:opacity-75 w-full flex flex-row items-center justify-between border-b border-prussian-blue pb-4 mb-4`}>
                                  <div className="w-[44px] h-[44px] ml-2">
                                    <UserAvatar data={{ image: item.image, ...item.extra }} />
                                  </div>
                                  <p className="flex-1 pl-3 pr-2">
                                    <span className="text-xs font-thin">
                                      <strong className="text-base font-semibold">{item.name}</strong> ({item._id})
                                    </span>
                                    {!!item.extra?.diretoria || !!item.extra?.funcao ? (
                                      <span className="block text-xs font-thin">
                                        {!!item.extra?.diretoria && <span className="text-electric-blue uppercase">{item.extra?.diretoria}</span>}
                                        {!!item.extra?.diretoria && !!item.extra?.funcao && ' - '}
                                        {item.extra?.funcao ? `${item.extra?.funcao}` : ''}
                                      </span>
                                    ) : null}
                                  </p>
                                </div>
                              ))}

                              <div className="text-center pt-2 text-white font-base font-light">Não encontrou quem procura? Experimente buscar por nome e sobrenome ou matrícula.</div>
                            </div>
                          </>
                        )}
                      </div>
                    )}

                    {/* {step === 1 && (
                      <div className="border-2 border-electric-blue rounded-2xl p-8 flex flex-col w-full space-y-2">
                        <div className="text-white font-thin text-xs leading-none mb-2 flex items-center">
                          Mensagem do convite <span className="text-electric-blue mx-1">(opcional - Até 150 caracteres)</span>
                        </div>

                        <textarea defaultValue={message} ref={(ref) => (messageRef.current = ref)} onChange={(e) => setMessage(e.target.value)} className="h-28 w-full bg-maastricht-blue text-white py-2 px-4 rounded-md text-sm font-light" maxLength={150}></textarea>
                      </div>
                    )} */}

                    <div className="flex lg:block justify-center">
                      <div className="p-2 px-45 flex flex-row space-x-8 items-center justify-center">
                        {/* {step > 0 && (
                          <button onClick={() => setStep(step - 1)} className={`eading-none ring-2 ring-blue-violet-crayola w-full lg:w-auto flex flex-col lg:flex-row items-center bg-transparent text-center text-white rounded py-3 px-4`}>
                            <p className="font-bold text-sm leading-none uppercase">Voltar</p>
                          </button>
                        )}
                        {step === 0 && (
                          <button onClick={() => (target?.length ? setStep(step + 1) : null)} disabled={!target?.length} className={`leading-none disabled:opacity-50 ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white rounded py-3 px-4`}>
                            <p className="font-bold text-sm leading-none uppercase">Continuar</p>
                          </button>
                        )} */}
                        {step === 0 && (
                          <button disabled={!target?.length} onClick={() => (target?.length ? handleSubmit() : null)} className={`leading-none disabled:opacity-50 ring-2 ring-slate-blue w-full lg:w-auto flex flex-col lg:flex-row items-center bg-slate-blue text-center text-white rounded py-3 px-4`}>
                            <p className="font-bold text-sm leading-none uppercase">Convidar!</p>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {loading && !submitted && (
                  <div className="space-y-4 mt-12">
                    <div className="flex flex-row items-center justify-center text-xl text-yellow animate-pulse">
                      <div></div>
                      Enviando convite...
                    </div>
                  </div>
                )}
              </div>
            </Box>
          )}

          {submitted && (
            <div className="border-2 rounded-3xl p-3 relative bg-oxford-blue border-electric-blue p-8 flex flex-col w-[324px] lg:max-w-screen-md justify-center items-center">
              <button
                className="absolute w-[33px] h-[33px] flex items-center justify-center -top-4 -right-4 button-close bg-slate-blue rounded-full"
                onClick={() => {
                  if (loading) return null;

                  setIsOpen(false);
                }}>
                <IoClose size={23} className="text-white leading-none block m-0 p-0" />
              </button>

              <div className="flex flex-col items-center justify-center">
                <div className="flex items-center justify-center mb-6">
                  <img src={FeedbackImg} alt="Enviado!" className="w-[173px] block" />
                </div>

                <h1 className="block text-2xl font-semibold text-yellow mb-4 text-center">Mandou bem!</h1>

                {target?.length ? (
                  target.length > 1 ? (
                    <>
                      <p className="text-sm font-white font-light text-center block">
                        Você convidou <span className="text-electric-blue font-light">{target.length} pessoas</span>.
                      </p>
                      <p className="text-sm font-white font-light text-center block">
                        Quando seu convite for aceito, você ganhará <span className="text-electric-blue font-normal">5 pontos</span>.
                      </p>
                      <p className="text-sm font-white font-light text-center block">Os pontos recebidos estarão no seu Extrato de pontos.</p>
                    </>
                  ) : (
                    <>
                      <p className="text-sm font-white font-light text-center block">
                        Você convidou <span className="text-electric-blue font-light">{target[0].extra.nome_guerra}</span>.
                      </p>
                      <p className="text-sm font-white font-light text-center block">
                        Quando seu convite for aceito, você ganhará <span className="text-electric-blue font-normal">5 pontos</span>.
                      </p>
                      <p className="text-sm font-white font-light text-center block">Os pontos recebidos estarão no seu Extrato de pontos.</p>
                    </>
                  )
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </>
      )}
    />
  );
}
