import { api } from "./api";

export interface IInteraction {
  player: string;
  sender: string;
  senderName: string;
  item: string;
  message?: string;
  like?: boolean;
  created: {
    $date: number;
  };
}

export async function registerInteraction(payload: IInteraction) {
  const { data } = await api.put<IInteraction[]>(`database/interactions__c`, payload);
  return data;
}


export async function deleteInteraction(id: string) {
    const { data } = await api.delete(`database/interactions__c?q=_id:'${id}'`);
    return data;
}